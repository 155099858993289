import { lazyLoadView } from '@/helpers/url';
import { RouteType } from '@/types/router';

const cancelRoutes: Array<RouteType> = [
    {
        path: '/cancel/intervention-code',
        name: 'CancelInterventionCodePage',
        component: lazyLoadView(`global/InterventionCodePage/InterventionCodePage`),
        meta: {
            nextRouteName: `PrebookedAppointmentPage.vue`,
        },
    },
    {
        path: '/cancel/appointment',
        name: `CancelAppointmentPage`,
        component: lazyLoadView(`cancel/CancelAppointmentPage/CancelAppointmentPage`),
        meta: {
            backButton: true,
        },
    },
];

for (let i = 0; i < cancelRoutes.length; i++) {
    if (!cancelRoutes[i].meta) {
        cancelRoutes[i].meta = {};
    }
    cancelRoutes[i].meta!.progressValue = (i + 1) / cancelRoutes.length * 100;
    cancelRoutes[i].meta!.showAppHeader = true;
}

export default cancelRoutes;
