export default {
    'WORKERS.FORM.TITLE': 'Add a technician',

    'WORKERS.SEARCH.PLACEHOLDER': 'Last name or First name',
    'WORKERS.ADD.BUTTON': 'Add a technician',

    'WORKERS.USER.NO_NAME': 'No name',

    'WORKERS.FORM.INPUT.FIRST_NAME.LABEL': 'First name',
    'WORKERS.FORM.INPUT.LAST_NAME.LABEL': 'Last name',
    'WORKERS.FORM.INPUT.PHONE.LABEL': 'Phone',
    'WORKERS.FORM.INPUT.COLOR.LABEL': 'Color',
    'WORKERS.FORM.INPUT.LABEL.LABEL': 'Label',
    'WORKERS.FORM.INPUT.COMPANIES.LABEL': 'Companies',
    'WORKERS.FORM.INPUT.COMPANY.LABEL': 'Company',
    'WORKERS.FORM.INPUT.ROLE.LABEL': 'Role',
    'WORKERS.FORM.INPUT.PLACEHOLDER.SELECT_COMPANIES.LABEL': 'Select one or more companies',
    'WORKERS.FORM.HINT.ONE_OF_THESE': 'Please complete at least one of these fields',
    'WORKERS.FORM.HINT.REQUIRED': 'Required field',

    'WORKERS.FORM.SUBMIT.TEXT': 'Add',
    'WORKERS.POPUP.SUCCESS.MESSAGE': 'The technician has been added',
    'WORKERS.POPUP.SUCCESS.EDIT.MESSAGE': 'Technician profile has been updated',
};
