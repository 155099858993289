import { CompaniesAddressDataType } from '@/types/Companies';

function getAddressAsString(data: CompaniesAddressDataType): string {
    return `${data.address}, ${data.zip_code}, ${data.city}`;
}

function getAddressAsStringCountry(data: CompaniesAddressDataType): string {
    return `${data.address || ''}, ${data.zip_code || ''}, ${data.country || ''}`;
}

function parseAddress(string: string): CompaniesAddressDataType {
    const data: Array<string> = string.split(', ');
    return {
        address: data[0],
        zip_code: data[1],
        city: data[2],
    };
}
function parseAddressCountry(string: string): CompaniesAddressDataType {
    const data: Array<string> = string.split(', ');
    return {
        address: data[0],
        zip_code: data[1],
        country: data[2],
    };
}

function addPostCodeToAddress(calizyAddress: string, post_code: string): string {
    const { zip_code, country, address } = parseAddressCountry(calizyAddress);
    const currentAddressCode = parseInt(zip_code, 10);
    if (Number.isInteger(currentAddressCode) && currentAddressCode === +post_code) {
        return calizyAddress;
    }
    return getAddressAsStringCountry({
        address,
        country,
        zip_code: `${post_code} ${zip_code}`,
    });
}

export {
    getAddressAsString,
    parseAddress,
    addPostCodeToAddress,
};
