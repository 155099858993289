<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ $route.meta.title }}
        </h1>
    </div>
    <div class='b-choose-manage__wrapper'>
        <router-link
            :to='addByFormRoute'
            class='b-choose-manage'>
            <img :src='requireImage("manual.svg")'>
            <h2 class='b-choose-manage__title h-mt-25'>
                {{ $t('ADD_COMPANIES.COMMON.MANUAL.TITLE') }}
            </h2>
            <div class='b-choose-manage__aside'>
                {{ $t('ADD_COMPANIES.COMMON.MANUAL.DESCRIPTION') }}
            </div>
        </router-link>

        <router-link
            :to='addByFileRoute'
            class='b-choose-manage'>
            <img :src='requireImage("import-file.svg")'>
            <h2 class='b-choose-manage__title h-mt-25'>
                {{ $t('ADD_COMPANIES.COMMON.FILE.TITLE') }}
            </h2>
            <div class='b-choose-manage__aside'>
                {{ $t('ADD_COMPANIES.COMMON.FILE.DESCRIPTION') }}
            </div>
        </router-link>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CONSULT_PREFIX } from '@/routes/config';

@Component
export default class AddOneOrMorePage extends Vue {
    get addByFormRoute() {
        return this.$route.meta ? `${CONSULT_PREFIX}${this.$route.meta.urlPrefix}/form` : ``;
    }

    get addByFileRoute() {
        return this.$route.meta ? `${CONSULT_PREFIX}${this.$route.meta.urlPrefix}/file` : ``;
    }
}
</script>
