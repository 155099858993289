export default {
    'LABEL.COMPANY.NAME': 'Social reason',
    'LABEL.FIRST.NAME': 'First name',
    'LABEL.APPT.CLIENT.FIRST.NAME': 'Client first name',
    'LABEL.APPT.CLIENT.LAST.NAME': 'Client last name',
    'LABEL.APPT.CLIENT.COMPANY': 'Client company',
    'LABEL.APPT.CLIENT.CONTRACT': 'Contract number',
    'LABEL.APPT.CLIENT.OI': 'OI number',
    'LABEL.APPT.CLIENT.LOCCATION': 'Appointment location',
    'LABEL.ADVISORS.NAME': 'Advisors name',
    'LABEL.ADVISORS.PHONE': 'Advisors phone number',
    'LABEL.TITLE': 'Title',
    'LABEL.LAST.NAME': 'Last name',
    'LABEL.PHONE.NUMBER': 'Phone number',
    'LABEL.CLIENT.NAME': 'Client name',
    'LABEL.CLIENT.LAST.NAME': 'Client surname',
    'LABEL.ROLE': 'Role',
    'LABEL.ROLE.PLACEHOLDER': 'Select a role',
    'LABEL.EMAIL': 'Email',
    'LABEL.COLOR': 'Color',
    'LABEL.ACCOUNT': 'Account status',
    'LABEL.CONTRACT.NUMBER': 'Contract number',
    'LABEL.TO': 'To',
    'LABEL.FROM': 'From',
    'LABEL.COMPANY.NAME.TITLE': 'Company name',
    'LABEL.BOOK': 'Book',
    'LABEL.APPOINTMENT.LOCATION': 'Appointment location',
    'LABEL.CHOOSE_DAY': 'Choose a date',
    'LABEL.NO.RESULT': 'No result',
    'LABEL.NO.OPTION': 'No option',
    'LABEL.RESULT.HERE.INFO': 'Here you will the result',
    'LABEL.COMPANY.MANUAL': 'Company',

    'POPUP.MANAGER.EDIT.HEADER': 'Edit user',
    'POPUP.MANAGER.SUCCESS': 'The user has been added',
    'POPUP.MANAGER.SUCCESS.UPDATED': 'The user has been updated',
    'POPUP.MANAGER.DELETE.HEADER': 'Delete the user',
    'POPUP.MANAGER.DELETE.QUESTION': 'Do you really want to delete this user?',
};
