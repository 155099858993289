<template>
<div class='b-blue-background b-blue-background__opacity b-cancel-popup__select b-blue-background--fixed'>
    <FwPopup
        v-if='reasonPopup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        width='400px'
        :title='$t(`CANCEL.REASON.HEADER`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        submitText='BUTTON.ADD'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <CancelReasonSelect
                v-if='optionsReasonOrigin'
                :isRequestSending='isRequestSending'
                iconsWrapperClass='h-flex-dir-column h-flex'
                :optionsReasonOrigin='optionsReasonOrigin'
                :disabledForm='false'
                :isConsultPart='isConsultPart'
                submitText=''
                @onSubmit='sendDataToServer'>
            </CancelReasonSelect>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CancelReasonSelect } from '@/components/common/CancelReasonSelect';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import i18n from '@/locale';

interface ReasonValueType {
    title: string,
    value: string,
}

@Component({
    components: {
        CancelReasonSelect,
    },
})

export default class CancelReasonPopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isConsultPart!: boolean;
    reasonPopup: boolean = true;
    isRequestSending: boolean = false;
    optionsReasonOrigin: Array<ReasonValueType> = [
        {
            title: i18n.tc('CANCEL.ORIGIN.CUSTOMER'),
            value: 'client',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.WORKER'),
            value: 'worker',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.IMA'),
            value: 'ima_protect',
        },
    ];

    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }

    async sendDataToServer(data: any) {
        try {
            this.isRequestSending = true;
            await AppointmentWebApi.cancelAppointment(
                data,
                this.appointmentStoreData.id
            );
            this.isRequestSending = false;
            this.reasonPopup = false;
            this.sentNotif('CANCEL.SUCCESS.APPOINTMENT', 'success');
            if (this.isConsultPart) {
                this.$store.commit('REMOVE_APPOINTMENT', this.appointmentStoreData.id);
                this.$emit('close', this.appointmentStoreData.id);
            } else {
                this.$router.push({ path: `/plan/intervention-code` });
            }
            this.$store.dispatch('storeAppointmentData', null);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>

<style lang='sass'>
.b-blue-background
    display: flex
    justify-content: center
    align-items: center
    .fw-popup__title
        font-size: 22px
        @include media('<=phone')
            font-size: 18px

    .fw-popup__close_popup
        @include media('<=phone')
            right: 22px
            top: 22px
</style>
