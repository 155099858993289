import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes';
import { isUserAuth, isUserAccountId } from '@/helpers/auth';
import store from '@/store';

Vue.use(VueRouter);

const router: any = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({ x: 0, y: savedPosition.y });
                }, 1);
            });
        } else if (to.meta && to.meta.saveScrollPosition) {
            return { x: 0, y: window.scrollY };
        }

        return { x: 0, y: 0 };
    },
});
router.beforeEach((to: any, from: any, next: any) => {
    if (to.meta.notAuthRequired) {
        if (isUserAuth(store)) {
            next({ name: `InterventionCodePage` });
        } else {
            next();
        }
    } else if (!to.meta.notAuthRequired) {
        if (!isUserAuth(store) && !isUserAccountId(store)) {
            next({ name: 'LoginPage' });
        } else {
            next();
        }
        next();
    } else {
        next();
    }
    if (from.fullPath !== '/') {
        store.commit(`GlobalStore/setPreviousRoutePath`, from.fullPath);
    }
});
export default router;
