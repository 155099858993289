import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import { prepareServerFormErrors } from '@/helpers/validators';
import { ServerBaseDataOnError } from '@/types/Response';

declare module 'vue/types/vue' {
    interface Vue {
        $validate: Function,
        validation: any,
        serverErrors: serverErrors, // need to declare on main component!
        config: Function, // need to declare on main component!
        emailValidator: ({ value }: { value: string, notRequired?: boolean }) => boolean,
        baseInputValidator: ({ value, minLength, maxLength, isRequired }: { value: string, minLength?: number, maxLength?: number, isRequired?: boolean }) => boolean,
    }
}

@Component
export default class ValidationMixin extends Vue {
    validationActiveArray: Array<string> = [];

    activateValidation(): Promise<boolean> {
        return this.$validate();
    }

    addCurrentInputToValidateArray(fieldName: string): void {
        if (!this.validationActiveArray.includes(fieldName)) {
            this.validationActiveArray.push(fieldName);
            this.activateValidation();
        }
    }

    fieldHasError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            (
                this.serverErrors[fieldName] ||
                this.validation.hasError(`${fieldName}`) ||
                Boolean(this.validation.firstError(`${fieldName}`))
            )
        );
    }
    fieldHasNotError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            !(this.serverErrors[fieldName] || this.validation.hasError(`${fieldName}`))
        );
    }
    getFieldErrorText(fieldName: string): string | null {
        if (!this.validationActiveArray.includes(fieldName)) {
            return null;
        }
        return this.serverErrors[fieldName] || this.validation.firstError(`${fieldName}`);
    }
    clearServerErrorsBase(fieldName: string): void {
        this.serverErrors[fieldName] = null;
    }
    propsKeyDownMethod($event: KeyboardEvent) {
        const availableKeys: Array<string> = [
            `Tab`,
            `Backspace`,
            `ArrowRight`,
            `ArrowLeft`,
        ];
        if (!Number.isInteger(parseInt($event.key, 10)) &&
            !availableKeys.includes($event.key) &&
            !($event.keyCode === 187 && $event.shiftKey) &&
            !($event.ctrlKey && $event.key === 'v' || $event.key === 'c') &&
            !($event.metaKey && $event.key === 'v' || $event.key === 'c')) {
            $event.preventDefault();
        }
    }
    noNumbersAndCharactersMethod($event: KeyboardEvent) {
        const a = /^[~`!@#$%\^&*()+=_\[\]\\';.,/{}|\\":<>\?]+$/i;
        if ((Number.isInteger(parseInt($event.key, 10)) &&
            $event.key !== '-') || a.test($event.key)) {
            $event.preventDefault();
        }
    }
    prepareServerFormErrors(data: ServerBaseDataOnError): serverErrors {
        return prepareServerFormErrors(data);
    }
    // @ts-ignore-next-line
    emailValidator({ value, notRequired }: { value: string, notRequired?: boolean }): boolean {
        return notRequired ? Validator.value(value).email() : Validator.value(value).required().email();
    }
    // @ts-ignore-next-line
    baseInputValidator({ value, minLength = 2, maxLength = 100, isRequired = true }: { value: string, minLength?: number, maxLength?: number, isRequired?: boolean }): boolean {
        if (isRequired) {
            return Validator.value(value).required().minLength(minLength).maxLength(maxLength);
        }
        return Validator.value(value).minLength(minLength).maxLength(maxLength);
    }
    // @ts-ignore-next-line
    baseTextAreaValidator({ value, minLength = 2 }: { value: string, minLength?: number }): boolean {
        return Validator.value(value).required().minLength(minLength).maxLength(500);
    }
    get isValidationActivated(): boolean {
        return this.validation && this.validation.activated;
    }

    get isFormValid(): boolean {
        if (this.validation.errors.length !== 0) {
            return false;
        }
        if (this.requiredFields) {
            for (let i = 0; i < this.requiredFields.length; i++) {
                const key = this.requiredFields[i];
                const passedRecord = this.validation.passedRecords.find((item: { field: string, value: boolean }) => item.field === key);
                if (!passedRecord || passedRecord.value === false) {
                    return false;
                }
            }
            return true;
        }
        return this.validation.passedRecords.length === Object.keys(this.serverErrors).length;
    }
};
