<template>
<div v-if='data.status !== `cancelled`'
     class='b-company-time-card'
     @click='handleClick'>
    <div class='b-company-time-card__inner'>
        <div v-if='!data.full_day && duration > 30'
             class='b-company-time-card__time'>
            {{ time(data.dt_start) }}
        </div>
        <div class='b-company-time-card__title'>
            <p class='h-m-0'
               :class='{"b-company-time-card__overflow-text": duration >= 30 }'
               :style='styles'>
                {{ data.title }}
            </p>
        </div>
    </div>
    <slot></slot>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';

@Component({
    refs: [`infoBox`],
})
export default class CompanyTimeCard extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Object, required: true }) readonly data!: UnavailabilityEventInstancesType;
    $refs!: {
        infoBox: HTMLElement
    };

    get duration(): number {
        return Math.abs(+new Date(this.data.dt_start) - +new Date(this.data.dt_end)) / 36e5 * 60;
    }

    get styles() {
        if (this.$refs.infoBox && this.data.duration <= 30) {
            return { width: `${this.$refs.infoBox.offsetWidth - 20}px` };
        }
        return null;
    }

    time(time: string) {
        return this.getCurrentDateAsMinutesAMPM(new Date(time));
    }

    handleClick(event: Event):void {
        this.$emit('click', event);
    }
}
</script>

<style lang='sass'>
.b-company-time-card
    position: relative
    width: 100%
    display: flex
    padding: 5px
    border-radius: 5px
    background-color: #fff
    overflow: hidden
    box-shadow: $base-box-shadow
    z-index: 1

    &:hover
        box-shadow: 0 12px 24px 0 rgba(39,219,189,0.2)

    &__inner
        display: flex
        flex-wrap: wrap
        width: 100%
        align-self: flex-start

    &__time
        padding: 3px 0
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        font-weight: 500
        font-size: 12px

    &__overflow-text
        width: 140px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__title
        font-size: 12px
        padding: 3px 0
        width: 100%
        font-weight: 400
        display: flex
        justify-content: center
        word-break: break-word
        align-items: flex-start
        line-height: 1.5em
</style>
