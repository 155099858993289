import { lazyLoadView } from '@/helpers/url';
import { RouteType } from '@/types/router';

const settingsRoutes: Array<RouteType> = [
    {
        path: '/settings',
        name: `SettingsMainPage`,
        component: lazyLoadView(`settings/SettingsMainPage/SettingsMainPage`),
        meta: {
            showAppHeader: true,
        },
    },
];

export default settingsRoutes;
