import { User } from '@/types/User';
import { EN_LOCAL } from '@/helpers/locale';
import i18n from '@/locale';

const WORKING_HOURS: Array<string> = [
    '00:00:00',
    '00:30:00',
    '01:00:00',
    '01:30:00',
    '02:00:00',
    '02:30:00',
    '03:00:00',
    '03:30:00',
    '04:00:00',
    '04:30:00',
    '05:00:00',
    '05:30:00',
    '06:00:00',
    '06:30:00',
    '07:00:00',
    '07:30:00',
    '08:00:00',
    '08:30:00',
    '09:00:00',
    '09:30:00',
    '10:00:00',
    '10:30:00',
    '11:00:00',
    '11:30:00',
    '12:00:00',
    '12:30:00',
    '13:00:00',
    '13:30:00',
    '14:00:00',
    '14:30:00',
    '15:00:00',
    '15:30:00',
    '16:00:00',
    '16:30:00',
    '17:00:00',
    '17:30:00',
    '18:00:00',
    '18:30:00',
    '19:00:00',
    '19:30:00',
    '20:00:00',
    '20:30:00',
    '21:00:00',
    '21:30:00',
    '22:00:00',
    '22:30:00',
    '23:00:00',
    '23:30:00',
];

const MONTHS: Array<string> = [
    'CALENDAR.MONTH.JANUARY',
    'CALENDAR.MONTH.FEBRUARY',
    'CALENDAR.MONTH.MARCH',
    'CALENDAR.MONTH.APRIL',
    'CALENDAR.MONTH.MAY',
    'CALENDAR.MONTH.JUNE',
    'CALENDAR.MONTH.JULY',
    'CALENDAR.MONTH.AUGUST',
    'CALENDAR.MONTH.SEPTEMBER',
    'CALENDAR.MONTH.OCTOBER',
    'CALENDAR.MONTH.NOVEMBER',
    'CALENDAR.MONTH.DECEMBER',
];

const WEEK_DAYS: Array<string> = [
    'CALENDAR.WEEK.SUNDAY',
    'CALENDAR.WEEK.MONDAY',
    'CALENDAR.WEEK.TUESDAY',
    'CALENDAR.WEEK.WEDNESDAY',
    'CALENDAR.WEEK.THURSDAY',
    'CALENDAR.WEEK.FRIDAY',
    'CALENDAR.WEEK.SATURDAY',
];

const MORNING_TIME: Array<string> = [
    'CALENDAR.TIME.08.AM',
    'CALENDAR.TIME.08.15.AM',
    'CALENDAR.TIME.08.30.AM',
    'CALENDAR.TIME.08.45.AM',
    'CALENDAR.TIME.09.AM',
    'CALENDAR.TIME.09.15.AM',
    'CALENDAR.TIME.09.30.AM',
    'CALENDAR.TIME.09.45.AM',
    'CALENDAR.TIME.10.AM',
    'CALENDAR.TIME.10.15.AM',
    'CALENDAR.TIME.10.30.AM',
    'CALENDAR.TIME.10.45.AM',
    'CALENDAR.TIME.11.AM',
    'CALENDAR.TIME.11.15.AM',
    'CALENDAR.TIME.11.30.AM',
    'CALENDAR.TIME.11.45.AM',
    'CALENDAR.TIME.12.PM',
];

const AFTERNOON_TIME: Array<string> = [
    'CALENDAR.TIME.02.PM',
    'CALENDAR.TIME.02.15.PM',
    'CALENDAR.TIME.02.30.PM',
    'CALENDAR.TIME.02.45.PM',
    'CALENDAR.TIME.03.PM',
    'CALENDAR.TIME.03.15.PM',
    'CALENDAR.TIME.03.30.PM',
    'CALENDAR.TIME.03.45.PM',
    'CALENDAR.TIME.04.PM',
    'CALENDAR.TIME.04.15.PM',
    'CALENDAR.TIME.04.30.PM',
    'CALENDAR.TIME.04.45.PM',
    'CALENDAR.TIME.05.PM',
    'CALENDAR.TIME.05.15.PM',
    'CALENDAR.TIME.05.30.PM',
    'CALENDAR.TIME.05.45.PM',
    'CALENDAR.TIME.06.PM',
    'CALENDAR.TIME.06.15.PM',
    'CALENDAR.TIME.06.30.PM',
    'CALENDAR.TIME.06.45.PM',
    'CALENDAR.TIME.07.PM',
];

const FULL_TIME = [
    ...MORNING_TIME,
    ...[
        'CALENDAR.TIME.12.15.PM',
        'CALENDAR.TIME.12.30.PM',
        'CALENDAR.TIME.12.45.PM',
        'CALENDAR.TIME.01.PM',
        'CALENDAR.TIME.01.15.PM',
        'CALENDAR.TIME.01.30.PM',
        'CALENDAR.TIME.01.45.PM',
    ],
    ...AFTERNOON_TIME,
];

const FULL_TIME_EVERY_15 = [
    'CALENDAR.TIME.12.AM',
    'CALENDAR.TIME.12.15.AM',
    'CALENDAR.TIME.12.30.AM',
    'CALENDAR.TIME.12.45.AM',
    'CALENDAR.TIME.01.AM',
    'CALENDAR.TIME.01.15.AM',
    'CALENDAR.TIME.01.30.AM',
    'CALENDAR.TIME.01.45.AM',
    'CALENDAR.TIME.02.AM',
    'CALENDAR.TIME.02.15.AM',
    'CALENDAR.TIME.02.30.AM',
    'CALENDAR.TIME.02.45.AM',
    'CALENDAR.TIME.03.AM',
    'CALENDAR.TIME.03.15.AM',
    'CALENDAR.TIME.03.30.AM',
    'CALENDAR.TIME.03.45.AM',
    'CALENDAR.TIME.04.AM',
    'CALENDAR.TIME.04.15.AM',
    'CALENDAR.TIME.04.30.AM',
    'CALENDAR.TIME.04.45.AM',
    'CALENDAR.TIME.05.AM',
    'CALENDAR.TIME.05.15.AM',
    'CALENDAR.TIME.05.30.AM',
    'CALENDAR.TIME.05.45.AM',
    'CALENDAR.TIME.06.AM',
    'CALENDAR.TIME.06.15.AM',
    'CALENDAR.TIME.06.30.AM',
    'CALENDAR.TIME.06.45.AM',
    'CALENDAR.TIME.07.AM',
    'CALENDAR.TIME.07.15.AM',
    'CALENDAR.TIME.07.30.AM',
    'CALENDAR.TIME.07.45.AM',
    'CALENDAR.TIME.08.AM',
    'CALENDAR.TIME.08.15.AM',
    'CALENDAR.TIME.08.30.AM',
    'CALENDAR.TIME.08.45.AM',
    'CALENDAR.TIME.09.AM',
    'CALENDAR.TIME.09.15.AM',
    'CALENDAR.TIME.09.30.AM',
    'CALENDAR.TIME.09.45.AM',
    'CALENDAR.TIME.10.AM',
    'CALENDAR.TIME.10.15.AM',
    'CALENDAR.TIME.10.30.AM',
    'CALENDAR.TIME.10.45.AM',
    'CALENDAR.TIME.11.AM',
    'CALENDAR.TIME.11.15.AM',
    'CALENDAR.TIME.11.30.AM',
    'CALENDAR.TIME.11.45.AM',
    'CALENDAR.TIME.12.PM',
    'CALENDAR.TIME.12.15.PM',
    'CALENDAR.TIME.12.30.PM',
    'CALENDAR.TIME.12.45.PM',
    'CALENDAR.TIME.01.PM',
    'CALENDAR.TIME.01.15.PM',
    'CALENDAR.TIME.01.30.PM',
    'CALENDAR.TIME.01.45.PM',
    'CALENDAR.TIME.02.PM',
    'CALENDAR.TIME.02.15.PM',
    'CALENDAR.TIME.02.30.PM',
    'CALENDAR.TIME.02.45.PM',
    'CALENDAR.TIME.03.PM',
    'CALENDAR.TIME.03.15.PM',
    'CALENDAR.TIME.03.30.PM',
    'CALENDAR.TIME.03.45.PM',
    'CALENDAR.TIME.04.PM',
    'CALENDAR.TIME.04.15.PM',
    'CALENDAR.TIME.04.30.PM',
    'CALENDAR.TIME.04.45.PM',
    'CALENDAR.TIME.05.PM',
    'CALENDAR.TIME.05.15.PM',
    'CALENDAR.TIME.05.30.PM',
    'CALENDAR.TIME.05.45.PM',
    'CALENDAR.TIME.06.PM',
    'CALENDAR.TIME.06.15.PM',
    'CALENDAR.TIME.06.30.PM',
    'CALENDAR.TIME.06.45.PM',
    'CALENDAR.TIME.07.PM',
    'CALENDAR.TIME.07.15.PM',
    'CALENDAR.TIME.07.30.PM',
    'CALENDAR.TIME.07.45.PM',
    'CALENDAR.TIME.08.PM',
    'CALENDAR.TIME.08.15.PM',
    'CALENDAR.TIME.08.30.PM',
    'CALENDAR.TIME.08.45.PM',
    'CALENDAR.TIME.09.PM',
    'CALENDAR.TIME.09.15.PM',
    'CALENDAR.TIME.09.30.PM',
    'CALENDAR.TIME.09.45.PM',
    'CALENDAR.TIME.10.PM',
    'CALENDAR.TIME.10.15.PM',
    'CALENDAR.TIME.10.30.PM',
    'CALENDAR.TIME.10.45.PM',
    'CALENDAR.TIME.11.PM',
    'CALENDAR.TIME.11.15.PM',
    'CALENDAR.TIME.11.30.PM',
    'CALENDAR.TIME.11.45.PM',
    'CALENDAR.TIME.12.AM',
];

const FULL_TIME_EVERY_30 = [
    'CALENDAR.TIME.12.AM',
    'CALENDAR.TIME.12.30.AM',
    'CALENDAR.TIME.01.AM',
    'CALENDAR.TIME.01.30.AM',
    'CALENDAR.TIME.02.AM',
    'CALENDAR.TIME.02.30.AM',
    'CALENDAR.TIME.03.AM',
    'CALENDAR.TIME.03.30.AM',
    'CALENDAR.TIME.04.AM',
    'CALENDAR.TIME.04.30.AM',
    'CALENDAR.TIME.05.AM',
    'CALENDAR.TIME.05.30.AM',
    'CALENDAR.TIME.06.AM',
    'CALENDAR.TIME.06.30.AM',
    'CALENDAR.TIME.07.AM',
    'CALENDAR.TIME.07.30.AM',
    'CALENDAR.TIME.08.AM',
    'CALENDAR.TIME.08.30.AM',
    'CALENDAR.TIME.09.AM',
    'CALENDAR.TIME.09.30.AM',
    'CALENDAR.TIME.10.AM',
    'CALENDAR.TIME.10.30.AM',
    'CALENDAR.TIME.11.AM',
    'CALENDAR.TIME.11.30.AM',
    'CALENDAR.TIME.12.PM',
    'CALENDAR.TIME.12.30.PM',
    'CALENDAR.TIME.01.PM',
    'CALENDAR.TIME.01.30.PM',
    'CALENDAR.TIME.02.PM',
    'CALENDAR.TIME.02.30.PM',
    'CALENDAR.TIME.03.PM',
    'CALENDAR.TIME.03.30.PM',
    'CALENDAR.TIME.04.PM',
    'CALENDAR.TIME.04.30.PM',
    'CALENDAR.TIME.05.PM',
    'CALENDAR.TIME.05.30.PM',
    'CALENDAR.TIME.06.PM',
    'CALENDAR.TIME.06.30.PM',
    'CALENDAR.TIME.07.PM',
    'CALENDAR.TIME.07.30.PM',
    'CALENDAR.TIME.08.PM',
    'CALENDAR.TIME.08.30.PM',
    'CALENDAR.TIME.09.PM',
    'CALENDAR.TIME.09.30.PM',
    'CALENDAR.TIME.10.PM',
    'CALENDAR.TIME.10.30.PM',
    'CALENDAR.TIME.11.PM',
    'CALENDAR.TIME.11.30.PM',
    'CALENDAR.TIME.12.AM',
];

function dateAsEventTime(date: Date, user: null | User): string {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let userLocale: string = 'en';
    if (user) {
        userLocale = i18n.locale;
    }
    // @ts-ignore-next-line
    return date.toLocaleDateString(userLocale, options);
}

function getDayOfWeek(date: Date): string {
    return new Date(date).toLocaleString('en-us', { weekday: 'long' });
}

function getDateAsIsoString(date: Date, withOutDate: boolean = false): string {
    const currentMonth: number = date.getMonth() + 1;
    let currentMonthString: string = currentMonth.toString();
    if (currentMonthString.length === 1) {
        currentMonthString = `0${currentMonthString}`;
    }
    const day = withOutDate ? '' : `-${date.getDate()}`;
    return `${date.getFullYear()}-${currentMonthString}${day}`;
}

function getDateWithDelayInDays(date: Date, delay: number): Date {
    return new Date(date.setDate(date.getDate() + delay));
}

/* eslint-disable-next-line no-shadow */
function getPrettyStringDate(date: Date, i18n: any, withoutTimezoneCalc?: boolean): string {
    // example: Friday, October 25, 2019
    // if withoutTimezoneCalc === true -> get the same date from string without timezone calculate
    const sameDayAsString = withoutTimezoneCalc ? new Date(date.getTime() - date.getTimezoneOffset() * -60000) : date;
    return `${i18n.t(WEEK_DAYS[sameDayAsString.getDay()])}, ${i18n.t(MONTHS[sameDayAsString.getMonth()])} ${sameDayAsString.getDate()}, ${sameDayAsString.getFullYear()}`;
}

function formatDateForTitle(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function getMinutesOrHoursAsString(value: number) {
    return `${value}`.length === 1 ? `0${value}` : `${value}`;
}

function formatAMPM({ date, locale, useUTC = true }: { date: Date, locale: string, useUTC?: boolean }) {
    let hours = useUTC ? date.getUTCHours() : date.getHours();
    let minutes = date.getMinutes();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        if (minutes === 59) {
            minutes = 0;
            hours = hours === 12 ? 12 : hours + 1;
        }
        return `${getMinutesOrHoursAsString(hours)}:${getMinutesOrHoursAsString(minutes)} ${ampm}`;
    }
    if (minutes === 59) {
        minutes = 0;
        hours = hours === 24 ? 24 : hours + 1;
    }
    return `${getMinutesOrHoursAsString(hours)}h${getMinutesOrHoursAsString(minutes)}`;
}

function parseToUTCDate(string: string) {
    const date = new Date(string);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const day = date.getUTCDate();
    return new Date(year, month, day, hour, minute);
}

function getCurrentDateAsHoursAMPM({ date, locale, useUTC = true }: { date?: Date | null, locale: string, useUTC?: boolean }) {
    const localDate = date || new Date();
    let hours = useUTC ? localDate.getUTCHours() : localDate.getHours();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        return `${getMinutesOrHoursAsString(hours)}:00 ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h00`;
}

function currentHoursCalculation(calculationNumber: number, locale: string, date?: Date) {
    const localDate = date ? new Date(date) : new Date();
    let hours = localDate.getHours() + calculationNumber;
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 && hours < 24 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
        return `${getMinutesOrHoursAsString(hours)}:00 ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h00`;
}

function getCurrentDateAsMinutes({ date, locale, useUTC = false }: { date: Date | null, locale: string, useUTC: boolean }) {
    const localDate = date || new Date();
    const hours = useUTC ? localDate.getUTCHours() : localDate.getHours();
    const minutes = localDate.getMinutes();
    return `${getMinutesOrHoursAsString(hours)}:${getMinutesOrHoursAsString(minutes)}`;
}

function getCurrentDateAsMinutesAMPM({ date, locale, useUTC = false }: { date: Date | null, locale: string, useUTC: boolean }) {
    const localDate = date || new Date();
    let hours = useUTC ? localDate.getUTCHours() : localDate.getHours();
    const minutes = localDate.getMinutes();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        return `${getMinutesOrHoursAsString(hours)}:${getMinutesOrHoursAsString(minutes)} ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h${getMinutesOrHoursAsString(minutes)}`;
}

function getFirstDayOfMonth(date: Date | null) {
    const localDate = date || new Date();
    return new Date(localDate.getFullYear(), localDate.getMonth(), 1);
}

function getLastDayOfMonth(date: Date | null, delay = 0) {
    const localDate = date || new Date();
    return new Date(localDate.getFullYear(), localDate.getMonth() + 1, delay);
}

function getWeekNumber(date: Date) {
    const localDate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    localDate.setUTCDate(localDate.getUTCDate() + 4 - (localDate.getUTCDay() || 7));
    const yearStart: Date = new Date(Date.UTC(localDate.getUTCFullYear(), 0, 1));
    return Math.ceil((((+localDate - +yearStart) / 86400000) + 1) / 7);
}

function getHoursAndMinutesFromAMPM(ampm: string): { hours: number, minutes: number } {
    // 03:00 am || 03:00 pm for En
    // 8h00 || 13h00 Fr
    if (ampm.includes('h')) {
        const timeDate: Array<string> = ampm.split('h');
        return {
            hours: parseInt(timeDate[0], 10),
            minutes: parseInt(timeDate[1], 10),
        };
    } else if (ampm.includes(':')) {
        const timeDate: Array<string> = ampm.split(':');
        const isPm = ampm.includes('pm');
        let hours = parseInt(timeDate[0], 10);
        if (isPm && hours < 12) hours += 12;
        if (!isPm && hours === 12) hours -= 12;
        return {
            hours,
            minutes: parseInt(timeDate[1], 10),
        };
    }

    return {
        hours: 0,
        minutes: 0,
    };
}

function dateToString(date: Date, nightTime: boolean = false, is2359: boolean = false): string {
    const day = `${date.getFullYear()}-${getMinutesOrHoursAsString(date.getMonth() + 1)}-${getMinutesOrHoursAsString(date.getDate())}`;
    let time = '';
    if (is2359) {
        time = '23:59:00';
    } else if (nightTime) {
        time = '00:00:00';
    } else {
        time = `${date.toTimeString().slice(0, 8)}`;
    }
    // eslint-disable-next-line max-len
    return `${day}T${time}.000Z`;
}

function equalByDay(date: Date, date2: Date): boolean {
    // eslint-disable-next-line max-len
    return (
        date.getDate() === date2.getDate() &&
        date.getMonth() === date2.getMonth() &&
        date.getFullYear() === date2.getFullYear()
    );
}
function equalByTodayTomorrowWeekend(date: Date): boolean {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const currentDay = new Date(date);
    const friday = today.getDay() === 5;
    const saturday = today.getDay() === 6;
    const sunday = new Date(today);
    const mondayAfterSunday = new Date(today);
    if (friday) {
        sunday.setDate(tomorrow.getDate() + 1);
        mondayAfterSunday.setDate(tomorrow.getDate() + 2);
    }
    if (saturday) {
        mondayAfterSunday.setDate(tomorrow.getDate() + 1);
    }
    return (
        equalByDay(today, currentDay) ||
        equalByDay(tomorrow, currentDay) ||
        equalByDay(sunday, currentDay) ||
        equalByDay(mondayAfterSunday, currentDay)
    );
}

function getDayDateAtNight(date?: Date): Date {
    const localDate = date || new Date();
    localDate.setHours(0, 0, 0, 0);
    return localDate;
}

export {
    WORKING_HOURS,
    MONTHS,
    WEEK_DAYS,
    MORNING_TIME,
    AFTERNOON_TIME,
    FULL_TIME,
    FULL_TIME_EVERY_15,
    FULL_TIME_EVERY_30,
    dateAsEventTime,
    getDateAsIsoString,
    getWeekNumber,
    getDateWithDelayInDays,
    getPrettyStringDate,
    formatDateForTitle,
    formatAMPM,
    currentHoursCalculation,
    getCurrentDateAsHoursAMPM,
    getCurrentDateAsMinutes,
    getCurrentDateAsMinutesAMPM,
    getHoursAndMinutesFromAMPM,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getMinutesOrHoursAsString,
    dateToString,
    equalByDay,
    equalByTodayTomorrowWeekend,
    parseToUTCDate,
    getDayDateAtNight,
};
