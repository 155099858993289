<template>
<div class='b-worker__info'
     :class='wrapperClass'>
    <div class='b-worker__avatar'
         :style='workerStyle'>
        <div :class='{ "b-worker__avatar__color--gradient": !worker.id }'
             class='b-worker__avatar__color'>
            <FwIcon
                v-if='!worker.avatar_url'
                class='b-worker__avatar__icon'
                icon='no-avatar'
                :size='`${avatarSize / 2}`'
                color='#fff'>
            </FwIcon>
        </div>
    </div>
    <div v-if='!hideInfo'
         class='b-worker__inner'>
        <div class='h-flex h-flex-dir-column'>
            <div class='b-worker__position'
                 :class='[
                     nameClass,
                     { "b-worker__position--ghost": !worker.first_name }
                 ]'>
                <span v-if='worker.first_name'>
                    {{ worker.first_name }}
                </span>
                <span v-if='worker.last_name'>
                    {{ worker.last_name }}
                </span>
                <span v-if='worker.full_name'>
                    {{ worker.full_name }}
                </span>
                <span v-else-if='!worker.first_name && !worker.last_name'>
                    {{ $tc('WORKERS.USER.NO_NAME') }}
                </span>
            </div>
            <div v-if='showAsideData'
                 class='h-flex'>
                <div :class='{
                         "b-worker__info__tag--success": worker.is_taking_appointments,
                         "b-worker__info__tag--negative": !worker.is_taking_appointments
                     }'
                     class='b-worker__info__tag'>
                    {{ worker.is_taking_appointments ? $t('WORKER.TAKES.APPOINTMENTS') : $t('WORKER.TAKES.NOT.APPOINTMENTS') }}
                </div>
                <div v-if='worker.is_admin'
                     class='b-worker__info__tag b-worker__info__tag--grey'>
                    Admin
                </div>
            </div>
            <span v-else-if='worker.role_name'
                  class='b-worker__name'>
                {{ worker.role_name }}
            </span>
            <div v-else
                 class='b-worker__name'>
                <span v-if='worker.company_title'>
                    {{ worker.company_title }}
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { workerDataType } from '@/types/User';
import { separateColor, hexToRgbA } from '@/helpers/colors';

const WORKER_AVATAR_SIZE_SMALL: string = 'small';
const WORKER_AVATAR_SIZE_NORMAL: string = 'normal';
const AVAILABLE_SIZES: Array<string> = [WORKER_AVATAR_SIZE_SMALL, WORKER_AVATAR_SIZE_NORMAL];

const AVAILABLE_ALIGN: Array<string> = ['base', 'right'];

@Component
export default class WorkerAvatar extends Vue {
    @Prop({ type: Object, required: true }) readonly worker!: workerDataType
    @Prop({ type: Number, default: 48 }) readonly avatarSize!: number
    @Prop({ type: String, default: null }) readonly nameClass!: string
    @Prop({ type: Boolean, default: false }) readonly hideInfo!: boolean
    @Prop({ type: Boolean, default: false }) readonly showAsideData!: boolean
    @Prop({ type: String, default: 'normal', validator: (type: string) => AVAILABLE_SIZES.includes(type) })
    readonly size!: string
    @Prop({ type: String, default: 'base', validator: (align: string) => AVAILABLE_ALIGN.includes(align) })
    readonly align!: string

    get workerStyle() {
        return {
            backgroundImage: `${this.avatarColor}`,
            width: `${this.avatarSize}px`,
            height: `${this.avatarSize}px`,
        };
    }
    get avatarColor() {
        let result;
        if (this.worker.color) {
            const colorArray = separateColor(this.worker.color);
            result = this.worker.avatar_url ?
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)},  ${hexToRgbA(colorArray[1], 0.5)}),
             url(${this.worker.avatar_url})` :
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 165, 1))`;
        }
        return result;
    }
    get wrapperClass() {
        return [
            `b-worker__info--${this.size}`,
            `b-worker__info--align-${this.align}`,
        ];
    }
}
</script>

<style lang='sass'>
.b-worker
    &__avatar
        border-radius: 50%
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        position: relative
        overflow: hidden

        &__color
            opacity: 0.4
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: center

            &--gradient
                opacity: 1

        &__icon
            opacity: 1

    &__position
        text-transform: capitalize
        font-size: 22px
        line-height: 26px
        font-weight: 600
        width: 100%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 204px

        &--ghost
            opacity: 0.4

    &__name
        text-transform: uppercase
        color: $main-gray
        font-weight: 500
        font-size: 11px
        letter-spacing: 0.3px
        margin-top: 5px
        width: 100%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 204px

    &__inner
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 15px
        flex: 1
        max-width: 204px

    &__info
        display: flex
        flex: 5

        &--align-right
            align-items: flex-end
            flex: 3

        &--small
            .b-worker__position
                font-size: 18px

            .b-worker__avatar
                margin-right: 0

        &__tag
            padding: 3px 4px
            border-radius: 4px
            font-size: 10px
            font-weight: 500
            text-align: center

            &--success
                color: $aqua-marine
                background-color: rgba(39, 219, 189, 0.2)

            &--negative
                color: $cancel-red
                background-color: rgba(255, 0, 77, 0.2)

            &--grey
                background-color: #F2F4F6
                color: $alt-blue

            &:not(:first-of-type)
                margin-left: 5px
</style>
