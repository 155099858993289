export default {
    state: {
        appointmentData: null,
        addAppointmentData: null,
        addCompaniesListData: [],
    },
    mutations: {
        APPOINTMENT_DATA(state:any, data:any) {
            state.appointmentData = data;
        },
        ADD_APPOINTMENT_DATA(state:any, data:any) {
            state.addAppointmentData = data;
        },
        ADD_COMPANIES_LIST_DATA(state:any, data:any) {
            state.addCompaniesListData = data;
        },
    },
    actions: {
        storeAppointmentData({ commit, state }: any, payload: object) {
            commit('APPOINTMENT_DATA', payload);
        },
    },
};

