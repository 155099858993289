<template>
<div class='b-route-wrapper'>
    <portal-target name='popupWrapper'></portal-target>
    <FixedBackground></FixedBackground>
    <div class='b-main-panel-wrapper'>
        <AppHeader v-if='showAppHeader'></AppHeader>
        <div class='h-pos-rel container h-width-100p'>
            <GoBackButton
                v-if='showBackButton'
                class='b-route-wrapper__back_arrow'>
            </GoBackButton>
        </div>
        <router-view></router-view>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { FixedBackground } from '@/components/global/FixedBackground';
import { AppHeader } from '@/components/global/AppHeader';
import { GoBackButton } from '@/components/simple/GoBackButton';

@Component({
    components: {
        GoBackButton,
        FixedBackground,
        AppHeader,
    },
})
export default class RouterWrapper extends Vue {
    get showAppHeader(): boolean {
        return this.$route.meta ? this.$route.meta.showAppHeader : false;
    }

    get showBackButton(): boolean {
        return this.$route.meta ? this.$route.meta.backButton : false;
    }
}
</script>

<style lang='sass'>
.b-route-wrapper
    width: 100%
    min-height: 100vh
    position: relative
    display: flex

    &__back_arrow
        left: 100px
        top: 47px

.b-main-panel-wrapper
    width: 100%
    display: flex
    flex-direction: column

@include media('<desktop')
    .b-route-wrapper
        &__back_arrow
            margin-left: 10px
            margin-top: 20px
            position: static
</style>
