export default {
    'CANCEL.REASON.HEADER': 'Please specify the reason of the cancellation',
    'CANCEL.ORIGIN.LABEL': 'Origin of cancellation',
    'CANCEL.REASON.LABEL': 'Reason of cancellation',
    'CANCEL.REASON.PERSON.PLACEHOLDER': 'Select a person',
    'CANCEL.REASON.PLACEHOLDER': 'Select a reason',
    'CANCEL.BUTTON.APPOINTMENT': 'Cancel appointment',
    'PAST.APPOINTMENTS.CANT.BE.CANCELED': 'Past appointment can’t be canceled',
    'CANCEL.SUCCESS.APPOINTMENT': 'Appointment has been cancelled',
    'CANCEL.GO.HOME.BUTTON': 'Back to the home page',
    'CANCEL.REASON.EMERGENCY': 'Company emergency',
    'CANCEL.REASON.SUITABILITY': 'Personal reason',
    'CANCEL.REASON.COMPLIANCE': 'Compliance site',
    'CANCEL.REASON.INTERVENTION.NECESSARY': 'Intervention no longer necessary',
    'CANCEL.REASON.WORKER.MISTAKE': 'Worker planning mistake',
    'CANCEL.REASON.WORKER.SUITABILITY': 'Worker suitability',
    'CANCEL.REASON.DELAY': 'Delay',
    'CANCEL.REASON.STOCK': 'Stock',
    'CANCEL.REASON.GOOD.AGREEMENT': 'Good for agreemenзt dead',
    'CANCEL.REASON.BAD.APPRECIATION': 'Bad appreciation distance',
    'CANCEL.REASON.REQUEST.MANAGER': 'At the request of the managers',
    'CANCEL.REASON.BAD.WEATHER': 'Bad weather / special event',
    'CANCEL.REASON.OTHER.URGENT': 'Other more urgent intervention',
    'CANCEL.REASON.PLAN.ERROR': 'Planning error',
    'CANCEL.REASON.BAD.COMPLIANCE': 'Bad compliance',
    'CANCEL.REASON.DELAYED': 'Delayed reception',
    'CANCEL.REASON.RIN.CANCELLATION': 'RIN cancellation',
    'CANCEL.REASON.RIN.TRANSFORMATION': 'RIN transformation',
    'CANCEL.REASON.STOCK.ISSUE.DELAY': 'Stock issue (delay)',
    'CANCEL.ORIGIN.CUSTOMER': 'Client',
    'CANCEL.ORIGIN.WORKER': 'Worker',
    'CANCEL.ORIGIN.IMA': 'IMA Protect',
    'CANCEL.ORIGIN.MANAGER': 'Manager',
    'CANCEL.ORIGIN.STOCK': 'Stock',
    'CANCEL.ORIGIN.TECH.INCOMPATIBILITY': 'Technical incompatibility',
    'CANCEL.TECHNICIAN.TRAVEL': 'Technician travel',
};
