<template>
<div v-if='companyData && !companyData.is_address_confirmed' class='b-add-company__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ companyData ? this.companyData.is_address_confirmed ? $t('LABEL.ADDRESS') : $t('COMPANY.POPUP.LOCATION.TITLE') : $t('LOADING.TEXT') }}
        </h1>
    </div>
    <FinalePopup
        v-if='showSuccessPopup'
        :subMessage='$t("COMPANIES.POPUP.SUCCESS.CHANGED.MESSAGE")'
        @close='closeFinalePopup'>
    </FinalePopup>
    <LocationPopup
        v-if='showLocationPopup'
        :isRequestSending='isRequestSending'
        :companyAddressData='companyAddressData'
        @closeLocation='closeLocation'
        @confirmAddress='addressRequest'>
    </LocationPopup>
    <EditAddressForm
        v-if='companyData'
        :isRequestSending='isRequestSending'
        :companyData='companyData'
        @delete='showDeletePopup = true'
        @onSubmit='sendData'>
    </EditAddressForm>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EditAddressForm } from '@/components/forms/edit/EditAddressForm';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { LocationPopup } from '@/components/popups/LocationPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { CompanyAddressDataType } from '@/types/Companies';
import { EventBus } from '@/utils/EventBus';
import { dateToString } from '@/helpers/dates';

@Component({
    components: {
        EditAddressForm,
        FinalePopup,
        LocationPopup,
    },
})
export default class AddOrEditAddressByForm extends Vue {
    showLocationPopup: boolean = false;
    showSuccessPopup: boolean = false;
    companyData: any = null;
    showDeletePopup: boolean = false;
    isRequestSending: boolean = false;
    isAddressConfirmed: boolean = false;
    companyAddressData: any = null;
    get addressRequest() {
        return !this.companyData.is_address_confirmed ? this.confirmAddress : this.sendDataToServer;
    }
    goToCompaniesPage() {
        this.$router.push({ path: '/consult/companies' });
    }
    closeLocation() {
        this.showLocationPopup = false;
    }
    sendData(data: CompanyAddressDataType) {
        if (this.companyData!.is_address_confirmed) {
            this.openLocationPopup(data);
        } else {
            this.sendAddressConfirm(data);
        }
    }

    confirmAddress(data: CompanyAddressDataType) {
        this.isAddressConfirmed = true;
        this.sendAddressConfirm(data);
    }

    async sendDataToServer() {
        this.isRequestSending = true;
        try {
            const { address, dateFrom, latitude, longitude } = this.companyAddressData;
            const payload = {
                is_address_confirmed: this.companyData!.is_address_confirmed,
                updatable_action: 'ValidateCompanyAddress',
                dt_execute_at: dateToString(new Date(dateFrom as string), true),
                updatable_id: this.$route.params.id,
                updatable_type: 'Company',
                new_data: {
                    address: `${address}`,
                    latitude,
                    longitude,
                },
            };
            const response = await WorkersWebApi.updateTechnicianSchedulesData(payload);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', 'success');
            this.$router.push({ path: `/consult/companies/${this.$route.params.id}` });
            this.$store.dispatch('storeCompanyUpdateData', response.data.company.update_request);
            this.isRequestSending = false;
            this.showSuccessPopup = true;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async sendAddressConfirm(data: CompanyAddressDataType) {
        this.isRequestSending = true;
        try {
            const company = await AppointmentWebApi.changeCompanyData({
                address_confirmed: this.isAddressConfirmed,
                // @ts-ignore-next-line
                address: data.address,
                title: this.companyData.name,
                siret: this.companyData.siret,
                phone: this.companyData.phone,
                insee_code: this.companyData.insee_code,
                siren: this.companyData.siren,
                contact_email: this.companyData.contact_email,
            }, this.companyData.id);
            this.isRequestSending = false;
            this.$store.commit('PLAN_COMPANY', company.data.company);
            if (company.data.company.is_address_confirmed) {
                this.$router.push({ path: `/consult/companies/${this.$route.params.id}` });
                this.showLocationPopup = false;
            } else {
                this.showLocationPopup = true;
            }
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', 'success');
            this.companyData = company.data.company;
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
    async openLocationPopup(data: CompanyAddressDataType) {
        try {
            const response = await AppointmentWebApi.sendAddressForGeolocation({ address: data.address });
            this.companyAddressData = { ...data, ...response.data.geolocation };
            this.showLocationPopup = true;
        } catch ({ response }) {
            // @ts-ignore-next-line
            EventBus.$emit(`sent-notification`, response.errors[0]);
        }
    }

    closePopup() {
        this.showDeletePopup = false;
    }
    closeFinalePopup() {
        this.showSuccessPopup = false;
        this.$router.push({ path: `/consult/companies/${this.$route.params.id}` });
    }

    async created() {
        if (this.$store.state.CalendarPageStore.company) {
            this.companyData = this.$store.state.CalendarPageStore.company;
        } else {
            this.loading = true;
            try {
                const data = await AppointmentWebApi.getSocietyItemData(this.$route.params.id);
                this.$store.commit('PLAN_COMPANY', data.company);
                this.companyData = data.company;
                this.$store.dispatch('storeCompanyUpdateData', data.company.update_request);
                this.loading = false;
            } catch ({ response }) {
                // @ts-ignore-next-line
                EventBus.$emit(`sent-notification`, response.errors[0]);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang='sass'>
    .b-add-company
        &__wrapper
            width: 1000px
            margin-bottom: 50px

            @include media('<=phone')
                width: 100%
</style>
