import Vue from 'vue';
import uiFramework from 'ui-framework';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import SimpleVueValidation from 'simple-vue-validator';
import { ReactiveRefs } from 'vue-reactive-refs';
import PortalVue from 'portal-vue';
import { User } from '@/types/User';
// eslint-disable-next-line
import * as firebase from 'firebase/app';
import { initBugsnag } from '@/helpers/bugsnag';
import App from './App.vue';
import store from './store/index';
import router from './router';

import CalizyGlobalMixin from './mixins/calizy-global';
import i18n from './locale';
import { getUserFromStorage } from './helpers/auth';
import { VALIDATOR_TEMPLATES } from './helpers/validators';
// import 'firebase/firestore';
// eslint-disable-next-line
import 'firebase/analytics';

if (document.location.hostname.search('ima-protect.calizy.com') !== -1) {
    const firebaseConfig = {
        apiKey: 'AIzaSyArEKuhyvCuENErXMQdKqatoq87irUnT44',
        authDomain: 'calizy-258209.firebaseapp.com',
        databaseURL: 'https://calizy-258209.firebaseio.com',
        projectId: 'calizy-258209',
        storageBucket: 'calizy-258209.appspot.com',
        messagingSenderId: '560097469597',
        appId: '1:560097469597:web:12364c229b0eb36a08a074',
        measurementId: 'G-EVEF7MQ364',
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    // alias
    Vue.prototype.$analytics = firebase.analytics();

    initBugsnag();
}

const user: null | User = getUserFromStorage();
if (user) {
    store.commit('GlobalStore/mutationLoginUser', user);
}

Vue.use(SimpleVueValidation, {
    templates: VALIDATOR_TEMPLATES,
});
Vue.use(PortalVue);
Vue.mixin(CalizyGlobalMixin);
Vue.use(ReactiveRefs);

Vue.config.productionTip = false;
Vue.use(uiFramework);

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components';
Vue.config.warnHandler = (msg: string | null, vm: Vue | null, trace: string | null) => {
    // temp solution
    if (msg && msg.startsWith(ignoreWarnMessage)) {
        msg = null;
        vm = null;
        trace = null;
    }
};

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
