<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'
     style='overflow-y: auto; align-items: flex-start'>
    <FwPopup
        class='b-popup-relative'
        iconColor='#BEC7D4'
        width='1000px'
        padding='30px 40px 0'
        title=''
        :allowBackgroundScrolling='false'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        disableClosingOnClickOutside
        @close='$emit(`closePopup`)'>
        <template #body>
            <ConsultCompaniesByTabsPage
                :companiesCardsProps='companiesCards'
                :totalPageProp='totalPageProp'
                :currentPageProp='currentPageProp'>
            </ConsultCompaniesByTabsPage>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ConsultCompaniesByTabsPage } from '@/views/consult/companies/ConsultCompaniesByTabsPage';
import { CompanyData } from '@/types/Companies';

@Component({
    components: {
        ConsultCompaniesByTabsPage,
    },
})
export default class SearchCompanyPopup extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly companiesCards!: Array<CompanyData>;
    @Prop({ type: Number, default: 0 }) readonly totalPageProp!: number;
    @Prop({ type: Number, default: 1 }) readonly currentPageProp!: number;
}
</script>

<style lang='sass'>
.b-book-appointment
    &__popup
        .b-cancel-appointment__form__events
            margin-bottom: 20px

.b-popup-relative
    display: grid!important
    min-height: 90vh!important
    margin: 15px 0 15px!important
    position: relative!important
    left: 0!important
    transform: translate(0, 0)!important
    top: 0!important

    .b-intervention-code__button
        height: 40px!important
        padding: 0 20px

    .pagination
        border-radius: 0 0 20px 20px

    .fw-input--search
        background-color: rgba(33,63,107,0.05)

.fw-button--transparent
    background-color: transparent !important
</style>
