import global from './global';
import plan from './plan';
import cancel from './cancel';
import form from './form';
import calendar from './calendar';
import consult from './consult';
import auth from './auth';
import settings from './settings';
import errors from './errors';

export default {
    ...global,
    ...plan,
    ...cancel,
    ...form,
    ...calendar,
    ...consult,
    ...auth,
    ...settings,
    ...errors,
};
