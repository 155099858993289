import { lazyLoadView } from '@/helpers/url';
import { CONSULT_PREFIX, WORKERS_PREFIX } from '@/routes/config';

import {
    WORKERS_CALENDAR,
} from '@/helpers/calendar';

export default [
    {
        path: `${CONSULT_PREFIX}${WORKERS_PREFIX}/:page([0-9]+)?`,
        name: `ConsultWorkersPage`,
        component: lazyLoadView(`consult/workers/ConsultWorkersPage/ConsultWorkersPage`),
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${WORKERS_PREFIX}/calendar/:id`,
        name: WORKERS_CALENDAR,
        component: lazyLoadView(`consult/common/CalendarPage/CalendarPage`),
        meta: {
            isManualTabShown: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${WORKERS_PREFIX}/:id`,
        name: `ConsultWorkerPage`,
        component: lazyLoadView(`consult/workers/ConsultWorkerPage/ConsultWorkerPage`),
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${WORKERS_PREFIX}/edit/:id`,
        name: `ConsultWorkersEditPage`,
        component: lazyLoadView(`consult/workers/ConsultWorkersEditPage/ConsultWorkersEditPage`),
    },
];
