const ON_SITE_ID: string = `location_category_on_site`;
const BY_PHONE_ID: string = `location_category_by_phone`;
const IN_BRANCH_PUBLIC_ID: string = `location_category_in_branch_public`;
const IN_BRANCH_VIDEO_ID: string = `location_category_in_branch_video`;
const IN_BRANCH_PRIVATE_VIDEO_ID: string = `location_category_in_branch_private`;
const IN_BRANCH_BY_PHONE_PUBLIC_ID: string = `location_category_in_branch_by_phone_public`;
const IN_BRANCH_BY_PHONE_PRIVATE_ID: string = `location_category_in_branch_by_phone_private`;

const LOCATION_SKILLS_SEARCH_NAMES = [
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
];

export {
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    LOCATION_SKILLS_SEARCH_NAMES,
};
