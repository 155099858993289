import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    PLAN_AN_APPOINTMENT_CALENDAR,
    COMPANY_CALENDAR,
    WORKERS_CALENDAR,
} from '@/helpers/calendar';
import { getFirstDayOfMonth, getLastDayOfMonth, dateToString, getDateWithDelayInDays, getDateAsIsoString } from '@/helpers/dates';
import { InstanceAppointmentsWithSlotsType } from '@/types/Events';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import { PayloadAvailabilitiesDataType } from '@/types/Calendar';
import { EventsWebApi } from '@/api/events/EventsApi';
import { AvailabilityType } from '@/types/Events/Workers';

@Component
export default class CalendarMixin extends Vue {
    get isCompanyCalendar(): boolean {
        return this.$route.name === COMPANY_CALENDAR;
    }

    get isWorkersCalendar(): boolean {
        return this.$route.name === WORKERS_CALENDAR;
    }

    get isPlanAnAppointmentCalendar(): boolean {
        return this.$route.name === PLAN_AN_APPOINTMENT_CALENDAR;
    }

    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }

    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }

    get appointmentReservedSlotsExists(): Array<string> {
        return this.$store.state.CalendarPageStore.appointment_reserved_slots_exists;
    }

    updateDataFromServer(serverData: any, key: any): void {
        this.meta = serverData.meta;
        const events = this.isWorkersCalendar ? serverData[key].filter((
            item: InstanceAppointmentsWithSlotsType) => item.origin && item.origin === `from_appointment`
        ) : serverData[key];
        const reservedSlots = events.filter((item: any) => item.status !== `cancelled`).map((item: any) => {
            if (this.isPlanAnAppointmentCalendar) {
                if (item.timeslots) {
                    return item.timeslots.map((timeslot: any) => ({
                        date: new Date(timeslot.dt_start),
                    }));
                }
                return {};
            }
            return { date: new Date(item.dt_start) };
        });
        this.$store.commit('CALENDAR_COMMIT_EVENTS', serverData[key]);
        const data = reservedSlots.flat().map((item: { date: Date }) => ({ ...item, id: +item.date }));
        this.$store.commit('CALENDAR_RESERVED_SLOTS', data);
    }

    async getMothDates(to: string, from: string) {
        if (this.isCompanyCalendar) {
            const { data } = await AppointmentWebApi.getCompanyAppointmentsDates({
                to, from,
                company_id: this.$route.params.id,
            });
            return data.dates;
        } else if (this.isWorkersCalendar) {
            const { data } = await AppointmentWebApi.getWorkerAppointmentsDates({
                to, from,
                user_id: this.$route.params.id,
            });
            return data.dates;
        } else if (this.isPlanAnAppointmentCalendar) {
            const { data } = await AppointmentWebApi.getAppointmentsMonthDates({
                to,
                from,
                company_id: this.companyDate.id,
                appointment_id: this.appointmentStoreData.id,
            });
            return data;
        }
        return [];
    }

    async getMonthData(date?: null | Date, onlyTimeSlots: boolean = false) {
        if (!this.calendarDate) return;
        if (!onlyTimeSlots) {
            this.tableOpacity = 0;
        }
        const localDate = date || (this.localDate as Date);
        const todayNight: string = dateToString(date || new Date(this.calendarDate.getTime()), true);
        const firstDayOfMonth: Date = getFirstDayOfMonth(localDate);
        const delta = this.isPlanAnAppointmentCalendar ? 0 : 7;
        const lastDayOfMonth: Date = getLastDayOfMonth(localDate, delta);
        const from: string = dateToString(firstDayOfMonth, true);
        const to: string = dateToString(lastDayOfMonth, false, true);
        const copyDate = (time: string) => new Date(time);
        const dt_week_start_date: Date = copyDate(todayNight);
        const dt_week_end_date: Date = getDateWithDelayInDays(copyDate(todayNight), 7);
        const dt_week_start: string = dateToString(dt_week_start_date, true);
        const dt_week_end: string = dateToString(dt_week_end_date, true);
        const month = getDateAsIsoString(dt_week_start_date, true);
        if (!this.appointmentReservedSlotsExists.includes(month)) {
            const monthData = await this.getMothDates(to, from);
            if (Array.isArray(monthData)) {
                this.$store.commit('CALENDAR_RESERVED_SLOTS_EXISTS', month);
                this.$store.commit('CALENDAR_RESERVED_SLOTS', monthData.map((day: string) => ({ date: new Date(day), id: +new Date(day) })));
            }
        }
        if (onlyTimeSlots) {
            return;
        }
        this.monthDataLoading = true;

        if (this.isCompanyCalendar || this.isWorkersCalendar) {
            if (this.isCompanyCalendar) {
                const { data } = await AppointmentWebApi.getAppointmentsData({
                    from: dt_week_start_date,
                    to: dt_week_end_date,
                    company_id: this.$route.params.id,
                });
                if (data) {
                    this.updateDataFromServer(data, `appointments`);
                }
            } else if (this.isWorkersCalendar) {
                const data = await EventsWebApi.getCalendarInstancesAppointmentsWithSlots({ to: dt_week_end, from: dt_week_start, user_id: this.$route.params.id, page: 1 });
                this.updateDataFromServer(data, `calendar_instances`);
            }
        } else if (this.isPlanAnAppointmentCalendar) {
            const payload: PayloadAvailabilitiesDataType = {
                appointment_id: this.appointmentStoreData.id,
                to: dt_week_end_date,
                from: dt_week_start_date,
                company_id: this.companyDate.id,
            };
            /* eslint-disable-next-line no-await-in-loop */
            const data: Array<AvailabilityType> = await CalendarApi.getAvailabilitiesData(payload);
            this.$store.commit('CALENDAR_COMMIT_EVENTS', data);
        }
        setTimeout(() => {
            this.monthDataLoading = false;
        }, 200);
        this.tableOpacity = 1;
    }

    changeCalendarMonth(data: { year: number, month: number, flag: number, vm: any, sibling: undefined }) {
        const date: Date = new Date(data.year, data.month + data.flag + 1, 0);
        if (this.isPlanAnAppointmentCalendar && date < new Date()) return;
        this.getMonthData(new Date(data.year, data.month + data.flag + 1, 0), true);
    }
};
