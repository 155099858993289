<template>
<div>
    <form class='b-add-branch__form'
          @keydown.stop.prevent.enter='onSubmit'>
        <div class='h-flex h-width-100p'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(CLIENT_NAME)'
                labelType='label-medium'
                :title='`${$t(`LABEL.CLIENT.NAME`)}`'>
                <FwInput
                    :value='client_name'
                    class='text-capitalize'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.CLIENT.NAME`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_NAME)'
                    @update:value='value => client_name = value'
                    @input='clearServerErrorsBase(CLIENT_NAME)'
                    @blur='addCurrentInputToValidateArray(CLIENT_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='`${$t("LABEL.APPT.CLIENT.COMPANY")}`'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`COMPANIES.FORM.INPUT.NAME.LABEL`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    @update:value='value => company_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-mh-20'></div>
        </div>
        <div class='h-flex h-width-100p h-mt-20'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(CONTRACT_NUMBER)'
                labelType='label-medium'
                :title='`${$t(`LABEL.CONTRACT.NUMBER`)}`'>
                <FwInput
                    :value='contract_number'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.CONTRACT.NUMBER`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CONTRACT_NUMBER)'
                    @update:value='value => contract_number = value'
                    @input='clearServerErrorsBase(CONTRACT_NUMBER)'
                    @blur='addCurrentInputToValidateArray(CONTRACT_NUMBER)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(OI_NUMBER)'
                labelType='label-medium'
                :title='`${$t(`LABEL.APPT.CLIENT.OI`)}`'>
                <FwInput
                    :value='oi_number'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.APPT.CLIENT.OI`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(OI_NUMBER)'
                    @update:value='value => oi_number = value'
                    @input='clearServerErrorsBase(OI_NUMBER)'
                    @blur='addCurrentInputToValidateArray(OI_NUMBER)'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-mh-20'></div>
        </div>
        <div class='h-flex h-width-100p h-mt-20'>
            <FwFormInput
                class='h-mh-20 h-flex-1-0'
                :fieldHasError='fieldHasError(LOCATION_VALUE)'
                labelType='label-medium'
                :title='`${$t(`LABEL.APPOINTMENT.LOCATION`)}`'>
                <FwInput
                    :value='location'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.APPOINTMENT.LOCATION`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(LOCATION_VALUE)'
                    @update:value='value => location = value'
                    @input='clearServerErrorsBase(LOCATION_VALUE)'
                    @blur='addCurrentInputToValidateArray(LOCATION_VALUE)'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import {
    COMPANY_NAME,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    ADVISOR_NAME,
    ADVISOR_PHONE,
    BRANCH_VALUE,
} from '@/helpers/events';
import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';

@Component({
    validators: {
        [COMPANY_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [CLIENT_SURNAME](value: string) {
            return this.requiredValidator({ value });
        },
        [CLIENT_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [CONTRACT_NUMBER](value: string) {
            return this.requiredValidator({ value });
        },
        [LOCATION_VALUE](value: string) {
            return this.requiredValidator({ value });
        },
        [ADVISOR_NAME](value: string) {
            return this.requiredValidator({ value });
        },
    },
})

export default class AppointmentForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;
    disabledForm: boolean = true;

    COMPANY_NAME: string = '';
    CLIENT_SURNAME: string = '';
    CLIENT_NAME: string = '';
    CONTRACT_NUMBER: string = '';
    OI_NUMBER: string = '';
    LOCATION_VALUE: string = '';
    ADVISOR_NAME: string = '';
    ADVISOR_PHONE: string = '';
    BRANCH_VALUE: string = '';
    company_name: string = '';
    fist_name: string = '';
    client_name: string = '';
    client_surname: string = '';
    contract_number: string = '';
    oi_number: string = '';
    location: string = '';
    advisor_name: string = '';
    advisor_phone: string = '';
    branches_name: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveAdmin: boolean = false;
    serverErrors: serverErrors = {
        company_name: null,
        client_name: null,
        client_surname: null,
        contract_number: null,
        oi_number: null,
        location: null,
        advisor_name: null,
        advisor_phone: null,
        branches_name: null,
    };
    updateFormByServerValues() {
        if (this.appointmentData) {
            if (this.appointmentData.client_company_title) {
                this.company_name = this.appointmentData.client_company_title;
            }
            if (this.appointmentData.location) {
                this.location = this.appointmentData.location;
            }
            if (this.appointmentData.client_details) {
                this.client_name = this.appointmentData.client_details;
            }
            if (this.appointmentData.contract_id) {
                this.contract_number = this.appointmentData.contract_id;
            }
            if (this.appointmentData.intervention_order) {
                this.oi_number = this.appointmentData.intervention_order;
            }
        }
    }

    created() {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
    .b-add-branch__form
        display: flex
        flex-wrap: wrap
        margin: 0 -15px

        &__row
            display: flex
            width: 100%
            margin-bottom: 0

            @include media('<=phone')
                flex-direction: column
                padding: 0 15px
                margin-bottom: 0 !important

                .b-add-branch__form__input
                    margin: 8px 15px

        &__input
            flex: 1 0
            margin: 0 15px
            input
                &:disabled
                    color: darkgray

        &__delete
            text-transform: uppercase
            margin-top: 20px
            font-size: 12px
            color: $cancel-red
            font-weight: bold
            cursor: pointer
            text-align: center

            &:hover
                opacity: 0.7
</style>
