import { lazyLoadView } from '@/helpers/url';

import {
    PLAN_AN_APPOINTMENT_CALENDAR,
} from '@/helpers/calendar';
import { CONSULT_PREFIX } from '@/routes/config';
import AddCompaniesRoutes from './add-company';
import AddWorkersRoutes from './add-technicians';
import WorkersRoutes from './workers';
import CompaniesRoutes from './companies';
import AppointmentsRoutes from './appointments';

export default [
    {
        path: `${CONSULT_PREFIX}/choose-manage`,
        name: `ChooseManagePage`,
        component: lazyLoadView(`consult/common/ChooseManagePage/ChooseManagePage`),
        meta: {
            showAppHeader: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}/plan-an-appointment`,
        name: PLAN_AN_APPOINTMENT_CALENDAR,
        component: lazyLoadView(`consult/common/CalendarPage/CalendarPage`),
    },
    ...WorkersRoutes,
    ...CompaniesRoutes,
    ...AddCompaniesRoutes,
    ...AddWorkersRoutes,
    ...AppointmentsRoutes,
];
