<template>
<div class='b-appointment-form'>
    <h2 class='b-appointment-left-bar__title'>
        {{ appointmentData.title }}
    </h2>
    <ErrorPopup
        v-if='showErrorPopup'
        :statusCode='errorStatus'
        :statusText='errorStatusText'
        :text='errorText'
        :buttonText='$t("FINISH.POPUP.SUCCESS.CLOSE")'
        @close='showErrorPopup = false'>
    </ErrorPopup>
    <EventForm
        :eventTime='eventTime'
        :dates='dates'
        :isRequestSending='isRequestSending'
        :defaultFormValues='appointmentData'
        :companyData='companyData'
        iconsWrapperClass='h-flex-dir-column h-flex'
        @onSubmit='sendDataToServer'>
    </EventForm>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { dateAsEventTime } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { EventForm } from '@/components/forms/event/EventForm';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CompanyData } from '@/types/Companies';
import { AppointmentDataType } from '@/types/Appointment';
import { ErrorPopup } from '@/components/popups/ErrorPopup';

@Component({
    components: {
        EventForm,
        ErrorPopup,
    },
})
export default class PlanAppointmentForm extends Mixins(DateMixin) {
    appointmentData: null | AppointmentDataType = null;
    companyData: null | CompanyData = null;
    isRequestSending: boolean = false;
    errorStatus: number | '' = '';
    errorStatusText: string = '';
    errorText: string = '';
    showErrorPopup: boolean = false;

    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }

    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }

    get eventDate(): any {
        return this.$store.state.CalendarPageStore.date;
    }

    get eventTime(): string {
        return dateAsEventTime(this.eventDate.date, this.user);
    }

    get dates(): Array<string> {
        const endTime = this.appointmentData ? new Date(new Date(this.eventDate.data.slot).getTime() + this.appointmentData.duration * 60000) : null;
        const dates = [
            this.getCurrentDateAsMinutesAMPM(new Date(this.eventDate.data.slot)),
        ];
        if (endTime) {
            dates.push(this.getCurrentDateAsMinutesAMPM(endTime));
        }
        return dates;
    }
    async sendDataToServer() {
        this.isRequestSending = true;
        if (this.companyData && this.appointmentData) {
            try {
                await AppointmentWebApi.bookAppointment(
                    {
                        dt_start: this.eventDate.data.slot,
                        company_id: this.companyData.id,
                        technician_id: this.eventDate.data.technician_id,
                        distance_to: this.eventDate.data.distance_to_in_meters,
                        distance_from: this.eventDate.data.distance_from_in_meters,
                        priority: this.eventDate.data.priority,
                    },
                    this.appointmentData.id
                );
                this.isRequestSending = false;
                this.$router.push({ path: '/plan/intervention-code' });
                this.sentNotif('APPOINTMENT.SUCCESS.POPUP.TXT', 'success');
            } catch (error) {
                const { response } = (error as AxiosError);
                this.showErrorPopup = true;
                if (response) {
                    this.errorText = response.data.errors && Array.isArray(response.data.errors) ? response.data.errors[0] : '';
                    this.errorStatus = response.status;
                    this.errorStatusText = response.statusText;
                }
                this.isRequestSending = false;
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }
    created() {
        this.companyData = this.companyDate;
        this.appointmentData = this.appointmentStoreData;
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar
    .mx-datepicker-popup
        z-index: 5!important

    .mx-datepicker--hide
        height: 250px!important

    .b-appointment-form-back_button
        top: 30px
        right: 30px
</style>
