import i18n from '@/locale';
import { ServerBaseDataOnError } from '@/types/Response';

const VALIDATOR_TEMPLATES = {
    required: i18n.tc(`VALIDATION.FIELD.REQUIRED`),
    email: i18n.tc(`VALIDATION.FIELD.EMAIL`),
    minLength: i18n.tc(`VALIDATION.FIELD.CHARACTERS.REQUIRED`),
    maxLength: i18n.tc(`VALIDATION.FIELD.CHARACTERS.MAX`),
};

function prepareServerFormErrors(data: ServerBaseDataOnError): serverErrors {
    if (!data.errors) {
        return {};
    }
    function getFieldErrorNameFromString(errorType: string): string | null {
        const subStringArray = errorType.split('.', 2);
        const index: number = subStringArray.indexOf('user');
        if (index !== -1) subStringArray.splice(index, 1);
        return subStringArray && subStringArray.length ? subStringArray[0] : null;
    }
    const errors: serverErrors = {};
    for (let i = 0; i < data.errors.length; i++) {
        const field = getFieldErrorNameFromString(data.errors[i].type);
        if (field) {
            errors[field] = data.errors[i].text;
        }
    }
    return errors;
}

export {
    VALIDATOR_TEMPLATES,
    prepareServerFormErrors,
};
