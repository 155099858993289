<template>
<div class='b-cancel-reason h-flex h-flex-center h-flex-dir-column h-mt-20'>
    <div class='h-flex h-mb-20 h-width-100p'>
        <span class='h-fw-500 h-mr-10'>
            {{ $t('CANCEL.TECHNICIAN.TRAVEL') }}
        </span>
        <FwSwitcher
            :isActive='isTechnicianTravel'
            :isRequestSending='isRequestSending'
            @toggleSwitch='isTechnicianTravel = !isTechnicianTravel'>
        </FwSwitcher>
    </div>
    <FwFormInput
        labelType='label-medium'
        :title='$t(`CANCEL.ORIGIN.LABEL`)'>
        <div style='background: #fff; width: 300px; border-radius: 10px;' class='h-p-0'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='valuePerson'
                    :propsPlaceholder='$t(`CANCEL.REASON.PERSON.PLACEHOLDER`)'
                    type='white'
                    label='title'
                    :options='optionsReasonOrigin'
                    @input='choosedValue'>
                    <template v-slot:option='{ props }'>
                        <slot :props='props'
                              name='option'>
                            <div>
                                {{ props.option.title }}
                            </div>
                        </slot>
                    </template>
                </FwSelect>
            </div>
        </div>
    </FwFormInput>
    <FwFormInput
        v-if='valuePerson'
        class='h-mt-20'
        labelType='label-medium'
        :title='$t(`CANCEL.REASON.LABEL`)'>
        <div style='background: #fff; width: 300px; border-radius: 10px;' class='h-p-0'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='valueReason'
                    :propsPlaceholder='$t(`CANCEL.REASON.PLACEHOLDER`)'
                    type='white'
                    label='title'
                    :options='optionsResult'>
                    <template v-slot:option='{ props }'>
                        <slot :props='props'
                              name='option'>
                            <div>
                                {{ props.option.title }}
                            </div>
                        </slot>
                    </template>
                </FwSelect>
            </div>
        </div>
    </FwFormInput>
    <div v-if='valuePerson && valueReason'
         class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30'
            borderRadiusType='tiny-border'
            :disabled='isRequestSending'
            @click='onSubmit'>
            {{ $t('CANCEL.BUTTON.APPOINTMENT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import i18n from '@/locale';

interface ReasonValueType {
    title: string,
    value: string,
}

@Component
export default class CancelReasonSelect extends Vue {
    @Prop({ type: Array, default: null }) readonly optionsReasonOrigin!: null | Array<string>;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    isTechnicianTravel: boolean = false;
    valuePerson: ReasonValueType | null = null;
    valueReason: ReasonValueType | null = null;
    optionsResult: Array<ReasonValueType> | null = null;
    customerData: Array<ReasonValueType> = [
        {
            title: i18n.tc('CANCEL.REASON.SUITABILITY'),
            value: 'personal_reason',
        },
        {
            title: i18n.tc('CANCEL.REASON.COMPLIANCE'),
            value: 'compliance_site',
        },
        {
            title: i18n.tc('CANCEL.REASON.INTERVENTION.NECESSARY'),
            value: 'intervention_no_longer_necessary',
        },
        {
            title: i18n.tc('CANCEL.REASON.RIN.CANCELLATION'),
            value: 'rin_cancellation',
        },
        {
            title: i18n.tc('CANCEL.REASON.RIN.TRANSFORMATION'),
            value: 'rin_transformation',
        },
    ];
    workerData: Array<ReasonValueType> = [
        {
            title: i18n.tc('CANCEL.REASON.EMERGENCY'),
            value: 'company_emergency',
        },
        {
            title: i18n.tc('CANCEL.REASON.WORKER.MISTAKE'),
            value: 'worker_planning_mistake',
        },
        {
            title: i18n.tc('CANCEL.REASON.WORKER.SUITABILITY'),
            value: 'worker_suitability',
        },
        {
            title: i18n.tc('CANCEL.REASON.DELAY'),
            value: 'delay',
        },
        {
            title: i18n.tc('CANCEL.REASON.STOCK'),
            value: 'stock',
        },
        {
            title: i18n.tc('CANCEL.REASON.GOOD.AGREEMENT'),
            value: 'good_for_agreement_dead',
        },
    ];
    IMAData: Array<ReasonValueType> = [
        {
            title: i18n.tc('CANCEL.REASON.REQUEST.MANAGER'),
            value: 'manager_request',
        },
        {
            title: i18n.tc('CANCEL.REASON.BAD.WEATHER'),
            value: 'bad_weather_or_special_event',
        },
        {
            title: i18n.tc('CANCEL.REASON.OTHER.URGENT'),
            value: 'more_urgent_intervention',
        },
        {
            title: i18n.tc('CANCEL.REASON.PLAN.ERROR'),
            value: 'planning_error',
        },
        {
            title: i18n.tc('CANCEL.REASON.STOCK.ISSUE.DELAY'),
            value: 'stock_issue_delay',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.TECH.INCOMPATIBILITY'),
            value: 'technical_incompatibility',
        },
        {
            title: i18n.tc('CANCEL.REASON.INTERVENTION.NECESSARY'),
            value: 'intervention_no_longer_necessary',
        },
    ];
    choosedValue() {
        this.valueReason = null;
        if (!this.valuePerson) return;
        /* eslint-disable vue/script-indent */
        switch (this.valuePerson!.value) {
            case `client`:
                this.optionsResult = this.customerData;
                break;
            case `worker`:
                this.optionsResult = this.workerData;
                break;
            case `ima_protect`:
                this.optionsResult = this.IMAData;
                break;
            default:
                this.optionsResult = null;
        }
        /* eslint-enable vue/script-indent */
    }
    @Emit('onSubmit')
    onSubmit() {
        return {
            origin: this.valuePerson!.value,
            reason: this.valueReason!.value,
            did_technician_travel: this.isTechnicianTravel,
        };
    }
}
</script>

<style lang="sass">
.b-cancel-reason
    .fw-select-white .multiselect__content-wrapper
        width: auto
        min-width: 100%
</style>
