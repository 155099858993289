export default {
    'WORKER.POPUP.IMA.TITLE': 'Créneaux réservés pour IMA Protect',
    'WORKER.TIME.SLOT.TITLE': 'Heures de travail',
    'WORKER.TIME.SLOT.MORNING': 'Matin',
    'WORKER.TIME.SLOT.DAY': 'Journée',
    'WORKER.TIME.SLOT.AFTERNOON': 'Après-midi',
    'WORKER.TIME.SLOT.LUNCH': 'Pause déjeuner',
    'WORKER.TIME.SLOT.FROM': 'de',
    'WORKER.TIME.SLOT.TO': 'à',
    'WORKER.TIME.CURRENT.TITLE': 'Actuelles',
    'WORKER.TIME.SCHEDULED.TITLE': 'Programmées',
    'WORKER.TIME.SCHEDULED.UPDATED.AT': 'Mises à jour le',
    'WORKER.TIME.POPUP.TITLE': 'Enregistrer les modifications',
    'WORKER.TIME.POPUP.TXT': 'Souhaitez-vous vraiment quitter cette page sans enregistrer ?',
    'WORKER.TIME.POPUP.BUTTON.CANCEL': 'Ne pas enregistrer',
    'WORKER.TIME.POPUP.BUTTON.SAVE': 'Enregistrer',
    'WORKER.POPUP.ADMIN.TEXT': 'Cette ressource est l’admin de sa société',
    'WORKER.POPUP.COMPANY.ADMIN': 'Cette société possède déjà un admin',
    'WORKER.POPUP.CONFIRM.BUTTON': 'Je confirme ces heures de travail',
    'WORKER.POPUP.NO.PERMISSION.TEXT': 'Vous ne pouvez plus modifier les heures de travail après confirmation',
    'WORKER.POPUP.INVITATION.OOPS': 'Oups',
    'WORKER.POPUP.INVITATION.CHECK': 'Vous devez compléter les étapes suivantes avant d’envoyer une invitation :',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE': 'Confirmer les heures de travail',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT': '(en fermant cette fenêtre de dialogue et en cliquant sur "Heures de travail");',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE': 'Confirmer l\'adresse de l\'entreprise de la ressource',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT': '(en cliquant sur ce lien).',
    'WORKER.POPUP.INVITATION.CHECK.BUTTON': 'Vérifier et confirmer les heures de travail, adresse',
    'WORKER.POPUP.INVITATION.SEND': 'ENVOYER L’INVITATION',
    'WORKER.POPUP.INVITATION.SENT': 'Invitation envoyée',
    'WORKER.POPUP.INVITATION.ACCEPTED': 'Invitation acceptée',
    'WORKER.POPUP.INVITATION.RESEND': 'RENVOYER',
    'WORKER.POPUP.INVITATION.CONFIRMED': 'Compte confirmé',
    'WORKER.POPUP.INVITATION.OUTLOOK.AUTHORIZED': 'Compte Outlook lié',
    'WORKER.POPUP.INVITATION.GOOGLE.AUTHORIZED': 'Compte Google lié',
    'WORKER.POPUP.INVITATION.OUTLOOK.GOOGLE.AUTHORIZED': 'Comptes Google et Outlook liés',
    'WORKER.POPUP.INVITATION.SYNCHRONIZED': 'Calendrier synchronisé',
    'WORKER.POPUP.INVITATION.SENT.MESSAGE': 'Invitation envoyée avec succès !',
    'WORKER.NO.WORKER': 'Pas encore de ressource',
    'WORKER.UNCONFIRMED': 'Utilisateurs non confirmés',
    'WORKER.CONFIRMED': 'Utilisateurs confirmés',
    'WORKER.EMAIL.TAKEN': 'L\'email est déjà utilisé',
    'WORKER.ADD.APPOINTMENT': 'Forcer un RDV',

    'WORKER.TAKES.APPOINTMENTS': 'Reçoit des RDV',
    'WORKER.TAKES.NOT.APPOINTMENTS': 'Ne reçoit pas de RDV',
};
