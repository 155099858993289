<template>
<div ref='table'
     class='b-plan-table__wrapper h-pos-rel'>
    <table v-if='isMounted'
           class='b-plan-table'
           :class='{
               "b-plan-table--hide": monthDataLoading,
               "b-plan-table--appointment": isPlanAnAppointmentCalendar
           }'
           cellspacing='0'
           cellpadding='0'>
        <colgroup>
            <col>
            <col v-for='i in 7'
                 :key='i'
                 class='b-plan-table__row_color'>
        </colgroup>
        <thead>
            <tr>
                <td></td>
                <td v-for='date in dates'>
                    <div class='b-plan-table_th'
                         :class='{ "b-plan-table_th--active": compareDates(date) }'>
                        {{ $t(getWeekDayMame(date)).substring(0, 3).toUpperCase() }}. {{ getDay(date) }}
                    </div>
                </td>
            </tr>
        </thead>
        <tbody class='b-plan-table__time--full-day'>
            <tr>
                <td class='b-plan-table__time b-plan-table__time--center b-td-no-border'>
                    <span class='h-pr-5 h-font-12'>
                        {{ $t('CALENDAR.FULL.DAY') }}
                    </span>
                </td>
                <td v-for='date in checkDates(dates)'
                    :key='date.toString()'>
                    <div class='b-plan-table__time__full_day'>
                        <AddAnAppointment
                            v-if='showAddAppointmentZone({ localTime: $t("CALENDAR.FULL.DAY"), timestamp: +date })'
                            :fristHalfTime='$t("CALENDAR.FULL.DAY")'
                            @click='openAddAppointmentPopup'>
                        </AddAnAppointment>
                        <template v-if='isWorkersCalendar || isCompanyCalendar'>
                            <CompanyTimeCard
                                v-for='(workerCard, index) in getEventsByDate(date, null, true)'
                                :key='index'
                                class='b-plan-table__time-card'
                                :data='workerCard'
                                @click='getChooseData(date, workerCard)'>
                            </CompanyTimeCard>
                        </template>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody ref='tableBody'>
            <tr v-for='(data, timeIndex) in checkCalendarViewTime'
                :key='timeIndex'>
                <td :key='`${forceUpdateKey}--time-line`'>
                    <div class='b-plan-table__time h-flex-justify-start h-p-0 h-mr-5 h-font-12 h-fw-500'
                         :class='{"b-plan-table__time--active": isTimeActive(data.time) }'>
                        {{ $t(data.time) }}
                    </div>
                </td>
                <td v-for='(date, indexDates) in dates'
                    :key='indexDates'
                    class='h-p-5 h-pos-rel'>
                    <div class='h-flex b-plan-table__time__inner'
                         :style='isPlanAnAppointmentCalendar ? getTdStyle(date) : null'>
                        <div v-if='showLine(data, date)'
                             :style='showLineStyles'
                             class='b-plan-table__timeline__wrapper'>
                            <div class='b-plan-table__timeline'></div>
                        </div>
                        <div class='b-plan-table__time-card-wrapper'
                             :style='getTdInnerStyle(`${+date}`)'
                             @mouseover='showTimeAppointmentAdd(data.time, +date)'
                             @mouseleave='hideTimeAppointmentAdd'>
                            <AddAnAppointment
                                v-if='isWorkersCalendar && showAddAppointmentZone({ localTime: data.time, timestamp: +date })'
                                :fristHalfTime='$t(data.time)'
                                :choosedDay='date'
                                :secondHalfTime='secondHalfTime($t(data.time))'
                                @openAddAppointmentPopup='openAddAppointmentPopup'>
                            </AddAnAppointment>
                            <div v-for='(eventData, index) in getEventsByDate(date, data.time, false, null, `${+date}`)'
                                 class='b-plan-table__time-card__outer'>
                                <FwIcon
                                    v-if='isWorkersCalendar'
                                    :style='{ transform: getTransform(eventData, data.time) }'
                                    class='b-plan-table__time-card__icon'
                                    :class='{ "b-plan-table__time-card__icon--hide": hideEvents.includes(eventData.id) }'
                                    icon='eye-cross'
                                    size='16'
                                    :color='hideEvents.includes(eventData.id) ? "#203F6B" : "#BEC7DD"'
                                    @click.native='toggleEvent(eventData.id)'>
                                </FwIcon>
                                <component
                                    :is='componentName'
                                    :key='index'
                                    class='b-plan-table__time-card'
                                    :style='getStyles(eventData, data.time)'
                                    :class='{ "b-plan-table__time-card--appointment": isPlanAnAppointmentCalendar }'
                                    :data='eventData'
                                    :wrapperHeight='wrapperHeight'
                                    @mouseover.native='setScale'
                                    @mouseleave.native='removeScale'
                                    @click.native='getChooseDataCalendar(date, eventData)'>
                                </component>
                            </div>
                            <template v-if='isWorkersCalendar'>
                                <div v-for='(eventData, index) in reservedSlotsByDate(date, data.time, `${+date}`)'
                                     class='b-plan-table__time-card__outer'>
                                    <ReservedSlotsCard
                                        :key='index'
                                        class='b-plan-table__reserved-slot__card'
                                        :style='getStyles(eventData, data.time)'
                                        :data='eventData'
                                        :wrapperHeight='wrapperHeight'>
                                    </ReservedSlotsCard>
                                </div>
                            </template>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <portal to='popupWrapper'>
        <AddAppointmentPopup
            v-if='isOpenAddAppointmentPopup'
            :appointmentStartDate='appointmentStartDate'
            @updateData='$emit("updateData", calendarDate)'
            @close='isOpenAddAppointmentPopup = false'>
        </AddAppointmentPopup>
    </portal>
    <AppointmentDayInfoPopup
        v-if='dayInfoPopupOpen'
        :timeSlotDate='timeSlotDate'
        :timeSlotData='timeSlotData'
        @getChooseData='getChooseData'
        @close='dayInfoPopupOpen = false'>
    </AppointmentDayInfoPopup>
    <AppointmentTimeSlotPriorityPopup
        v-if='isTimeSlotPriorityPopup'
        :timeSlotDate='timeSlotDate'
        :timeSlotData='timeSlotData'
        @getChooseData='getChooseData'
        @close='isTimeSlotPriorityPopup = false'>
    </AppointmentTimeSlotPriorityPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import filter from 'ramda/es/filter';
import uniqBy from 'ramda/es/uniqBy';
import maxBy from 'ramda/es/maxBy';
import sort from 'ramda/es/sort';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';
import { showTime, time, mainCalendarTime } from '@/mocks/tableData';
import { ReservedSlotType } from '@/types/Events/ReservedSlots';
import {
    EventDataType,
    EventsType,
    CompanyEventType,
    WorkersEventType,
    InstanceAppointmentsWithSlotsType,
} from '@/types/Events';
import {
    CALENDAR_TYPES,
    PLAN_AN_APPOINTMENT_CALENDAR,
} from '@/helpers/calendar';
import { CompanyTimeCard } from '@/components/simple/CompanyTimeCard';
import { UnavailabilityEventInstancesType, TimeSlotsCalendarInstancePreparedType, AvailabilityType } from '@/types/Events/Workers';
import { currentHoursCalculation, getDateAsIsoString, parseToUTCDate, equalByDay, equalByTodayTomorrowWeekend, dateToString } from '@/helpers/dates';
import { AppointmentTimeCard } from '@/components/simple/AppointmentTimeCard';
import { WorkerTimeCard } from '@/components/simple/WorkerTimeCard';
import { ReservedSlotsCard } from '@/components/simple/ReservedSlotsCard';
import { AddAnAppointment } from '@/components/simple/AddAnAppointment';
import { BookCalendarEventCard } from '@/components/simple/BookCalendarEventCard';
import { AddAppointmentPopup } from '@/components/popups/AddAppointmentPopup';
import { calculateEventHeightCoef } from '@/helpers/table';
import { AppointmentDataType } from '@/types/Appointment';
import { AppointmentDayInfoPopup } from '@/components/popups/AppointmentDayInfoPopup';
import { AppointmentTimeSlotPriorityPopup } from '@/components/popups/AppointmentTimeSlotPriorityPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

type AnyEventTypes = Array<EventsType> | Array<CompanyEventType> | Array<UnavailabilityEventInstancesType> | Array<TimeSlotsCalendarInstancePreparedType>

type instancePeriodType = {
    start: Date
    end: Date
}

@Component({
    components: {
        AppointmentTimeCard,
        WorkerTimeCard,
        CompanyTimeCard,
        AppointmentDayInfoPopup,
        AppointmentTimeSlotPriorityPopup,
        AddAnAppointment,
        AddAppointmentPopup,
        ReservedSlotsCard,
        BookCalendarEventCard,
    },
    refs: [`tableBody`, 'table'],
})
export default class PlanTable extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Array, required: true }) readonly dates!: Array<Date>;
    @Prop({ type: Array, required: true }) readonly date!: Date;
    @Prop({ type: Array, default: () => ([]) }) readonly reservedSlots!: Array<ReservedSlotType>;
    @Prop({ type: Date, default: null }) readonly calendarDate!: Date;
    @Prop({ type: Boolean, required: true }) readonly monthDataLoading!: boolean;
    @Prop({ type: Array, required: true })
    readonly events!: Array<EventsType> | Array<CompanyEventType> | Array<UnavailabilityEventInstancesType> | Array<InstanceAppointmentsWithSlotsType> | Array<AvailabilityType>;
    @Prop({ type: String, default: PLAN_AN_APPOINTMENT_CALENDAR, validator: (type: string) => CALENDAR_TYPES.includes(type) })
    readonly type!: string;

    timeData: Array<EventDataType> = time;
    timeStart: string | null = null;
    currentDate: Date = this.getDayDateAtNight();
    appointmentChosenDay: string | number = '';
    forceUpdateKey = 1;
    isMounted: boolean = false;
    mouseOnEvent: boolean = false;
    isOpenAddAppointmentPopup: boolean = false;
    availableTimes = this.isPlanAnAppointmentCalendar ? null : mainCalendarTime.map(localTime => localTime.time);
    showTimeAddAppointment: string | null = null;
    timestampAddAppointment: number | null = null;
    hideEvents: Array<string> = [];
    dayInfoPopupOpen: boolean = false;
    isTimeSlotPriorityPopup: boolean = false;
    timeSlotDate: Date | null = null;
    timeSlotData: Array<EventsType> | null = null;
    viewCoeff: number = 1;
    cellSize: number = 90;
    tableWidthMapping: { [key: string]: number } = {};

    $refs!: {
        tableBody: HTMLElement
        table: HTMLElement
    };

    get wrapperHeight(): null | number {
        return this.$refs.tableBody ? this.$refs.tableBody.offsetHeight : null;
    }

    get calendarViewData(): any {
        return this.$store.state.CalendarPageStore.calendar_settings_data ?
            this.$store.state.CalendarPageStore.calendar_settings_data :
            null;
    }
    get appointmentStartDate(): Date {
        const startDay = new Date(this.appointmentChosenDay);
        const timeData = this.getHoursAndMinutesFromAMPM((this.timeStart as string));
        return new Date(startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get currentHour() {
        return this.getCurrentDateAsHoursAMPM(new Date(), false);
    }

    get currentHourPlusOne() {
        return currentHoursCalculation(1, this.$i18n.locale);
    }

    get showLineStyles() {
        return {
            width: `100%`,
            top: `${new Date().getMinutes() / 60 * 100}%`,
        };
    }

    get componentName(): string {
        if (this.isPlanAnAppointmentCalendar) {
            return `BookCalendarEventCard`;
        } else if (this.isCompanyCalendar) {
            return `CompanyTimeCard`;
        } else if (this.isWorkersCalendar) {
            return `WorkerTimeCard`;
        }
        return 'div';
    }

    showAddAppointmentZone({ localTime, timestamp }: { localTime: string, timestamp: number }) {
        const hoursData = this.getHoursAndMinutesFromAMPM(this.$i18n.tc(localTime));
        const calendarDate = new Date(timestamp);
        calendarDate.setHours(hoursData.hours, hoursData.minutes, 0, 0);
        return (
            !this.mouseOnEvent &&
            this.showTimeAddAppointment === localTime &&
            this.timestampAddAppointment === timestamp &&
            +new Date() < +calendarDate
        );
    }

    openAddAppointmentPopup(date: string, chosenTime: string) {
        this.timeStart = chosenTime;
        this.appointmentChosenDay = date;
        this.isOpenAddAppointmentPopup = true;
    }

    showTimeAppointmentAdd(timeAddAppointment: string, timestamp: number) {
        this.showTimeAddAppointment = timeAddAppointment;
        this.timestampAddAppointment = timestamp;
    }

    hideTimeAppointmentAdd() {
        this.showTimeAddAppointment = null;
        this.timestampAddAppointment = null;
    }

    setScale($event: any) {
        this.mouseOnEvent = true;
        const element = $event.currentTarget;
        if (element.dataset.hover !== 'hover') {
            element.dataset.hover = `hover`;
            element.style.transform += `scale(1.05)`;
        }
    }

    removeScale($event: any) {
        this.mouseOnEvent = false;
        const element = $event.currentTarget;
        element.style.transform = element.style.transform.replace('scale(1.05)', '');
        delete element.dataset.hover;
    }

    secondHalfTime(localTime: string) {
        const hoursData = this.getHoursAndMinutesFromAMPM(localTime);
        const date = new Date();
        date.setHours(hoursData.hours, 30, 0, 0);
        return this.formatAMPM(date, false);
    }

    checkDates(dates: Array<Date>) {
        let result = [];
        if (this.isPlanAnAppointmentCalendar && this.calendarViewData) {
            for (let i = 0; i < dates.length; i++) {
                if (!this.calendarViewData.saturday && !this.calendarViewData.sunday) {
                    if (dates[i].getDay() !== 6 && dates[i].getDay() !== 0) {
                        result.push(dates[i]);
                    }
                } else if (!this.calendarViewData.saturday) {
                    if (dates[i].getDay() !== 6) {
                        result.push(dates[i]);
                    }
                } else if (!this.calendarViewData.sunday) {
                    if (dates[i].getDay() !== 0) {
                        result.push(dates[i]);
                    }
                } else {
                    result.push(dates[i]);
                }
            }
        } else {
            result = dates;
        }
        return result;
    }

    isTimeActive(currentTime: string) {
        return this.currentHour === this.$i18n.t(currentTime) || this.currentHourPlusOne === this.$i18n.t(currentTime);
    }

    getTransform(data: UnavailabilityEventInstancesType, cellTime: string): string {
        const hoursDiff = parseToUTCDate(data.dt_start_view || data.dt_start).getHours() - this.getHoursAndMinutesFromAMPM(this.$i18n.t(cellTime)).hours;
        const hoursCoff = hoursDiff >= 0 ? hoursDiff : 1;
        const cellHeight = this.isPlanAnAppointmentCalendar ? 56 : 42;
        return `translateY(${new Date(data.dt_start_view || data.dt_start).getMinutes() / 60 * cellHeight + cellHeight * hoursCoff}px)`;
    }

    getStyles(data: UnavailabilityEventInstancesType | AvailabilityType, cellTime: string) {
        if (this.isPlanAnAppointmentCalendar) {
            return this.getCardStyle((data as AvailabilityType));
        // eslint-disable-next-line no-else-return
        } else {
            const startFormatAMPM = this.formatAMPM(new Date((data as UnavailabilityEventInstancesType).dt_start_view || (data as UnavailabilityEventInstancesType).dt_start));
            const endFormatAMPM = this.formatAMPM(new Date((data as UnavailabilityEventInstancesType).dt_end_view || (data as UnavailabilityEventInstancesType).dt_end));
            return {
                height: this.calcHeight([startFormatAMPM, endFormatAMPM], this.availableTimes),
                transform: this.getTransform((data as UnavailabilityEventInstancesType), cellTime),
                visibility: this.hideEvents.includes((data as UnavailabilityEventInstancesType).id) ? 'hidden' : 'visible',
            };
        }
    }

    filterEventsByDate(date: Date) {
        return (this.events as Array<AvailabilityType>).filter(item => new Date(item.slot).getDate() === date.getDate());
    }

    getTdInnerStyle(key: string) {
        return {
            minWidth: `${(this.tableWidthMapping[key] || 1) * 95}px`,
        };
    }

    getTdStyle(date: Date) {
        const eventsByDate = this.filterEventsByDate(date);
        let maxEventInLineCount: number = 0;
        for (let i = 0; i < eventsByDate.length; i++) {
            const event = eventsByDate[i];
            // @ts-ignore-next-line
            const count = this.getEventOrder(event);
            if (maxEventInLineCount < count) {
                maxEventInLineCount = count;
            }
            if (maxEventInLineCount === 4) break;
        }
        const width: number = maxEventInLineCount ? this.cellSize * maxEventInLineCount : 160;
        return {
            width: `${width}px`,
        };
    }

    get checkCalendarViewTime(): Array<EventDataType> {
        let result: Array<EventDataType> = [];
        if (this.isPlanAnAppointmentCalendar && this.calendarViewData) {
            for (let i = 0; i < this.timeData.length; i++) {
                if (parseInt(this.timeData[i].calendarViewTime!, 10) >= parseInt(this.calendarViewData.monday_time_start, 10) &&
                    parseInt(this.timeData[i].calendarViewTime!, 10) <= parseInt(this.calendarViewData.monday_time_end, 10)) {
                    this.timeData[i].show = true;
                    result.push(this.timeData[i]);
                }
            }
            const firstAvailableIndex = this.timeData.findIndex(item => item.time === result[0].time);
            const lastAvailableIndex = this.timeData.findIndex(item => item.time === result[result.length - 1].time);
            this.availableTimes = this.timeData.slice(firstAvailableIndex, lastAvailableIndex + 1).map(localTime => localTime.time);
        } else {
            if (this.events.length === 0) {
                return showTime;
            }
            const dates: Array<string> = this.dates.map(item => this.getPrettyStringDate(item));
            // @ts-ignore-next-line
            const filteredEventOnWeek = this.events.filter(item => dates.includes(this.getPrettyStringDate(new Date(item.dt_start_view || item.dt_start))));
            if (!filteredEventOnWeek.length) {
                return showTime;
            }
            // @ts-ignore-next-line
            const eventWithMaxHours = filteredEventOnWeek.reduce((prevEvent: AppointmentDataType, currentEvent: AppointmentDataType) => {
                const prevDate = parseToUTCDate(prevEvent.dt_end);
                const currentDate = parseToUTCDate(currentEvent.dt_end);
                const isPrevMore = (prevDate.getHours() > currentDate.getHours()) ||
                    (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() > currentDate.getMinutes());
                return isPrevMore ? prevEvent : currentEvent;
            });
            // @ts-ignore-next-line
            const eventWithMinHours = filteredEventOnWeek.reduce((prevEvent: AppointmentDataType, currentEvent: AppointmentDataType) => {
                const prevDate = parseToUTCDate(prevEvent.dt_end);
                const currentDate = parseToUTCDate(currentEvent.dt_end);
                const isPrevSmaller = (prevDate.getHours() < currentDate.getHours()) ||
                    (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() < currentDate.getMinutes());
                return isPrevSmaller ? prevEvent : currentEvent;
            });
            const minHour = parseToUTCDate(eventWithMinHours.dt_start).getHours();
            const minMinutes = parseToUTCDate(eventWithMinHours.dt_start).getMinutes();
            const maxHour = parseToUTCDate(eventWithMaxHours.dt_end).getHours();
            const maxMinutes = parseToUTCDate(eventWithMaxHours.dt_end).getMinutes();
            let coff = maxMinutes ? 60 / maxMinutes : 0;
            const startIndexOfEvent = time.findIndex(item => item.hours === minHour);
            const endIndexOfEvent = time.findIndex(item => item.hours === maxHour && item.minutes === maxMinutes);
            const startIndexBase = time.findIndex(item => item.hours === showTime[0].hours && item.minutes === showTime[0].minutes);
            const endIndexBase = time.findIndex(item => item.hours === showTime[showTime.length - 1].hours && item.minutes === showTime[showTime.length - 1].minutes);
            const startIndexToSlice = startIndexOfEvent < startIndexBase ? startIndexOfEvent : startIndexBase;
            const endIndexToSlice = endIndexOfEvent > endIndexBase ? endIndexOfEvent : endIndexBase;
            const available = this.timeData.slice(startIndexToSlice, endIndexToSlice + coff + 1);
            this.availableTimes = available.map(localTime => localTime.time);
            const isEventInRange = showTime.find(
                item => item.hours === minHour && item.minutes === minMinutes) && showTime.find(item => item.hours === maxHour && item.minutes === maxMinutes
            );
            if (isEventInRange || (available.length && this.$i18n.t(available[available.length - 1].time) === this.formatAMPM(new Date(eventWithMaxHours.dt_end)))) {
                coff = 3;
            }
            const coefficient = coff || 0;
            this.viewCoeff = (this.availableTimes.length - coefficient) / this.availableTimes.length;
            result = available.filter(localTime => localTime.main);
        }
        return result.length ? result : showTime;
    }

    get eventsForView(): AnyEventTypes {
        let timeslotsByWorkingDaySettings = [];
        if (this.isPlanAnAppointmentCalendar) {
            // @ts-ignore-next-line
            const data = this.events.flat();
            const calendarViewTimeAsStrings = this.checkCalendarViewTime.map(item => this.$t(item.time));
            const startWorkingDateHour = this.getHoursAndMinutesFromAMPM(calendarViewTimeAsStrings[0]).hours;
            // TODO Slavon
            // @ts-ignore-next-line
            timeslotsByWorkingDaySettings = data.filter((timeslot: AvailabilityType) => {
                const localDate = new Date(timeslot.slot);
                const startHour = localDate.getUTCHours();
                return startHour >= startWorkingDateHour;
            });
        } else if (this.isWorkersCalendar) {
            // @ts-ignore-next-line
            timeslotsByWorkingDaySettings = this.events.filter(item => item.origin && item.origin === 'from_appointment');
        } else {
            timeslotsByWorkingDaySettings = this.events;
        }
        return timeslotsByWorkingDaySettings;
    }

    calcHeight(dates: Array<string>, availableTimes: Array<any> | null): string {
        if (this.wrapperHeight) {
            return `${this.wrapperHeight * this.viewCoeff * calculateEventHeightCoef(dates, this.$i18n, availableTimes)}px`;
        }
        return `100%`;
    }

    tryGetAppointmentDataFromCache(appointment_id: string): AppointmentDataType | null {
        return this.$store.state.CalendarPageStore.appointmentCacheData.find((appointment: AppointmentDataType) => appointment.id === appointment_id);
    }

    /* eslint-disable-next-line no-shadow */
    getChooseData(date: Date, data: Array<EventsType> | Array<CompanyEventType> | Array<WorkersEventType>) {
        this.$store.commit('PLAN_DATE', { date, data });
        this.dayInfoPopupOpen = false;
        this.isTimeSlotPriorityPopup = false;
        this.$emit('openForm', { date, data });
    }

    async getChooseDataCalendar(date: Date, data: InstanceAppointmentsWithSlotsType) {
        let appointment: null | AppointmentDataType = null;
        if ((this.isWorkersCalendar && data.origin === `from_appointment`) || this.isCompanyCalendar) {
            // const cachedEvent = this.tryGetAppointmentDataFromCache(data.appointment_id || data.id);
            // if (cachedEvent) {
            //     appointment = cachedEvent;
            // } else {
            appointment = await AppointmentWebApi.getAppointmentData(data.appointment_id || data.id);
            this.$store.commit('CACHE_APPOINTMENT', appointment);
            // }
        }
        const PLANE_DATE = { date, data: appointment || data, localEventId: data.id };
        this.$store.commit('PLAN_DATE', PLANE_DATE);
        if (equalByTodayTomorrowWeekend(date) && this.isPlanAnAppointmentCalendar) {
            this.dayInfoPopupOpen = true;
            this.timeSlotDate = date;
            // @ts-ignore-next-line
            this.timeSlotData = data;
        } else if (this.isPlanAnAppointmentCalendar && (data.priority === 4 || data.priority === 5)) {
            this.isTimeSlotPriorityPopup = true;
            this.timeSlotDate = date;
            // @ts-ignore-next-line
            this.timeSlotData = data;
        } else {
            this.$emit('openForm', PLANE_DATE);
        }
    }

    compareDates(date: Date) {
        return date.getTime() === this.currentDate.getTime();
    }

    toggleEvent(id: string) {
        if (this.hideEvents.includes(id)) {
            this.hideEvents = this.hideEvents.filter(e => e !== id);
        } else {
            this.hideEvents.push(id);
        }
    }

    calculateIntersection(instances: Array<UnavailabilityEventInstancesType>): Array<Array<UnavailabilityEventInstancesType>> {
        const getIntersectionData = (
            { events, periodInstance }: { events: Array<UnavailabilityEventInstancesType>, periodInstance: UnavailabilityEventInstancesType }
        ): Array<UnavailabilityEventInstancesType> => {
            if (!events.length) return [];
            const intersectionData: Array<UnavailabilityEventInstancesType> = [];
            let instancePeriod: instancePeriodType = {
                start: parseToUTCDate(periodInstance.dt_start),
                end: parseToUTCDate(periodInstance.dt_end),
            };
            const uniqIdKey: string = this.isPlanAnAppointmentCalendar ? `pseudo_id` : `id`;
            const recCalc = () => {
                const predicate = (instance: UnavailabilityEventInstancesType) => {
                    const startDate = parseToUTCDate(instance.dt_start);
                    if (+startDate >= +instancePeriod.start && +startDate < +instancePeriod.end) {
                        instancePeriod = Object.assign(instancePeriod, { end: parseToUTCDate(instance.dt_end) });
                        return true;
                    }
                    return false;
                };
                const localEvents = events.filter(
                    // @ts-ignore-next-line
                    (instance: UnavailabilityEventInstancesType) => !intersectionData.some(local => local[uniqIdKey] === instance[uniqIdKey])
                );
                const element = localEvents.slice(0, 1);
                const filteredEvents = localEvents.filter(predicate);
                if (!filteredEvents.length) {
                    return element;
                }
                const preparedEvents: Array<UnavailabilityEventInstancesType> = filteredEvents;
                if (preparedEvents.length) {
                    // @ts-ignore-next-line
                    const maxDtEnd = maxBy((instance: UnavailabilityEventInstancesType) => +new Date(instance.dt_end), instancePeriod.end, preparedEvents);
                    instancePeriod = Object.assign(instancePeriod, { end: maxDtEnd });
                    intersectionData.push(...preparedEvents);
                    recCalc();
                }
                return intersectionData;
            };
            return recCalc();
        };

        const instancesIntersection: Array<Array<UnavailabilityEventInstancesType>> = [];
        for (let i = 0; i < instances.length; i++) {
            const instance: UnavailabilityEventInstancesType = instances[i];
            const flatIntersections = instancesIntersection.flat();
            if (flatIntersections.length) {
                if (this.isPlanAnAppointmentCalendar) {
                    if (flatIntersections.some((local: UnavailabilityEventInstancesType) => {
                        return local.dt_start === instance.dt_start && local.dt_end === instance.dt_end;
                    })) continue;
                } else if (flatIntersections.some((local: UnavailabilityEventInstancesType) => local.id === instance.id)) continue;
            }
            const uniqIdKey: string = this.isPlanAnAppointmentCalendar ? `pseudo_id` : `id`;
            // @ts-ignore-next-line
            const excludeInstanced = uniqBy(item => item[uniqIdKey], instancesIntersection.flat());
            const filteredInstances = instances.filter(
                // @ts-ignore-next-line
                (localInstance: UnavailabilityEventInstancesType) => !excludeInstanced.some(local => local[uniqIdKey] === localInstance[uniqIdKey])
            );
            const intersection = getIntersectionData({ events: filteredInstances, periodInstance: instance });
            instancesIntersection.push(intersection);
        }
        return this.optimizeInstances(instancesIntersection);
    }

    optimizeInstances(data: Array<Array<UnavailabilityEventInstancesType>>): Array<Array<UnavailabilityEventInstancesType>> {
        const result: Array<Array<UnavailabilityEventInstancesType>> = [];
        for (let i = 0; i < data.length; i++) {
            let index: number = 0;
            const group: Array<UnavailabilityEventInstancesType> = data[i];
            const firstElement = group[index];
            if (!firstElement) continue;
            let partOfEvents: Array<UnavailabilityEventInstancesType> = [];
            for (let k = 0; k < group.length; k++) {
                const currentEvent = group[index];
                const event = group[k];
                if (+parseToUTCDate(event.dt_start) < +parseToUTCDate(currentEvent.dt_end)) {
                    partOfEvents.push(event);
                } else {
                    result.push(partOfEvents);
                    partOfEvents = [];
                    partOfEvents.push(event);
                    index = k;
                }
            }
            result.push(partOfEvents);
        }
        return result;
    }

    get preparedReservedSlots() {
        // @ts-ignore-next-line
        const reservedSlots = this.events.filter(item => item.origin && item.origin === 'from_reserved_slot');
        if (this.isWorkersCalendar) {
            for (let i = 0; i < reservedSlots.length; i++) {
                const item = reservedSlots[i];
                if (!item.full_day || item.dt_start_view || item.dt_end_view) continue;
                const startCalendarTime = this.checkCalendarViewTime[0];
                const endCalendarTime = this.checkCalendarViewTime[this.checkCalendarViewTime.length - 1];
                const startTime: Date = parseToUTCDate(item.dt_start);
                const endTime: Date = parseToUTCDate(item.dt_end);
                startTime.setHours(startCalendarTime.hours, startCalendarTime.minutes);
                endTime.setHours(endCalendarTime.hours, endCalendarTime.minutes);
                item.dt_start_view = dateToString(startTime);
                item.dt_end_view = dateToString(endTime);
                item.ignore_full_day = true;
            }
        }
        return reservedSlots;
    }

    reservedSlotsByDate(date: Date, currentTime: string | null, rowKey: string) {
        return this.getEventsByDate(date, currentTime, false, this.preparedReservedSlots, rowKey);
    }

    getEventsByDate(
        // eslint-disable-next-line no-shadow
        date: Date, time: string | null, isFullDay: boolean = false, events?: AnyEventTypes, rowKey?: string
    ): Array<UnavailabilityEventInstancesType> | Array<AvailabilityType> {
        if (this.isPlanAnAppointmentCalendar) {
            const localTime = this.$i18n.t(time || ``);
            const eventsByDate = filter((instance: AvailabilityType) => equalByDay(new Date(instance.slot), date), (this.events as Array<AvailabilityType>));
            if (!eventsByDate.length) {
                return [];
            }
            const sortedEvents = sort((instanceA: AvailabilityType, instanceB : AvailabilityType) => {
                return +new Date(instanceA.slot) - +new Date(instanceB.slot);
            }, eventsByDate);
            const instances = sortedEvents.filter(localEvent => new Date(localEvent.slot).getUTCHours() === this.getHoursAndMinutesFromAMPM(localTime).hours);
            if (rowKey && (instances.length && this.tableWidthMapping[rowKey] < instances.length) || !this.tableWidthMapping[(rowKey as string)]) {
                this.$set(this.tableWidthMapping, (rowKey as string), instances.length);
            }
            return instances;
            // eslint-disable-next-line no-else-return
        } else {
            const eventsByDate = filter((instance: UnavailabilityEventInstancesType) => {
                return equalByDay(parseToUTCDate(instance.dt_start_view || instance.dt_start), date);
                // @ts-ignore-next-line
            }, events || this.eventsForView);
            if (!eventsByDate.length) {
                return [];
            }
            const isoDateString = getDateAsIsoString(date);
            const instances: Array<UnavailabilityEventInstancesType> = [];
            const sortedEvents = sort((instanceA: UnavailabilityEventInstancesType, instanceB : UnavailabilityEventInstancesType) => {
                return +parseToUTCDate(instanceA.dt_start_view || instanceA.dt_start) - +parseToUTCDate(instanceB.dt_start_view || instanceB.dt_start);
            }, eventsByDate);
            const instancesIntersection: Array<Array<UnavailabilityEventInstancesType>> = this.calculateIntersection(sortedEvents);
            for (let i = 0; i < instancesIntersection.length; i++) {
                const group = instancesIntersection[i];
                if (!group.length) continue;
                const groupStartInstance = group[0];
                const currentDate = parseToUTCDate(groupStartInstance.dt_start_view || groupStartInstance.dt_start);
                let isActualTime;
                if (isFullDay && !groupStartInstance.ignore_full_day) {
                    isActualTime = groupStartInstance.full_day && getDateAsIsoString(currentDate) === isoDateString;
                } else {
                    isActualTime = (
                        this.getCurrentDateAsHoursAMPM(currentDate) === this.$i18n.t(time || ``) &&
                        getDateAsIsoString(currentDate) === isoDateString
                    );
                }
                if (isActualTime) {
                    instances.push(...group);
                }
            }
            return instances;
        }
    }

    eventDate(instance: EventsType): string {
        return instance.date;
    }

    getEventOrder(event: AvailabilityType): number {
        return new Date(event.slot).getMinutes() / 15 + 1;
    }

    getCardStyle(event: AvailabilityType) {
        const order: number = this.getEventOrder(event);
        return {
            left: `${(order - 1) * this.cellSize + 10}px`,
        };
    }

    showLine(eventDataType: EventDataType, date: Date) {
        return (
            this.forceUpdateKey &&
            this.getCurrentDateAsHoursAMPM(new Date()) === this.$i18n.t(eventDataType.time) &&
            +this.currentDate === +date
        );
    }

    created() {
        setInterval(() => {
            this.forceUpdateKey += 1;
        }, 1000 * 60);
    }

    mounted() {
        this.isMounted = true;
    }
}
</script>

<style lang='sass'>
$left-td-width: 90px

.b-global-loading
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    background: #fff
    top: 0
    left: 0
    z-index: 10

.b-plan-table
    padding: 0 60px 0 0
    min-width: 100%
    transition: opacity .2s
    opacity: 1

    thead tr:first-child > td
        position: sticky
        top: 0
        z-index: 30

    .b-worker-time-card__inner
        overflow: hidden
        position: relative

    .fw-time-card__time
        padding: 1px 0
        font-size: 11px

    &--hide
        opacity: 0

    &__wrapper
        width: calc(100vw - 340px)

    &_th
        height: 30px
        border-radius: 5px
        background-color: #F8F9FA
        display: flex
        align-items: center
        justify-content: center
        position: relative
        margin-right: 8px
        font-size: 12px
        font-weight: bold
        letter-spacing: 0.3px
        line-height: 14px

        &--active
            background-color: rgba(39,219,189,0.1)
            color: $main-green
            b
                color: $main-green
                &:before
                    content: ''
                    color: $main-green
                    border-radius: 10px
                    position: absolute
                    z-index: 1
                    margin-top: -1px

        b
            display: flex
            align-items: center
            justify-content: center
            margin-left: 10px
            span
                position: relative
                z-index: 2

    td:first-of-type
        margin: 0 5px
        text-align: right
        width: $left-td-width
        min-width: $left-td-width
        padding-right: 0
        vertical-align: top

    td:not(:first-of-type)
        min-width: 160px

    thead
        td
            padding: 5px 0
            background: #fff

    tbody
        td
            border-right: 8px solid #fff

    &__row_color
        background: repeating-linear-gradient(-78deg, #D5DDE8, #D5DDE8 .5px, #fff .4px, #fff 5px)

    &__time
        color: #BEC7D3
        font-weight: normal

        &--active
            color: $dark-blue
            font-weight: bold

        &--full-day
            td
                height: 50px
                padding: 5px

            .b-plan-table__time__full_day
                height: 100%
                display: flex
                align-items: center
                justify-content: center

        &__full_day
            display: flex
            padding: 5px 0
            position: relative

    &__time-card-wrapper
        height: 42px
        width: 100%
        display: flex

    &__time-card
        cursor: pointer
        flex: 1 0 86px
        min-width: 86px
        z-index: 2
        will-change: tranform, box-shadow
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out

        &--appointment
            position: absolute !important
            margin: 0 10px
            &:first-of-type
                margin-left: 0

            &:last-of-type
                margin-right: 0

        &__outer
            width: 100%
            position: relative
            margin: 0 2px

        &__icon
            position: absolute
            right: 4px
            top: 6px
            z-index: 4
            cursor: pointer
            background-color: #ffffff
            padding: 4px 6px 0 6px
            display: flex
            align-items: center
            justify-content: center
            &--hide
                background-color: transparent !important

            &:hover
                path
                    fill: #203F6B !important

        &:not(:first-of-type)
            margin-left: 7px

    &--appointment
        .b-plan-table__time-card
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05), 0px -5px 8px -1px rgba(0, 0, 0, 0.05)
            max-width: 80px !important
            width: 80px !important
            flex: 0 0 80px !important
            min-width: 80px !important

        .b-plan-table__time-card__outer
            transition: transform 0.3s
            position: static !important
            flex: 1 0
            > *
                &:hover, &.active
                    position: relative
                    /*transform: scale(1.12)*/
                    z-index: 29 !important

        .b-plan-table__time-card-wrapper
            height: 44px
            &:empty
                height: 44px

    &__reserved-slot__card
        margin-left: -7px
        margin-right: -7px

@include media('<=tablet')
    .b-plan-table
        &__wrapper
            width: 100%

.b-plan-table__timeline
    width: 100%
    height: 1px
    background-color: $dark-blue
    position: relative

    &__wrapper
        width: 100%
        position: absolute
        z-index: 10
        left: 0
</style>
