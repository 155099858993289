import { lazyLoadView } from '@/helpers/url';

export default [
    {
        path: `/consult/appointments/:page([0-9]+)?`,
        name: `ConsultAppointmentsPage`,
        component: lazyLoadView(`consult/appointments/ConsultAppointmentsPage/ConsultAppointmentsPage`),
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
];
