import { lazyLoadView } from '@/helpers/url';
import { RouteType } from '@/types/router';

const planRoutes: Array<RouteType> = [
    {
        path: '/plan/intervention-code',
        name: `InterventionCodePage`,
        component: lazyLoadView(`global/InterventionCodePage/InterventionCodePage`),
        meta: {
            backButton: false,
            nextRouteName: `SocietySelectionPage`,
        },
    },
    {
        path: '/plan/society-selection',
        name: `SocietySelectionPage`,
        component: lazyLoadView(`plan/SocietySelectionPage/SocietySelectionPage`),
        meta: {
            backButton: true,
        },
    },
    {
        path: '/plan/prebooked/appointment',
        name: `PrebookedAppointmentPage`,
        component: lazyLoadView(`plan/PrebookedAppointmentPage/PrebookedAppointmentPage`),
        meta: {
            backButton: true,
        },
    },
];

for (let i = 0; i < planRoutes.length; i++) {
    if (!planRoutes[i].meta) {
        planRoutes[i].meta = {};
    }
    planRoutes[i].meta!.progressValue = (i + 1) / planRoutes.length * 100;
    planRoutes[i].meta!.showAppHeader = true;
}

export default planRoutes;
