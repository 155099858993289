import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { User } from '@/types/User';
import { WorkersWebApi } from '@/api/workers/WorkersApi';

@Module({
    namespaced: true,
})
class CommonStore extends VuexModule {
    isFixedBgOpen: boolean = false;
    hideBgStatus: boolean = true;
    userData: User | object = {};

    @Mutation
    setFixedBgStatus(value: boolean) {
        this.isFixedBgOpen = value;
    }
    @Mutation
    setHideBgStatus(value: boolean) {
        this.hideBgStatus = value;
    }

    @Mutation
    setOwnerAccountData(userData: User | null) {
        if (userData) {
            this.userData = userData;
        }
    }

    @Action({ commit: 'setOwnerAccountData' })
    actionOwnerAccountData(userData: User) {
        return userData;
    }

    @Action
    async fetchOwnerAccountData(id: number) {
        let data;
        try {
            data = await WorkersWebApi.getManagerData({ id });
        } catch (e) {
            console.log(e);
        }
        if (data) {
            this.context.commit('setOwnerAccountData', data);
        }
    }

    get isFixedBg(): boolean {
        return this.isFixedBgOpen;
    }
    get hideFixedBgStatus(): boolean {
        return this.hideBgStatus;
    }

    get ownerAccount(): User | object {
        return this.userData;
    }
}

export default CommonStore;
