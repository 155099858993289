<template>
<div class='fw-time-card'>
    <div class='fw-time-card__time-wrapper'
         @click='handleClick'>
        <div class='fw-time-card__label h-flex h-flex-center'
             :style='{
                 backgroundColor: data.priority_color,
                 borderColor: data.priority_color,
             }'>
            <div class='fw-border-index h-pos-abs'>
                {{ data.priority_number }}
            </div>
        </div>
        <div class='fw-time-card__time'>
            {{ startDate }}
        </div>
        <div v-if='data && data.distance_to_in_km_text' class='fw-time-card__time h-opacity-0_5'>
            {{ data.distance_to_in_km_text }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';

import {
    TimeCardData,
} from '@/types/Events';

@Component
export default class AppointmentTimeCard extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Object, required: true }) readonly data!: TimeCardData;

    get startDate() {
        return this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_start));
    }
    get endDate() {
        return this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_end));
    }

    handleClick(event: Event):void {
        this.$emit('click', event);
    }
}
</script>

<style lang='sass'>
.fw-time-card
    padding: 2px 5px 2px 20px !important
    .fw-border-index
        width: 100%
        text-align: center
        font-size: 10px
        font-weight: 500
        color: #FFF
        position: absolute
        z-index: 1
</style>
