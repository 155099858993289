export default {
    state: {
        workerData: null,
        workerUpdateData: null,
        companyUpdateData: null,
        managerTotal: null,
        workerScheduleData: {},
    },
    mutations: {
        WORKER_DATA(state:any, data:any) {
            state.workerData = data;
        },
        MANAGER_TOTAL(state:any, data:any) {
            state.managerTotal = data;
        },
        WORKER_SCHEDULE_DATA(state:any, data:any) {
            state.workerScheduleData = data;
        },
        WORKER_UPDATE_DATA(state:any, data:any) {
            state.workerUpdateData = data;
        },
        COMPANY_UPDATE_DATA(state:any, data:any) {
            state.companyUpdateData = data;
        },
    },
    actions: {
        storeWorkerData({ commit, state }: any, payload: object) {
            commit('WORKER_DATA', payload);
        },
        storeManagerCount({ commit, state }: any, payload: object) {
            commit('MANAGER_TOTAL', payload);
        },
        storeWorkerScheduleData({ commit, state }: any, payload: object) {
            commit('WORKER_SCHEDULE_DATA', payload);
        },
        storeWorkerUpdateData({ commit, state }: any, payload: object) {
            commit('WORKER_UPDATE_DATA', payload);
        },
        storeCompanyUpdateData({ commit, state }: any, payload: object) {
            commit('COMPANY_UPDATE_DATA', payload);
        },
    },
};

