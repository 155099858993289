var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"b-worker__info",class:_vm.wrapperClass},[_c('div',{staticClass:"b-worker__avatar",style:(_vm.workerStyle)},[_c('div',{staticClass:"b-worker__avatar__color",class:{ "b-worker__avatar__color--gradient": !_vm.worker.id }},[(!_vm.worker.avatar_url)?_c('FwIcon',{staticClass:"b-worker__avatar__icon",attrs:{"icon":"no-avatar","size":`${_vm.avatarSize / 2}`,"color":"#fff"}}):_vm._e()],1)]),(!_vm.hideInfo)?_c('div',{staticClass:"b-worker__inner"},[_c('div',{staticClass:"h-flex h-flex-dir-column"},[_c('div',{staticClass:"b-worker__position",class:[
                     _vm.nameClass,
                     { "b-worker__position--ghost": !_vm.worker.first_name }
                 ]},[(_vm.worker.first_name)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.first_name)+" ")]):_vm._e(),(_vm.worker.last_name)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.last_name)+" ")]):_vm._e(),(_vm.worker.full_name)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.full_name)+" ")]):(!_vm.worker.first_name && !_vm.worker.last_name)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('WORKERS.USER.NO_NAME'))+" ")]):_vm._e()]),(_vm.showAsideData)?_c('div',{staticClass:"h-flex"},[_c('div',{staticClass:"b-worker__info__tag",class:{
                         "b-worker__info__tag--success": _vm.worker.is_taking_appointments,
                         "b-worker__info__tag--negative": !_vm.worker.is_taking_appointments
                     }},[_vm._v(" "+_vm._s(_vm.worker.is_taking_appointments ? _vm.$t('WORKER.TAKES.APPOINTMENTS') : _vm.$t('WORKER.TAKES.NOT.APPOINTMENTS'))+" ")]),(_vm.worker.is_admin)?_c('div',{staticClass:"b-worker__info__tag b-worker__info__tag--grey"},[_vm._v(" Admin ")]):_vm._e()]):(_vm.worker.role_name)?_c('span',{staticClass:"b-worker__name"},[_vm._v(" "+_vm._s(_vm.worker.role_name)+" ")]):_c('div',{staticClass:"b-worker__name"},[(_vm.worker.company_title)?_c('span',[_vm._v(" "+_vm._s(_vm.worker.company_title)+" ")]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }