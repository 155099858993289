<template>
<div class='b-appointment-info'>
    <div>
        <img :src='requireImage("logo.svg")'>
    </div>
    <WorkerAvatar
        v-if='worker'
        class='h-mt-30'
        :worker='worker'
        size='small'
        align='right'>
    </WorkerAvatar>
    <ConsultCard
        v-for='information in informationData'
        :key='information.title'
        :information='information'>
    </ConsultCard>
</div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { informationDataType } from '@/types/Consult';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { WorkerType } from '@/types/Workers';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { separateColor, hexToRgbA } from '@/helpers/colors';

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
    },
})
export default class WorkersCalendarInfo extends Vue {
    informationData: Array<informationDataType> = [];
    worker: WorkerType | null = this.workerStoreData;

    get workerStoreData(): any {
        return this.$store.state.WorkerPageStore.workerData;
    }
    get userColor() {
        const colorArray:Array<string> = separateColor(this.worker!.color!);
        return `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
    }
    get isTechnicianRightsReadA() {
        return this.user().manager_profile &&
            this.user().manager_profile.role.technician_rights.read &&
            this.user().manager_profile.role.calendar_profile_rights.read;
    }
    get isTechnicianRightsReadB() {
        return this.user().manager_profile &&
            (this.user().manager_profile.role.technician_rights.read &&
                !this.user().manager_profile.role.calendar_profile_rights.read);
    }
    prepareData(workerData: any): Array<informationDataType> {
        const workerDataArray: Array<informationDataType> = [];
        if (workerData.company_title) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: workerData.company_title,
            });
        }
        if (workerData.id) {
            workerDataArray.push({
                title: 'ID',
                aside: workerData.id,
            });
        }
        if (workerData.email) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: workerData.email,
            });
        }
        if (workerData.phone && !(workerData.technician_profile && workerData.technician_profile.phone)) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.PHONE'),
                aside: workerData.phone,
            });
        }
        if (workerData.technician_profile && workerData.technician_profile.phone) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.PHONE'),
                aside: workerData.technician_profile.phone,
            });
        }
        if (workerData.company_name) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: workerData.company_name,
            });
        }
        return workerDataArray;
    }

    getWorker() {
        if (this.isTechnicianRightsReadA || this.isTechnicianRightsReadB) {
            WorkersWebApi.getWorkerData({
                id: this.$router.currentRoute.params.id,
            }).then(response => {
                if (response.status === 200) {
                    this.loading = false;
                    this.$store.dispatch('storeWorkerData', response.data.user);
                    this.worker = response.data.user;
                    this.checkAccessToCalendarPage(this.worker);
                    this.informationData = this.prepareData(response.data.user);
                    console.log('ok');
                }
            }).catch(({ response }) => {
                if (response.status === 401) {
                    console.log('some error');
                } else {
                    this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
                }
            });
        }
    }

    checkAccessToCalendarPage(worker: WorkerType | null) {
        if (worker && worker.technician_profile && !worker.technician_profile.is_taking_appointments) {
            this.$router.push({ path: `/consult/technicians/${worker.id}` });
        }
    }

    mounted() {
        if (this.workerStoreData) {
            this.worker = this.workerStoreData;
            if (this.isTechnicianRightsReadA || this.isTechnicianRightsReadB) {
                this.informationData = this.prepareData(this.workerStoreData);
            }
            this.checkAccessToCalendarPage(this.worker);
        } else {
            this.getWorker();
        }
    }
}
</script>
