export default {

    'CONSULT.APPOINTMENT.TITLE': 'Rendez-vous',
    'CONSULT.APPOINTMENT.SEARCH.TITLE': 'Rechercher un rendez-vous',
    'CONSULT.APPOINTMENT.SEARCH.CLIENT.NAME': 'Nom du client',
    'CONSULT.APPOINTMENT.SEARCH.CLIENTS.NAME': 'Nom du client',
    'CONSULT.APPOINTMENT.SEARCH.ADVISOR.NAME': 'Nom du conseiller',
    'CONSULT.APPOINTMENT.SEARCH.TECHS.NAME': 'Nom du technicien',
    'CONSULT.APPOINTMENT.SEARCH.TECH.NAME': 'Nom du technicien',
    'CONSULT.COMPANIES.NAME': 'Nom de la société',
    'CONSULT.COMPANY.NAME': 'Nom de la société',
    'CONSULT.APPOINTMENT.SEARCH.DATE': 'Date',
    'CONSULT.APPOINTMENT.SEARCH.Reference': 'Référence',
    'CONSULT.APPOINTMENT.SEARCH.POPUP.EDIT': 'Modifier un RDV',

    'CONSULT.APPOINTMENT.CLIENT.DETAILS': 'Informations société',
    'PAST.APPOINTMENT.DETAILS.TITLE': 'Informations rendez-vous',

    'CONSULT.BRANCHES.NAME': 'Agence',
    'CONSULT.BRANCHES.REFERENCE': 'Référence',

    'CONSULT.TYPE.NAME.PLACEHOLDER': 'Saisissez le nom',
    'CONSULT.TYPE.DATE.PLACEHOLDER': 'Sélectionnez une date',
    'CONSULT.TYPE.ADVISOR.PLACEHOLDER': 'Saisissez le nom',
    'CONSULT.TYPE.BRANCH.PLACEHOLDER': 'Saisissez une agence',
    'CONSULT.TYPE.REFERENCE.PLACEHOLDER': 'Référence',

    'CONSULT.SITE.NOT.COVERED': 'Site non couvert, veuillez contacter le service réseau.',

    'CUSTOMER.DETAILS.INPUT.PLACEHOLDER': 'ID prospect ou client',
    'CUSTOMER.DETAILS.BUTTON.ID': 'Identifiant',
    'CUSTOMER.DETAILS.BUTTON.TYPE.IN': 'Manuellement',

    'LABEL.SELECT.PLACEHOLDER': 'Sélectionnez une date',

    'SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL': 'Résultat | Résultats',

    'BUTTON.EDIT.APPT': 'Modifier le rendez-vous',
    'APPT.NOTES.PLACEHOLDER': 'Informations additionnelles sur ce rendez-vous',
    'APPT.CHECKBOX.TITLE': 'Ce technicien peut avoir des rendez-vous',
    'CANCELLED.ON.EXPIRATION.DATE': 'S\'il n\'est pas confirmé, ce rendez-vous sera automatiquement annulé le',
    'CANCELLED.ON.PREBOOKED.MISMATCH.TEXT': 'Différence de durée entre ce rendez-vous et le nouveau',
    'CONFIRM.THE.APPT': 'Confirmer le rendez-vous',
    'CANCEL.THE.APPT': 'Annuler le rendez-vous',

    'PREBOOKED.APPT.NOTIFICATION': 'Une réservation est liée à cette intervention',
};
