export default {
    'PLAN.KIND.APPOINTMENT': 'Quel type d’intervention ?',
    'PLAN.CALENDAR.APPOINTMENT.HEADER': 'Planifier un rendez-vous',
    'PLAN.CHOOSE.APPOINTMENT': 'Choisissez une intervention',
    'PLAN.HEADER': 'Code d\'intervention',
    'PLAN.IWS.CODE': 'code IWS',
    'PLAN.COHERIS.CODE': 'code Coheris',
    'PLAN.SOCIETY.NAME': 'Sélection d’une société',
    'PLAN.SOCIETY.LABEL': 'Nom de la société',
    'PLAN.SOCIETY.PLACEHOLDER': 'Rechercher une société',
    'PLAN.SOCIETY.NO.ELEMENT.FOUND': 'Aucun résultat. Essayez avec d\'autres mots-clés',
    'PLAN.APPOINTMENT.DELAY.TXT': 'Attention, cette intervention nécessite un délai minimum de planification de {delay_value} jours',
    'APPOINTMENT.SUCCESS.POPUP.TXT': 'Rendez-vous planifié avec succès',
    'POPUP.SUCCESS.HEADER': 'Ajout réussi!',
};
