<template>
<div class='h-pt-30 h-flex-center h-flex-dir-column'>
    <FwDatepicker
        v-model='localDate'
        name='datepicker'
        class='h-mv-20'
        :propsRange='false'
        :selectMode='false'
        :propsLang='`${$i18n.locale}`'
        pickerStyle='static'
        :hideIcon='true'
        :showWeeksNumbers='true'
        :reservedSlots='reservedSlotsData'
        :hideInput='true'
        reservedSlotsColor='green'
        :disabledDate='disablePrevDays'
        @change-calendar-month='changeCalendarMonthHandler'
        @selectDate='updateDatesHandler'>
    </FwDatepicker>
    <FwButton
        v-if='isPlanAnAppointmentCalendar'
        size='small'
        borderRadiusType='small-border'
        color='cancel'
        class='h-mt-20'
        @click='$router.push({path: `/plan/intervention-code`})'>
        {{ $t('BUTTON.CANCEL') }}
    </FwButton>
    <FwButton
        v-else
        size='small'
        borderRadiusType='small-border'
        color='blue'
        class='h-mt-20'
        @click='$router.back(`-1`)'>
        {{ $t('BUTTON.BACK') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Emit, Prop, Watch } from 'vue-property-decorator';
import { ReservedSlotsType } from '@/types/ReservedSlots';
import CalendarMixin from '@/mixins/calendar';

@Component
export default class CalendarDatePicker extends Mixins(CalendarMixin) {
    @Prop({ type: Date, required: true }) readonly dateCurrent!: null | Date;
    @Prop({ type: Array, default: () => [] }) readonly reservedSlotsData!: Array<ReservedSlotsType>;
    localDate: null | Date = null;

    disablePrevDays(date: Date) {
        let result = null;
        if (this.isPlanAnAppointmentCalendar) {
            result = +date.setDate(date.getDate() + 1) <= +new Date();
        }
        return result;
    }

    @Watch('dateCurrent')
    updateLocalDate() {
        this.localDate = this.dateCurrent;
    }

    mounted() {
        this.updateLocalDate();
    }

    @Emit('updateDates')
    updateDatesHandler(date: Date) {
        return date;
    }

    @Emit('changeCalendarMonth')
    changeCalendarMonthHandler(data: any) {}
}
</script>
