<template>
<div class='h-mt'>
    <form class='b-add-company__form'
          @keyup.enter='onSubmit'>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("name")'
                labelType='label-medium'
                :title='`${formTitlesMapping["name"]}*`'>
                <FwInput
                    :value='name'
                    type='text'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    :error='getFieldErrorText("name")'
                    inputStyle='white-bigger'
                    placeholder='ex: Cuberto'
                    @update:value='value => name = value'
                    @input='clearServerErrorsBase("name")'
                    @blur='addCurrentInputToValidateArray("name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='!isEdit'
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("address")'
                labelType='label-medium'
                :title='`${formTitlesMapping["address"]}*`'>
                <FwInput
                    :value='address'
                    type='text'
                    name='text'
                    :disabled='confirmCheckbox'
                    :error='getFieldErrorText("address")'
                    inputStyle='white-bigger'
                    placeholder='ex: 130, rue Saint-Maur 75011 Paris'
                    @update:value='value => address = value'
                    @input='clearServerErrorsBase("address")'
                    @blur='addCurrentInputToValidateArray("address")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                v-if='!isEdit'
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("zip_code")'
                labelType='label-medium'
                :title='`${formTitlesMapping["zip_code"]}*`'>
                <FwInput
                    :value='zip_code'
                    type='text'
                    name='text'
                    :disabled='confirmCheckbox'
                    :error='getFieldErrorText("zip_code")'
                    inputStyle='white-bigger'
                    placeholder='ex: 74000'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => zip_code = value'
                    @input='clearServerErrorsBase("zip_code")'
                    @blur='addCurrentInputToValidateArray("zip_code")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='!isEdit'
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("city")'
                labelType='label-medium'
                :title='`${formTitlesMapping["city"]}*`'>
                <FwInput
                    :value='city'
                    type='text'
                    :disabled='confirmCheckbox'
                    name='text'
                    inputStyle='white-bigger'
                    placeholder='ex: Annecy'
                    :error='getFieldErrorText("city")'
                    @update:value='value => city = value'
                    @input='clearServerErrorsBase("city")'
                    @blur='addCurrentInputToValidateArray("city")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("insee_code")'
                labelType='label-medium'
                :title='`${formTitlesMapping["insee_code"]}`'>
                <FwInput
                    :value='insee_code'
                    type='text'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    inputStyle='white-bigger'
                    :placeholder='13 + `\xa0` + $t(`COMPANIES.FORM.INPUT.PLACEHOLDER.NUMBERS`)'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => insee_code = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("siren")'
                labelType='label-medium'
                :title='formTitlesMapping["siren"]'>
                <FwInput
                    :value='siren'
                    type='text'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    inputStyle='white-bigger'
                    :placeholder='9 + `\xa0` + $t(`COMPANIES.FORM.INPUT.PLACEHOLDER.NUMBERS`)'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => siren = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("siret")'
                labelType='label-medium'
                :title='`${formTitlesMapping["siret"]}`'>
                <FwInput
                    :value='siret'
                    type='text'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    inputStyle='white-bigger'
                    :placeholder='14 + `\xa0` + $t(`COMPANIES.FORM.INPUT.PLACEHOLDER.NUMBERS`)'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => siret = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("phone")'
                labelType='label-medium'
                :title='formTitlesMapping["phone"]'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    inputStyle='white-bigger'
                    placeholder='ex: 06 20 45 30 79'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => phone = value'>
                    <template #left>
                        <div class='b-phone-with-code fw-select-white fw-select-base__time'>
                            <FwSelect
                                v-model='phoneCode'
                                :disabled='true'
                                :tryToUseSubstring='true'
                                noElementsFoundText='no code'
                                type='phone-with-code'
                                :canNotDelete='true'
                                :showRightIcon='false'
                                @substrPhone='substrPhone'>
                            </FwSelect>
                        </div>
                    </template>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError("contact_email")'
                labelType='label-medium'
                :title='`${formTitlesMapping["contact_email"]}`'>
                <FwInput
                    :value='contact_email'
                    type='text'
                    name='text'
                    :disabled='isCompanyCreatedAndNotEdit'
                    inputStyle='white-bigger'
                    placeholder='ex: adress@email.com'
                    @update:value='value => contact_email = value'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <div class='b-form-hint__wrapper'>
        <div class='b-form-hint'>
            <p class='h-mt-5'>
                * {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
            </p>
        </div>
    </div>
    <div v-if='$route.params.id && !companyData.is_address_confirmed' class='b-form-hint__wrapper'>
        <div class='label-medium h-fw-500'>
            <p class='h-mt-5'>
                {{ $t('COMPANIES.ADDRESS.CONFIRM.STRING.TEXT') }}
                <router-link class='text-underline h-fw-700' :to='`/consult/companies/address/${$route.params.id}`'>
                    {{ $t('COMPANIES.ADDRESS.CONFIRM.STRING.LINK') }}
                </router-link>
            </p>
        </div>
    </div>

    <div v-if='isEdit' class='h-flex h-flex-center h-flex-dir-column h-mb-50'>
        <FwButton
            v-if='!hideSaveButton'
            :disabled='disabled'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div class='b-add-company__form__delete'
             @click='$emit("delete")'>
            {{ $t('CONSULT.DELETE.BUTTON') }}
        </div>
    </div>

    <div v-else class='h-flex h-flex-center'>
        <FwButton
            :disabled='disabled'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('COMPANIES.FORM.SUBMIT.TEXT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch, Emit, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ValidationMixin from '@/mixins/validation';
import { parseAddress } from '@/helpers/address';
import { CompanyData, CompaniesCreateFormDataType } from '@/types/Companies';
import { isEqual } from '@/helpers/base';

@Component({
    validators: {
        name(value: string) {
            return this.baseInputValidator({ value });
        },
        address(value: string) {
            return this.baseInputValidator({ value, isRequired: !this.isEdit });
        },
        zip_code(value: string) {
            return this.baseInputValidator({ value, isRequired: !this.isEdit });
        },
        city(value: string) {
            return this.baseInputValidator({ value, isRequired: !this.isEdit });
        },
        contact_email(value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
})
export default class AddOrEditCompanyForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly companyData!: CompanyData;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isCompanyCreated!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabledEdit!: boolean;

    isEdit: boolean = false;
    confirmCheckbox: boolean = false;

    name: string = '';
    address: string = '';
    siren: string = '';
    siret: string = '';
    phone: string = '';
    zip_code: string = '';
    insee_code: string = '';
    city: string = '';
    contact_email: string = '';
    is_address_confirmed: boolean = false;
    phoneCode: string = '+33';
    serverFormData: null | CompaniesCreateFormDataType = null;

    formTitlesMapping: { [key: string]: TranslateResult | string } = {};
    requiredFields: Array<string> = [];

    serverErrors: serverErrors = {
        name: null,
        address: null,
        zip_code: null,
        city: null,
    };

    get isEqual(): boolean {
        return isEqual(this.serverFormData, this.currentForm);
    }

    get hideSaveButton(): boolean {
        return this.isEdit && this.disabledEdit;
    }

    get disabled(): boolean {
        return (!this.isFormValid || this.isRequestSending || this.isEqual);
    }

    get isCompanyCreatedAndNotEdit(): boolean {
        if (this.hideSaveButton) {
            return true;
        }
        return this.isCompanyCreated && !this.isEdit;
    }

    get currentForm(): CompaniesCreateFormDataType {
        const addressPayload = {
            address: `${this.address}, ${this.zip_code}, ${this.city}`,
            is_address_confirmed: this.confirmCheckbox,
        };
        return {
            name: this.name,
            siren: this.siren,
            siret: this.siret,
            phone: this.phone ? this.phoneCode + this.phone : this.phone,
            insee_code: this.insee_code,
            contact_email: this.contact_email,
            ...addressPayload,
        };
    }

    updateFormByServerValues() {
        if (this.companyData.title) {
            this.name = this.companyData.title;
        }
        if (this.companyData.address) {
            const address = parseAddress(this.companyData.address);
            this.address = address.address;
            this.zip_code = address.zip_code;
            // @ts-ignore-next-line
            this.city = address.city;
        }
        if (this.companyData.contact_email) {
            this.contact_email = this.companyData.contact_email;
        }
        if (this.companyData.siret) {
            this.siret = this.companyData.siret;
        }
        if (this.companyData.siren) {
            this.siren = this.companyData.siren;
        }
        if (this.companyData.phone) {
            let { phone } = this.companyData;
            if (this.companyData.phone && this.companyData.phone.startsWith(this.phoneCode)) {
                phone = this.companyData.phone.replace(this.phoneCode, ``);
            }
            this.phone = phone;
        }
        if (this.companyData.insee_code) {
            this.insee_code = this.companyData.insee_code;
        }
        if (this.companyData.is_address_confirmed) {
            this.confirmCheckbox = this.companyData.is_address_confirmed;
        }
        this.serverFormData = JSON.parse(JSON.stringify(this.currentForm));
    }

    created() {
        this.formTitlesMapping = {
            name: this.$i18n.t('COMPANIES.FORM.INPUT.NAME.LABEL'),
            address: this.$i18n.t('COMPANIES.FORM.INPUT.ADDRESS.LABEL'),
            siren: 'SIREN',
            siret: 'SIRET',
            zip_code: this.$i18n.t('CONSULT.ADD.FILE.RESULT.FIELDS.ZIP_CODE'),
            insee_code: 'INSEE',
            city: this.$i18n.t('COMPANIES.FORM.INPUT.CITY.LABEL'),
            phone: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
            contact_email: 'Email',
        };
        if (this.companyData) {
            this.isEdit = true;
            this.requiredFields = ['name'];
            this.updateFormByServerValues();
        } else {
            this.requiredFields = ['name', 'address', 'zip_code', 'city'];
        }
    }

    mounted(): void {
        if (this.requiredFields && this.companyData) {
            for (let i = 0; i < this.requiredFields.length; i++) {
                this.addCurrentInputToValidateArray(this.requiredFields[i]);
            }
        }
    }

    @Watch('errorFromServer')
    checkEmailError() {
        if (this.errorFromServer.includes('Title')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                name: this.$i18n.t('COMPANY.TITLE.TAKEN'),
            };
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return this.currentForm;
    }
}
</script>

<style lang='sass'>
.b-add-company__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-company__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: $cancel-red
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
