<template>
<div class='b-calendar-page__wrapper'>
    <div
        v-if='showCalendar'
        class='b-appointment-left-bar'
        :class='{ "b-over-fixed-background": isFixedBg && isLeftBarFormShow }'>
        <div v-show='!(showForm && isFixedBg && isPlanAnAppointmentCalendar)'>
            <PlanAppointmentInfo v-if='isPlanAnAppointmentCalendar' :companyData='companyDate'></PlanAppointmentInfo>
            <CompanyCalendarInfo v-else-if='isCompanyCalendar'></CompanyCalendarInfo>
            <WorkersCalendarInfo v-else-if='isWorkersCalendar'></WorkersCalendarInfo>
            <CalendarDatePicker
                :dateCurrent='date'
                :reservedSlotsData='calendarReservedSlotsData'
                @changeCalendarMonth='changeCalendarMonth'
                @updateDates='updateDates'>
            </CalendarDatePicker>
        </div>
        <div v-if='showForm && hideForm'>
            <template v-if='isPlanAnAppointmentCalendar'>
                <PlanAppointmentForm v-if='isFixedBg'></PlanAppointmentForm>
                <div class='b-appointment-form-back_button h-pos-abs'
                     @click='closeForm'>
                    <FwIcon
                        v-if='isFixedBg'
                        class='h-mh-5 h-pointer'
                        icon='close'
                        size='15'
                        color='#BEC7D4'>
                    </FwIcon>
                </div>
            </template>
            <portal v-else-if='isWorkersCalendar || isCompanyCalendar'
                    to='popupWrapper'>
                <CompanyPopup
                    :data='formData.data'
                    :date='formData.date'
                    :localEventId='formData.localEventId'
                    @close='closeForm'
                    @updateCalendar='getPageData'>
                </CompanyPopup>
            </portal>
        </div>
    </div>
    <div class='b-appointment-main'>
        <div :style='{ "opacity": tableOpacity }'>
            <div class='b-appointment-main__header h-pt-5'>
                <div style='background: transparent; height: auto'>
                    <div style='min-width: 200px'>
                        <div v-if='isPlanAnAppointmentCalendar'>
                            <span class='b-appointment-main__header--selector h-font-12 h-fw-500'>
                                {{ $t('CALENDAR.VIEW.AGENDA') }}
                            </span>
                            <div style='min-width: 200px; position: relative;'
                                 class='b-select-company__calendar fw-select-tooltip'
                                 :class='{ "b-over-fixed-background": isFixedBg && !showForm }'>
                                <FwSelect
                                    v-model='value'
                                    class='h-font-14 h-fw-700 h-table-cell'
                                    :canNotDelete='true'
                                    :propsPlaceholder='companyTitle'
                                    :options='companiesStoreData'
                                    label='company_title'
                                    :pagination='true'
                                    :clear-on-select='true'
                                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                    @focus='setFixedBgStatus(true)'
                                    @blur='setFixedBgStatus(false)'
                                    @select='choosedCompany'>
                                </FwSelect>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 class='b-appointment-main__title'>
                        {{ $t(currentMonthName) }} {{ currentYear }}
                    </h2>
                </div>
                <CalendarDateNavigation
                    :date='calendarDate'
                    :hideBackButton='hideCalendarDateNavigationBackButton'
                    @changeDay='changeDay'>
                </CalendarDateNavigation>
            </div>
            <FwHorizontalScroll
                :isPageLoaded='true'
                slotWrapperClass='h-flex h-flex-dir-column h-mh--10'
                newClass='fw-horizontal-scroll__shadow'
                colorIcon='#203F6A'
                btnLocation='between'
                sizeIcon='20'
                slotScrollClass='h-p-0'
                iconClass='h-opacity-0_8'>
                <template #content>
                    <div class='b-appointment-main__body'>
                        <PlanTable
                            v-if='showTable && dates.length'
                            :dates='datesByCalendarSettings'
                            :events='events'
                            :reservedSlots='reservedSlots'
                            :type='$route.name'
                            :monthDataLoading='monthDataLoading'
                            :calendarDate='calendarDate'
                            :date='date'
                            @openForm='openForm'
                            @updateData='getPageData'>
                        </PlanTable>
                    </div>
                </template>
            </FwHorizontalScroll>
            <div v-if='!isDesktop()'
                 class='b-appointment-main__show-button h-flex-center'>
                <FwButton
                    class='h-mv-5'
                    borderRadiusType='small-border'
                    @click='showCalendar = !showCalendar'>
                    Show calendar
                </FwButton>
            </div>
        </div>
        <FwSpinLoader
            v-if='!tableOpacity'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='b-appointment-main__loading'
            :isActive='monthDataLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <InformationPopup
        v-if='isShowMessageInfo && isPlanAnAppointmentCalendar'
        :headerText='$t("Message")'
        :bodyText='companyDate.notif_message'
        :buttonText='$t(`CALENDAR.POPUP.BUTTON.GO.TO.COMPANY.PAGE`)'
        width='430px'
        isColorTransparent
        isShowCloseCross
        @close='isShowMessageInfo = false'
        @goToPage='goToCompanyPage'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';
import { CompanyData } from '@/types/Companies';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { PlanTable } from '@/components/common/PlanTable';
import { PlanAppointmentInfo } from '@/components/common/PlanAppointmentInfo';
import { PlanAppointmentForm } from '@/components/common/PlanAppointmentForm';
import { CalendarDatePicker } from '@/components/nodes/CalendarDatePicker';
import { CompanyCalendarInfo } from '@/components/nodes/CompanyCalendarInfo';
import { WorkersCalendarInfo } from '@/components/nodes/WorkersCalendarInfo';
import { CalendarDateNavigation } from '@/components/nodes/CalendarDateNavigation';
import { CompanyPopup } from '@/components/popups/CompanyPopup';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { MONTHS, equalByDay } from '@/helpers/dates';
import AuthMixin from '@/mixins/auth';
import {
    EventsType,
    CompanyEventType,
    WorkersEventType,
} from '@/types/Events';
import { BaseMetaType } from '@/types/Response';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import { companyDataProps } from '@/helpers/company';
import { AppointmentDataType } from '@/types/Appointment';

const CommonStore = namespace('CommonStore');

interface SelectedData {
    id: string,
    company_id: string,
    title?: string,
}

@Component({
    components: {
        CalendarDateNavigation,
        CalendarDatePicker,
        CompanyCalendarInfo,
        WorkersCalendarInfo,
        PlanTable,
        PlanAppointmentInfo,
        PlanAppointmentForm,
        CompanyPopup,
        InformationPopup,
    },
})

export default class CalendarPage extends Mixins(DateMixin, CalendarMixin, AuthMixin) {
    @CommonStore.Getter('isFixedBg') isFixedBg!: boolean;
    @CommonStore.Getter('hideFixedBgStatus') hideFixedBgStatus!: boolean;
    @CommonStore.Mutation('setFixedBgStatus') setFixedBgStatus!: Function;
    @CommonStore.Mutation('setHideBgStatus') setHideBgStatus!: Function;

    selectorLoader: boolean = false;
    date: Date = new Date();
    calendarDate: Date = new Date();
    previousDate: string | null = null;
    dates: Array<Date> = [];
    datesInterval = 7;
    tableOpacity: number = 0;
    showForm: boolean = false;
    isShowMessageInfo: boolean = false;
    companyTitle: string | null = null;
    formData: CompanyEventType | WorkersEventType | EventsType | null = null;
    value: string | null | CompanyData = null;
    options: Array<CompanyData> | null = [
        companyDataProps,
    ];
    meta: BaseMetaType | null = null;
    windowWidth: number | null = null;
    showCalendar: boolean = this.isTablet() || this.isDesktop();
    monthDataLoading: boolean = false;
    showTable: boolean = true;
    choosedDateWorkerCalendar: Date | undefined | null = null;

    handleResize() {
        this.windowWidth = window.innerWidth;
        this.showCalendar = this.windowWidth > 992;
    }

    get datesByCalendarSettings() {
        return this.calculateDatesByCalendarSettings(this.dates);
    }

    get hideCalendarDateNavigationBackButton() {
        return equalByDay(this.date, new Date());
    }

    get calendarViewData(): any {
        return this.$store.state.CalendarPageStore.calendar_settings_data ?
            this.$store.state.CalendarPageStore.calendar_settings_data :
            null;
    }

    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }

    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }

    get companiesStoreData(): any {
        return this.$store.state.AppointmentStore.addCompaniesListData;
    }

    get events(): Array<UnavailabilityEventInstancesType> | Array<AppointmentDataType> | Array<CompanyEventType> {
        return this.$store.state.CalendarPageStore.events;
    }

    get currentMonthName(): string {
        return this.calendarDate ? MONTHS[this.calendarDate.getMonth()] : '';
    }

    get currentYear(): string {
        return this.calendarDate ? `${this.calendarDate.getFullYear()}` : '';
    }
    get hideForm() {
        if (!this.hideFixedBgStatus) {
            this.showForm = false;
        }
        return this.hideFixedBgStatus;
    }

    get isLeftBarFormShow(): boolean {
        return this.showForm && this.isPlanAnAppointmentCalendar;
    }

    get calendarReservedSlotsData(): any {
        return this.$store.state.CalendarPageStore.calendar_reserved_slots;
    }

    get choosedDate() {
        return this.choosedDateWorkerCalendar ? this.choosedDateWorkerCalendar : this.date;
    }

    calculateDatesByCalendarSettings(dates: Array<Date>): Array<Date> {
        let result = [];
        if (this.isPlanAnAppointmentCalendar && this.calendarViewData) {
            for (let i = 0; i < dates.length; i++) {
                if (!this.calendarViewData.saturday && !this.calendarViewData.sunday) {
                    if (dates[i].getDay() !== 6 && dates[i].getDay() !== 0) {
                        result.push(dates[i]);
                    }
                } else if (!this.calendarViewData.saturday) {
                    if (dates[i].getDay() !== 6) {
                        result.push(dates[i]);
                    }
                } else if (!this.calendarViewData.sunday) {
                    if (dates[i].getDay() !== 0) {
                        result.push(dates[i]);
                    }
                } else {
                    result.push(dates[i]);
                }
            }
        } else {
            result = dates;
        }
        return result;
    }

    async choosedCompany(value: SelectedData) {
        if (value) {
            this.setFixedBgStatus(false);
            // this.setHideBgStatus(false);
            try {
                this.tableOpacity = 0;
                this.$store.commit('CALENDAR_DATE_CLEAR');
                const data = await AppointmentWebApi.getSocietyItemData(value.company_id);
                this.$store.commit('PLAN_COMPANY', data.company);
                this.value = data.company;
                if (this.value) {
                    this.value.company_title = data.company.title;
                }
                if (data.company.notif_message) {
                    this.isShowMessageInfo = true;
                }
                this.showTable = false;
                this.date = new Date();
                this.updateDates();
                await this.getMonthData(this.date);
                this.showTable = true;
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.tableOpacity = 1;
            }
        }
    }

    goToCompanyPage() {
        this.$router.push({ path: `/consult/companies/${this.companyDate.id}` });
    }

    openForm(data: CompanyEventType | WorkersEventType | EventsType) {
        this.showForm = true;
        this.showCalendar = true;
        this.setFixedBgStatus(true);
        this.setHideBgStatus(true);
        this.formData = data;
        if (!this.showCalendar) {
            this.showCalendar = true;
        }
    }

    closeForm() {
        this.showForm = false;
        this.setFixedBgStatus(false);
        this.setHideBgStatus(false);
    }

    changeDay({ delta, date }: { delta: number, date: null | Date }) {
        if (date) {
            const dayIndex = this.datesByCalendarSettings.findIndex(item => date.getDate() === item.getDate());
            if (dayIndex !== undefined && this.datesByCalendarSettings[dayIndex + delta]) {
                this.date = this.datesByCalendarSettings[dayIndex + delta];
            } else {
                const previousWeekDate: Date = this.getDate({ delta: -7, date });
                const previousDates = this.calculateDatesByCalendarSettings(this.prepareDates(previousWeekDate));
                this.date = previousDates[previousDates.length - 1];
            }
        }
        this.updateDates(this.date);
    }

    async updateDates(date: Date | null = null) {
        if (this.windowWidth && this.windowWidth < 992) {
            this.showCalendar = false;
        }
        this.dates = this.prepareDates(date || this.date);
        if (date) {
            this.calendarDate = date;
        }
        if (date && this.previousDate !== new Date(date).toLocaleString('default', { month: 'long' })) {
            await this.getMonthData(date);
        }
    }

    prepareDates(date?: Date): Array<Date> {
        const dates = [];
        const localDate = this.getDayDateAtNight(date || new Date());
        for (let delta = 0; delta < this.datesInterval; delta++) {
            dates.push(this.getDate({ delta, date: localDate }));
        }
        return dates;
    }

    async getPageData(date?: Date) {
        if (this.isPlanAnAppointmentCalendar && (!this.companyDate || !this.appointmentStoreData)) {
            this.$router.push({ path: '/plan/intervention-code' });
        }
        this.companyTitle = this.companyDate.company_title;
        this.choosedDateWorkerCalendar = date;
        this.value = this.companyDate;
        if (this.value && this.companyDate) {
            // @ts-ignore-next-line
            // TODO Yevhen
            this.value.company_title = this.companyDate.title;
        }
        this.updateDates(date);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    async created() {
        await this.getPageData();
        await this.getMonthData(this.choosedDate);
        if (this.companyDate && this.companyDate.notif_message) {
            this.isShowMessageInfo = true;
        }
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        this.$store.commit('CALENDAR_DATE_CLEAR');
    }
}
</script>

<style lang='sass'>
.h-table-cell
    display: table-cell

.h-table
    display: table

.h-white-space-nowrap
    white-space: nowrap

.b-appointment-main
    flex: 1
    height: 100%
    background-color: #fff
    transition: opacity 0.2s
    position: relative
    .b-select-company
        &__calendar
            .multiselect__option
                padding: 17px !important
                display: table !important
                width: 100%

            .multiselect__element
                display: table
                width: 100%

    &__loading
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center

    .fw-select-tooltip .multiselect__content
        overflow-y: auto !important

    .fw-select-tooltip .multiselect__content-wrapper
        display: block
        overflow-y: auto !important
        margin-top: 10px
        width: auto !important

    .multiselect__tags
        padding: 0!important
        max-height: 30px!important
        min-height: 30px!important

    &__header
        margin: 0 68px 0 60px
        display: flex
        justify-content: space-between
        align-items: center

        &--selector
            text-transform: uppercase
            color: #d3d9e1

    &__title
        font-size: 20px

    &__today-button
        width: 83px
        height: 28px

    &__show-button
        position: fixed
        bottom: 0
        width: 100%
        background: #fff
    .multiselect__content-wrapper
        min-width: 280px
        min-height: 80px
    .fw-select__caret
        right: 5px
    .multiselect__placeholder
        padding-right: 20px
    .multiselect:after
        right: -1px!important

.fw-horizontal-scroll__inner
    height: calc(100vh - 75px)!important

.fw-horizontal-scroll__content
    height: 100%!important

.b-calendar-page__wrapper
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden

@include media('<tablet')
    .b-appointment
        &-left-bar
            overflow: scroll
            position: fixed
            top: 0
            width: 100%
            height: 100%
            z-index: 31

    .b-appointment-main
        &__header
            flex-flow: column nowrap
            margin: 0
            padding: 5px 0

        &__title
            font-size: 16px
</style>
