import { lazyLoadView } from '@/helpers/url';

export default [
    {
        path: '/login',
        alias: '/',
        name: 'LoginPage',
        component: lazyLoadView(`auth/LoginPage/LoginPage`),
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/login-with-microsoft',
        alias: '/',
        component: lazyLoadView(`auth/LoginMicrosoftPage/LoginMicrosoftPage`),
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/auth',
        alias: '/',
        name: 'AuthPage',
        component: lazyLoadView(`auth/AuthPage/AuthPage`),
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/reset-password-email',
        name: 'ResetPasswordEmailPage',
        component: lazyLoadView(`auth/ResetPasswordEmailPage/ResetPasswordEmailPage`),
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: lazyLoadView(`auth/ResetPasswordPage/ResetPasswordPage`),
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
];
