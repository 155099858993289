<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ $t('CONSULT.ADD.FILE.TUTORIAL.TITLE') }}
        </h1>
    </div>
    <div class='h-flex-center h-flex-dir-column h-mb-50'>
        <div v-for='(step, index) in listOfSteps'
             :key='index'
             class='b-add-company-by-file'>
            <div class='b-add-company-by-file__text'>
                <div class='b-add-company-by-file__index'>
                    {{ index + 1 }}
                </div>
                <div v-html='step.text'></div>
            </div>
            <img v-if='step.imageUrl'
                 class='b-add-company-by-file__image'
                 :src='requireImage(step.imageUrl)'>
        </div>

        <FwButton
            :to='getRoute'
            class='h-mb-30 h-flex h-flex-center'
            borderRadiusType='small-border'>
            {{ $t('CONSULT.ADD.FILE.TITLE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { CONSULT_PREFIX, ADD_WORKERS_PREFIX } from '@/routes/config';

interface listOfStepsType {
    text: string | TranslateResult,
    imageUrl?: string,
}

@Component
export default class AddCompanyByFileInfo extends Vue {
    listOfSteps: Array<listOfStepsType> = [];

    get getRoute() {
        return this.$route.meta ? `${CONSULT_PREFIX}${this.$route.meta.urlPrefix}/file` : ``;
    }

    get isWorkerPage() {
        return this.$route.meta && this.$route.meta.urlPrefix === ADD_WORKERS_PREFIX;
    }

    created() {
        this.listOfSteps = [
            {
                text: this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.1'),
            },
            {
                text: (
                    this.isWorkerPage ?
                        this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.2.WORKERS') :
                        this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.2.COMPANIES')
                ),
                imageUrl: this.isWorkerPage ? 'add-file-worker.jpg' : 'add-file-company.jpg',
            },
            {
                text: this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.3'),
            },
            {
                text: this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.4'),
            },
            {
                text: this.$i18n.t('CONSULT.ADD.FILE.TUTORIAL.STEP.5'),
            },
        ];
    }
}
</script>

<style lang='sass'>
.b-add-company-by-file
    display: block
    width: 100%
    margin-bottom: 30px

    &__index
        width: 25px
        height: 25px
        border-radius: 2px
        color: #27DBBD
        background-color: lighten(#27DBBD, 42)
        font-size: 16px
        display: flex
        align-items: center
        justify-content: center
        font-weight: 500
        margin-right: 36px
        flex: 0 0 25px
        line-height: 1em

    &__text
        display: flex
        font-size: 16px
        line-height: 20px
        color: $alt-blue

    &__image
        max-width: 100%
        margin-top: 40px
        margin-bottom: 10px
</style>
