import { RouteType } from '@/types/router';
import { lazyLoadView } from '@/helpers/url';

const profileRoutes: Array<RouteType> = [
    {
        path: '/profile',
        name: `ProfileIndexPage`,
        component: lazyLoadView(`profile/ProfileIndexPage/ProfileIndexPage`),
        meta: {
            showAppHeader: true,
        },
    },
];

export default profileRoutes;
