export default {
    'SETTINGS.ROLE.EDIT.POPUP.TITLE': 'Edit roles',
    'SETTINGS.ROLE.ADD.POPUP.TITLE': 'Add a new role',
    'SETTINGS.ROLE.SUBTITLE': 'Please assign at least one right',
    'SETTINGS.ROLE.NAME.LABEL': 'Role name',
    'SETTINGS.ROLE.REQUIRED.FIELD': 'Required field',
    'SETTINGS.ROLE.NAME.PLACEHOLDER': 'Give a name to this new role',
    'SETTINGS.ROLE.APPOINTMENT.TITLE': 'Appointment',
    'APPOINTMENT.TITLE.CANCELED': 'Appointment successfully canceled',
    'appointment_booking': 'Book an appointment',
    'appointment_cancelling': 'Cancel an appointment',
    'SETTINGS.ROLE.COMPANIES.TITLE': 'Companies',
    'company_list_consulting': 'Consult the list of companies',
    'company_list_cancelling': 'Delete a company',
    'company_calendar_consulting': 'Consult companies calendar',
    'company_list_adding': 'Add a company',
    'company_list_editing': 'Edit a company',
    'SETTINGS.ROLE.WORKERS.TITLE': 'Technicians',
    'worker_list_consulting': 'Consult the list of technicians',
    'worker_list_cancelling': 'Delete a technician',
    'worker_list_adding': 'Add a technician',
    'worker_calendar_consulting': 'Consult technicians calendar',
    'worker_list_editing': 'Edit a technician',
    'settings_adding_team_member': 'Add a team member',
    'SETTINGS.ROLE.DELETE.TITLE': 'Delete role',
    'SETTINGS.ROLE.DELETE.TXT.EXISTING.USER': 'There are still some users with this role. Please update the role of these users before deleting the role.',
    'SETTINGS.ROLE.DELETE.TXT': 'Do you really want to delete this role?',
    'SETTINGS.ROLE.DELETE.BUTTON.CONFIRM': 'Yes, delete',
    'SETTINGS.ROLE.DELETE.BUTTON.CANCEL': 'Cancel',
    'settings_editing_team_member': 'Edit a user',
    'settings_deleting_team_member': 'Delete a user',
    'settings_adding_role': 'Add a role',
    'settings_editing_role': 'Edit a role',
    'settings_deleting_role': 'Delete a role',
};
