<template>
<div class='b-page-container b-page-container--big'>
    <div class='b-page-title h-mh-10'>
        <h1>
            {{ $t('LABEL.COMPANIES') }}
        </h1>
        <div class='h-flex h-flex-wrap b-company-page'>
            <template>
                <div class='b-consult-button-size'>
                    <FwButton
                        size='flex'
                        class='h-flex h-flex-center h-text-center'
                        borderRadiusType='tiny-border'
                        @click='openSearch'>
                        {{ $t('LABEL.SEARCH.COMPANIES') }}
                    </FwButton>
                </div>
            </template>
        </div>
    </div>
    <div class='b-consult-cards__wrapper'>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <CompanyCard
            v-for='company in companiesCards'
            v-else
            :key='company.id'
            :company='company'
            class='h-mh-10 h-mb-20'>
        </CompanyCard>
    </div>
    <FwPagination
        v-if='!isSearchPopupOpen'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
    <SearchCompanyPopup
        v-if='isSearchPopupOpen'
        :companiesCards='companiesCards'
        :totalPageProp='totalPage'
        :currentPageProp='currentPage'
        @closePopup='closePopup'>
    </SearchCompanyPopup>
</div>
</template>

<script lang='ts'>
import throttle from 'lodash.throttle';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { CompanyData } from '@/types/Companies';
import { CompanyCard } from '@/components/simple/CompanyCard';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { SearchCompanyPopup } from '@/components/popups/SearchCompanyPopup';
import { updateUrlPageAndQuery } from '@/helpers/url';

const SORT_BY_NAME = 'name';
const SORT_BY_NUMBER = 'number';

@Component({
    components: {
        CompanyCard,
        SearchCompanyPopup,
    },
})
export default class ConsultCompaniesPage extends Vue {
    searchText: string = '';
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    loading: boolean = true;
    isSearchPopupOpen: boolean = false;
    sortByWorkersCount: boolean = false;
    sortValue: null | { name: string, type: string } = null;
    sortOptions: Array<{ name: any | TranslateResult, type: string }> = [];
    companiesCards: Array<CompanyData> = [];
    throttleSearch = throttle(this.doRequest, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    updateState(page: number = this.currentPage) {
        this.$router.replace(updateUrlPageAndQuery(`/consult/companies`, page, {
            query: this.searchText,
        })).catch(() => {});
    }

    closePopup() {
        this.isSearchPopupOpen = false;
        this.searchText = ``;
        this.updateState(this.currentPage);
    }

    getCompaniesList(el: number, val: number) {
        if (this.searchText.length > 1) {
            this.getCompaniesBySearch(el, val, this.searchText, this.sortByWorkersCount);
        } else if (this.searchText.length === 0) {
            this.getCompanies(el, val, this.sortByWorkersCount);
        }
    }

    openSearch() {
        this.isSearchPopupOpen = true;
    }

    async getCompanies(el: number, val: number, sort: boolean) {
        this.loading = true;
        this.currentPage = el;
        const payload: any = {
            current_page: el,
            per_page: val,
            sort,
        };
        try {
            const data = await AppointmentWebApi.getSocietyPaginationData(payload);
            this.totalPage = data.meta.total_count;
            this.loading = false;
            this.companiesCards = data.companies;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async getCompaniesBySearch(el: number, val: number, searchText: string, sort: boolean) {
        if (this.searchText.length > 1) {
            this.loading = true;
            this.currentPage = el;
            const payload: any = {
                current_page: el,
                per_page: val,
                sort,
            };
            try {
                const data = await AppointmentWebApi.getSocietyDataBySearch(payload, searchText);
                this.totalPage = data.meta.total_count;
                this.loading = false;
                this.companiesCards = data.companies;
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.loading = false;
            }
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.isSearchPopupOpen = !!this.$route.query.popup;
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.searchText = (this.$route.query.query as string) || ``;
        if (this.$route.query.sort) {
            const sotValue = this.sortOptions.find(item => item.type === this.$route.query.sort);
            if (sotValue) {
                this.sortValue = sotValue;
            }
        }
        if (!this.sortValue) {
            this.sortValue = this.sortOptions[0];
        }
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortValue', this.sortBy);
    }

    created() {
        this.sortOptions = [
            {
                name: this.$i18n.t('PLAN.SOCIETY.LABEL'),
                type: SORT_BY_NAME,
            },
            {
                name: this.$i18n.t('CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NUMBER'),
                type: SORT_BY_NUMBER,
            },
        ];
        this.takeParamsFromUrl();

        if (!this.isSearchPopupOpen) {
            this.doRequest();
        }
    }

    search() {
        this.currentPage = 1;
        this.doRequest();
    }

    doRequest() {
        if (this.searchText.length > 1) {
            this.search();
        } else if (this.searchText.length === 0) {
            this.getCompanies(this.currentPage, this.perPage, this.sortByWorkersCount);
        }
    }

    sortBy() {
        this.currentPage = 1;
        if (this.sortValue) {
            if (this.sortValue.type === SORT_BY_NAME) {
                this.sortByWorkersCount = false;
                this.getCompaniesList(this.currentPage, this.perPage);
            } else if (this.sortValue.type === SORT_BY_NUMBER) {
                this.sortByWorkersCount = true;
                this.getCompaniesList(this.currentPage, this.perPage);
            }
        }
    }

    @Watch(`$route`)
    onRouterChange(): void {
        if (!this.isSearchPopupOpen) {
            this.takeParamsFromUrl();
            this.doRequest();
        }
    }
}
</script>

<style lang='sass'>
.b-company-page
    .fw-input--search
        background-color: #eff0f0 !important

.b-work-company-page

    .multiselect__tags
        display: flex!important
        align-items: center!important
        padding-left: 12px!important

    .multiselect__single
        margin-bottom: 0!important

.b-consult-button-size
    height: 44px
    margin: 0 5px

    .fw-button
        padding: 0 10px
</style>
