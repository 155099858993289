<template>
<div class='b-appointment-info'>
    <div>
        <router-link
            to='/plan/intervention-code'>
            <img :src='requireImage("logo.svg")'>
        </router-link>
    </div>
    <h2 class='b-appointment-left-bar__title'>
        {{ $t('PLAN.CALENDAR.APPOINTMENT.HEADER') }}
    </h2>
    <ConsultCard
        v-for='information in informationData'
        :key='information.title'
        :information='information'>
    </ConsultCard>
    <div v-if='companyData' class='b-consult-card__sub-wrapper--base b-consult-card__sub-wrapper'>
        <div class='b-consult-card__sub-title'>
            {{ $t('LABEL.COMPANY') }}
        </div>
        <div class='b-consult-card__sub-aside'>
            <router-link
                class='text-underline-hover'
                :to='{ path: companyUrl }'
                target='_blank'>
                <span>
                    {{ companyData.title }}
                </span>
                <FwIcon
                    class='h-pointer h-bold'
                    color='#BEC7D4'
                    icon='link-target'
                    size='14'>
                </FwIcon>
            </router-link>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { informationDataType } from '@/types/Consult';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { CompanyData } from '@/types/Companies';

@Component({
    components: {
        ConsultCard,
    },
})
export default class PlanAppointmentInfo extends Vue {
    informationData: Array<informationDataType> = [];

    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }

    get companyData(): any {
        return this.$store.state.CalendarPageStore.company;
    }

    get companyUrl(): string {
        return `/consult/companies/${this.companyData.id || this.companyData.company_id}`;
    }

    prepareData(appointmentData: any): Array<informationDataType> {
        const appointmentDataArray: Array<informationDataType> = [];
        if (appointmentData) {
            if (appointmentData.title) {
                appointmentDataArray.push({
                    title: this.$i18n.t('CALENDAR.APPOINTMENT.LABEL'),
                    aside: appointmentData.title,
                    info: `Identification: ${appointmentData.contract_id}`,
                });
            }
            if (appointmentData.duration) {
                appointmentDataArray.push({
                    title: this.$i18n.t('CALENDAR.DURATION.LABEL'),
                    aside: `${appointmentData.duration}m`,
                });
            }
            if (appointmentData.location) {
                appointmentDataArray.push({
                    title: this.$i18n.t('CALENDAR.LOCATION.LABEL'),
                    aside: appointmentData.location,
                });
            }
        }
        return appointmentDataArray;
    }
    created() {
        this.informationData = this.prepareData(this.appointmentStoreData);
    }
}
</script>
