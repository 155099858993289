export default {
    'WORKERS.FORM.TITLE': 'Ajouter une ressource',

    'WORKERS.SEARCH.PLACEHOLDER': 'Nom ou prénom',
    'WORKERS.ADD.BUTTON': 'Ajouter une ressource',

    'WORKERS.USER.NO_NAME': 'Sans nom',

    'WORKERS.FORM.INPUT.FIRST_NAME.LABEL': 'Prénom',
    'WORKERS.FORM.INPUT.LAST_NAME.LABEL': 'Nom',
    'WORKERS.FORM.INPUT.PHONE.LABEL': 'Téléphone',
    'WORKERS.FORM.INPUT.COLOR.LABEL': 'Couleur',
    'WORKERS.FORM.INPUT.LABEL.LABEL': 'Étiquette',
    'WORKERS.FORM.INPUT.COMPANIES.LABEL': 'Sociétés',
    'WORKERS.FORM.INPUT.COMPANY.LABEL': 'Société',
    'WORKERS.FORM.INPUT.ROLE.LABEL': 'Fonction',
    'WORKERS.FORM.INPUT.PLACEHOLDER.SELECT_COMPANIES.LABEL': 'Sélectionnez une ou plusieurs sociétés',
    'WORKERS.FORM.HINT.ONE_OF_THESE': 'Veuillez remplir au moins un de ces champs',
    'WORKERS.FORM.HINT.REQUIRED': 'Champs requis',

    'WORKERS.FORM.SUBMIT.TEXT': 'Ajouter',
    'WORKERS.POPUP.SUCCESS.MESSAGE': 'Une ressource a été ajoutée',
    'WORKERS.POPUP.SUCCESS.EDIT.MESSAGE': 'Le profil du technicien a bien été modifié',
};
