<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h1>
            Reading your file
        </h1>
        <div class='h-mt--10'>
            28 companies founded
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class AddWorkersByFileData extends Vue {
    addCompanyFileDate: Array<any> = []
}
</script>
