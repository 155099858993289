const COMPANY_NAME = 'company_name';
const REASON_COMPANY_NAME = 'reason_company_name';
const COMPANY_MANAGER = 'company_manager';
const CLIENT_SURNAME = 'client_surname';
const CLIENT_FULLNAME = 'client_details';
const CLIENT_NAME = 'client_name';
const CONTRACT_NUMBER = 'contract_number';
const LOCATION_VALUE = 'location_value';
const COMPANY = 'company';
const COMPANY_ADDRESS = 'company_address';
const CONTACTS = 'contacts';
const ADVISOR_NAME = 'advisor_name';
const ADVISOR_PHONE = 'advisor_phone';
const BRANCH_VALUE = 'branches_name';

const TITLE = 'title';
const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const PHONE = 'phone';
const CONTACT_EMAIL = 'email';
const NOTES = 'notes';

export {
    COMPANY_NAME,
    COMPANY_ADDRESS,
    CONTACTS,
    REASON_COMPANY_NAME,
    COMPANY_MANAGER,
    CLIENT_FULLNAME,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    COMPANY,
    ADVISOR_NAME,
    ADVISOR_PHONE,
    BRANCH_VALUE,
    TITLE,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    CONTACT_EMAIL,
    NOTES,
};
