export default {
    'SETTINGS.ROLES.TITLE': 'Fonction',
    'SETTINGS.ROLES.SEARCH.TEXT': 'Rechercher une fonction',

    'SETTINGS.MENU.HEADER': 'Paramètres',
    'SETTINGS.MENU.ACCOUNT': 'Compte',
    'SETTINGS.MENU.MANAGERS': 'Managers',
    'SETTINGS.MENU.ROLES': 'Fonctions',
    'SETTINGS.MENU.APPT.TYPES': 'Types de rendez-vous',
    'SETTINGS.MENU.APPT.REMINDERS': 'Rappels des rendez-vous',
    'SETTINGS.MENU.CALENDAR.VIEW': 'Vue calendrier',
    'SETTINGS.SEARCH.PLACEHOLDER': 'Rechercher un manager',
    'SETTINGS.SEARCH.APPT.PLACEHOLDER': 'Rechercher un rendez-vous',
    'SETTINGS.SORT.APPT.CLOSEST': 'le plus proche du rendez-vous)',
    'SETTINGS.SORT.APPT.FAREST': 'le plus loin du rendez-vous)',
    'SETTINGS.SORT.APPT.PEOPLE.IN': 'Personnes impliquées"',

    'SETTINGS.ROLE.CONSULT': 'Consulter',
    'SETTINGS.ROLE.VIEW': 'Vue',
    'SETTINGS.ROLE.ADD': 'Ajouter',
    'SETTINGS.ROLE.EDIT': 'MODIFIER',
    'SETTINGS.ROLE.LIST': 'Consulter (Liste)',
    'SETTINGS.ROLE.READ': 'Consulter',
    'SETTINGS.ROLE.CANCEL': 'Annuler',
    'SETTINGS.ROLE.DELETE': 'Effacer',
    'SETTINGS.ROLE.CREATE': 'Créer',
    'SETTINGS.ROLE.BOOK': 'Livre',

    'SETTINGS.ROLE.RIGHTS.TITLE': 'Configuration',
    'SETTINGS.ROLE.COMPANY.LIST.TITLE': 'Liste des entreprises',
    'SETTINGS.ROLE.COMPANY.WORKER.TITLE': 'Liste des travailleurs',
    'SETTINGS.ROLE.COMPANY.CALENDAR.TITLE': 'Calendrier de l\'entreprise',
    'SETTINGS.ROLE.WORKER.CALENDAR.TITLE': 'Calendrier des travailleurs',
    'SETTINGS.ROLE.NAME.TITLE': 'Nom de rôle',

    'SETTINGS.ROLE.RIGHTS.ACCOUNT': 'Compte',
    'SETTINGS.ROLE.RIGHTS.ADVISOR': 'Conseiller',
    'SETTINGS.ROLE.RIGHTS.ANONYMOUS_CALENDAR': 'Anonymisation du calendrier',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY': 'Types de rendez-vous',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_NOTIFICATION': 'Notification de rendez-vous',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT': 'Rendez-vous',
    'SETTINGS.ROLE.RIGHTS.BRANCH': 'Branche',

    'SETTINGS.ROLE.RIGHTS.TECHNICIAN.CALENDAR': 'Affichage du calendrier de planification',
    'SETTINGS.ROLE.RIGHTS.ADVISOR.CALENDAR': 'Conseiller Calendrier',
    'SETTINGS.ROLE.RIGHTS.COMPANY_CALENDAR': 'Entreprise Calendrier',
    'SETTINGS.ROLE.RIGHTS.HUB_BRANCH_CALENDAR': 'Hub/Branche Calendrier',
    'SETTINGS.ROLE.RIGHTS.HUB_CALENDAR': 'Hub Calendrier',
    'SETTINGS.ROLE.RIGHTS.BRANCH_CALENDAR': 'Branche Calendrier',
    'SETTINGS.ROLE.RIGHTS.CALENDAR': 'Calendrier (société)',
    'SETTINGS.ROLE.RIGHTS.PROFILE': 'Calendrier (technicien)',
    'SETTINGS.ROLE.RIGHTS.COMPANY': 'Société',
    'SETTINGS.ROLE.RIGHTS.HUB': 'Hub',
    'SETTINGS.ROLE.RIGHTS.KPI': 'Statistiques',
    'SETTINGS.ROLE.RIGHTS.MANAGER': 'Utilisateur',
    'SETTINGS.ROLE.RIGHTS.RIGHTS': 'Fonction',
    'SETTINGS.ROLE.RIGHTS.SETTINGS': 'Réglages',
    'SETTINGS.ROLE.RIGHTS.TECHNICIAN': 'Technicien',
    'SETTINGS.ROLE.RIGHTS.ONBOARDING': 'Onboarding',

    'SETTINGS.ROLE.UPDATE.SUCCESSFULLY': 'Rôle mis à jour avec succès',
    'SETTINGS.ROLE.CREATE.SUCCESSFULLY': 'Rôle créé avec succès',
    'SETTINGS.ROLE.DELETE.SUCCESSFULLY': 'Rôle supprimé avec succès',
    'SETTINGS.ROLE.DELETE.TITLE': 'Supprimer un rôle',
    'SETTINGS.ROLE.DELETE.SUB': 'Voulez-vous vraiment supprimer ce rôle?',

    'SETTINGS.ROLE.GROUP.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.GROUP.BOOKING_FLOW': 'Prise de rendez-vous',
    'SETTINGS.ROLE.GROUP.RESOURCES': 'Ressources',
    'SETTINGS.ROLE.GROUP.CONFIGURATION': 'Configuration',
};
