<template>
<div class='b-page-container'>
    <div class='b-page-title
                b-page-title--base'>
        <h1 v-if='companyData'>
            {{ companyData.title }}
        </h1>
        <FwButton
            size='little'
            :to='{ name: "CompanyCalendar", params: { id: $route.params.id } }'
            borderRadiusType='tiny-border'>
            {{ $t('BUTTON.SEE_CALENDAR') }}
        </FwButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else class='b-consult-card__wrapper'>
        <div class='b-consult-card__outer'>
            <div class='b-consult-card'>
                <div class='b-consult-card__title'>
                    <div>
                        {{ $t('HEADER.INFORMATION') }}
                    </div>
                    <router-link
                        :to='`/consult/companies/edit/${$route.params.id}`'>
                        <FwIcon
                            class='h-pointer'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'>
                        </FwIcon>
                    </router-link>
                </div>
                <div class='b-consult-card__inner b-consult-card__scroll-inner'>
                    <ConsultCard
                        v-for='information in informationData'
                        :key='information.title'
                        :information='information'>
                    </ConsultCard>
                    <div>
                        <div class='h-mt-40 h-flex h-flex-space-between'>
                            <div v-if='companyAddressData'
                                 class='b-consult-card__address h-fw-700'>
                                {{ $t(companyAddressData.title) }}
                            </div>
                            <FwIcon
                                v-if='!companyData.is_address_confirmed'
                                class='h-pointer'
                                icon='edit-settings'
                                size='25'
                                color='#BEC7D4'
                                @click.native='goToCompanyAddress'>
                            </FwIcon>
                        </div>
                        <div v-if='companyAddressData && companyAddressData.aside' class='b-consult-card__sub-aside'>
                            {{ companyAddressData.aside }}
                        </div>
                    </div>
                    <div v-if='workerStoreUpdateData'>
                        <div class='h-flex h-flex-space-between h-flex-center h-mt-30'>
                            <div class='h-flex h-flex-center'>
                                <FwIcon
                                    icon='reccurance-icon'
                                    size='20'
                                    color='#BEC7D4'>
                                </FwIcon>
                                <span class='h-ml-10 h-fw-500 h-font-14'>
                                    {{ $t('UPDATE.TITLE.POPUP.SCHEDULED.UPDATE') }}
                                </span>
                            </div>
                            <FwIcon
                                class='h-pointer'
                                icon='bucket-edit'
                                size='20'
                                color='#BEC7D4'
                                @click.native='confirmPopup = true'>
                            </FwIcon>
                        </div>
                        <p class='h-mt-5 h-font-12'>
                            {{ $t('UPDATE.ADDRESS.DESCRIPTION') }} "{{ workerStoreUpdateData.new_data.address }}" {{ $t('UPDATE.ADDRESS.ON') }} {{ updateDate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class='b-consult-card h-mt-20'>
                <ClosuresCalendar
                    :id='$route.params.id'
                    :readonly='!canManageCompanyClosure'
                    type='company'>
                </ClosuresCalendar>
            </div>
        </div>
        <div class='b-consult-card'>
            <div class='b-consult-card__title'>
                {{ $tc('LABEL.TECHNICIANS.PLURAL', companyData.technicians.length) }}
            </div>
            <div class='h-mt-20 h-pb-30'>
                <div v-if='companyData.technicians.length !== 0'
                     class='b-consult-card__scroll-inner'>
                    <div
                        v-for='worker in companyData.technicians'
                        :key='worker.id'
                        class='b-consult-card__worker-wrapper'
                        @click='setWorkerData(worker)'>
                        <WorkerAvatar
                            :worker='worker'
                            nameClass='b-consult-card__worker-name'
                            size='small'
                            showAsideData
                            align='right'>
                        </WorkerAvatar>
                        <div class='b-consult-card__worker-arrow'>
                            <FwIcon
                                class='h-mh-5'
                                icon='arrow-right'
                                size='16'
                                color='#27dbbd'>
                            </FwIcon>
                        </div>
                    </div>
                </div>
                <div v-else class='h-width-100p'>
                    <h2 class='h-text-center'>
                        {{ $t('WORKER.NO.WORKER') }}
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`UPDATE.TITLE.POPUP.CANCEL`)'
        :subMessage='$t(`UPDATE.TITLE.POPUP.CANCEL.QUESTION`)'
        :buttonText='`UPDATE.TITLE.POPUP.CANCEL.YES`'
        :cancelButtonText='`UPDATE.TITLE.POPUP.CANCEL.BACK`'
        @deleteItem='cancelUpdate'
        @close='confirmPopup = false'>
    </DeletePopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { ClosuresCalendar } from '@/components/common/ClosuresCalendar';
import { CompanyInformationDataType, CompanyData } from '@/types/Companies';
import { CategoryLocationSkillNameType, CategorySkillNameType, UserGeoSitesType } from '@/types/Appointment';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { companyDataProps } from '@/helpers/company';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { getPrettyStringDate } from '@/helpers/dates';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { LOCATION_SKILLS_SEARCH_NAMES } from '@/helpers/appointment';
import TranslateMixin from '@/mixins/TranslateMixin';
import { WorkerListType } from '@/types/Workers';
import { User } from '@/types/User';

const WorkersPageStore = namespace('WorkersPageStore');

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
        DeletePopup,
        InformationPopup,
        ClosuresCalendar,
    },
})
export default class ConsultCompanyPage extends Mixins(TranslateMixin) {
    @WorkersPageStore.Mutation('setWorkersCardsData') setWorkersCardsData!: (workers: Array<WorkerListType>) => void;

    informationData: Array<CompanyInformationDataType> = [];
    companyData: CompanyData = companyDataProps;
    companyAddressData: CompanyInformationDataType | null = null;
    loading: boolean = true;
    confirmPopup: boolean = false;
    isPreventPopupShow: boolean = false;

    get workerStoreUpdateData(): any {
        return this.$store.state.WorkerPageStore.companyUpdateData;
    }

    get updateDate() {
        if (this.workerStoreUpdateData) {
            return getPrettyStringDate(new Date(this.workerStoreUpdateData.dt_execute_at), this.$i18n, true);
        }

        return null;
    }

    get updateId() {
        return this.workerStoreUpdateData ? this.workerStoreUpdateData.id : ``;
    }

    get canManageCompanyClosure(): boolean {
        const user: User = this.user();
        return !!(user.manager_profile && user.manager_profile.role && user.manager_profile.role.company_rights && user.manager_profile.role.company_rights.update);
    }

    goToCompanyAddress() {
        if (this.workerStoreUpdateData) {
            this.isPreventPopupShow = true;
        } else {
            this.$router.push({ path: `/consult/companies/address/${this.$route.params.id}` });
        }
    }
    async goCompanyData() {
        try {
            const data = await AppointmentWebApi.getSocietyItemData(this.$route.params.id);
            this.loading = false;
            this.companyData = data.company;
            this.informationData = this.prepareData(this.companyData);
            this.setWorkersCardsData(data.company.technicians as any);
            this.$store.commit('PLAN_COMPANY', data.company);
            this.$store.dispatch('storeCompanyUpdateData', data.company.update_request);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }
    setWorkerData(worker: any) {
        this.$store.dispatch('storeWorkerData', worker);
        this.$router.push({ path: `/consult/technicians/${worker.id}` });
    }

    prepareData(company: CompanyData): Array<CompanyInformationDataType> {
        const companyDataArray: Array<CompanyInformationDataType> = [];
        if (company.address) {
            this.companyAddressData = {
                title: this.$i18n.t('COMPANIES.FORM.INPUT.ADDRESS.LABEL'),
                aside: company.address,
            };
        }
        if (company.siret) {
            companyDataArray.push({
                title: 'SIRET',
                aside: company.siret,
            });
        }
        if (company.siren) {
            companyDataArray.push({
                title: 'SIREN',
                aside: company.siren,
            });
        }
        if (company.insee_code) {
            companyDataArray.push({
                title: 'INSEE',
                aside: company.insee_code,
            });
        }
        if (company.phone) {
            companyDataArray.push({
                title: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
                aside: company.phone,
            });
        }
        if (company.contact_email) {
            companyDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: company.contact_email,
            });
        }
        if (company.contact_1 || company.contact_2) {
            if (company.contact_1) {
                companyDataArray.push({
                    title: this.$i18n.t('Contacts'),
                    aside: `${company.contact_1}`,
                });
            }
            if (company.contact_2) {
                companyDataArray.push({
                    aside: `${company.contact_2}`,
                    isNoPadding: true,
                });
            }
        }
        if (company.geo_sites && company.geo_sites.length) {
            companyDataArray.push({
                title: this.$i18n.t('LABEL.GEOGRAFIC.ZONE'),
                aside: `(${company.geo_sites.length}) ${company.geo_sites.map((site: UserGeoSitesType) => site.name).join(', ')}`,
            });
        }
        if (company.skills && company.skills.length) {
            const locationSkills = company.skills.filter(
                (item: CategoryLocationSkillNameType | CategorySkillNameType) => LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
            );
            const skills = company.skills.filter(
                (item: CategoryLocationSkillNameType | CategorySkillNameType) => !LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
            );
            if (skills && skills.length) {
                companyDataArray.push({
                    title: this.$i18n.t('LABEL.SKILLS'),
                    // @ts-ignore-next-line
                    aside: `(${skills.length}) ${skills.map((skill: CategorySkillNameType) => skill[this.labelKey]).join(', ')}`,
                });
            }
            if (locationSkills && locationSkills.length) {
                companyDataArray.push({
                    title: this.$i18n.t('LABEL.APPOINTMENT.METHODS'),
                    // @ts-ignore-next-line
                    aside: `(${locationSkills.length}) ${locationSkills.map((skill: CategorySkillNameType) => skill[this.labelKey]).join(', ')}`,
                });
            }
        }
        return companyDataArray;
    }

    async cancelUpdate() {
        try {
            await WorkersWebApi.deleteTechnicianUpdateData(this.updateId);
            this.confirmPopup = false;
            this.$store.dispatch('storeCompanyUpdateData', null);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CANCELED', 'success');
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async created() {
        await this.goCompanyData();
    }
}
</script>

<style lang='sass'>
.b-consult-card
    flex: 1 0
    margin: 0 15px
    background-color: #fff
    border-radius: 20px

    &__outer
        flex: 1 0
        padding: 0 15px

        .b-consult-card
            margin: 0

        .b-consult-card
            &:not(:first-of-type)
                margin-top: 20px

    &__address
        font-size: 24px

    @include media('<=phone')
        margin: 0 0 20px

    &--small
        flex: 0 0 320px

    &--transparent
        background-color: transparent

    &__title
        font-size: 24px
        color: $dark-blue
        font-weight: 600
        padding: 40px 40px 0
        display: flex
        justify-content: space-between

    &__wrapper
        margin: 0 -15px
        display: flex
        justify-content: space-between

    &__worker-wrapper
        display: flex
        padding: 10px 40px
        align-items: center
        justify-content: space-between

        &:hover
            cursor: pointer
            background-color: $light-gray

            .b-consult-card__worker-arrow
                transform: translate(5px, 0)

    &__worker-name
        flex: 1
        padding: 0
        font-size: 18px
        font-weight: 500
        color: $dark-blue

    &__inner
        padding: 0 40px 40px

    &__scroll-inner
        max-height: 400px
        overflow: auto

    &__worker-arrow
        transform: translate(0, 0)
        transition: transform .4s ease-in-out

.b-page-title
    padding: 20px 0 10px
    display: flex
    align-items: center
    justify-content: space-between

@include media('<=phone')
    .b-page-title
        flex-wrap: wrap
        padding: 20px 15px

        & > div:not(:first-of-type)
            margin-left: 15px

    .b-consult-cards
        width: 100%

        &__wrapper
            padding: 0 10px
            margin: 0 0 70px

    .b-consult-card
        &__wrapper
            margin: 0 0 70px
            flex-flow: column
</style>
