<template>
<div class='h-mt'>
    <form class='b-add-company__form--edit'
          @keyup.enter='onSubmit'>
        <div class='b-add-company__form--edit__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form--edit__input'
                :fieldHasError='fieldHasError(COMPANY_INFORMATION_ADDRESS)'
                labelType='label-medium'
                :title='`${formTitlesMapping[COMPANY_INFORMATION_ADDRESS]}*`'>
                <FwInput
                    :value='address'
                    type='text'
                    name='text'
                    :disabled='disableInputsByAddres'
                    :error='getFieldErrorText(COMPANY_INFORMATION_ADDRESS)'
                    inputStyle='white-bigger'
                    placeholder='ex: 130, rue Saint-Maur 75011 Paris'
                    @update:value='value => address = value'
                    @input='clearServerErrorsBase(COMPANY_INFORMATION_ADDRESS)'
                    @blur='addCurrentInputToValidateArray(COMPANY_INFORMATION_ADDRESS)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div v-if='companyData.is_address_confirmed' class='b-add-company__form--edit__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form--edit__input'
                labelType='label-medium'
                :title='`${$t(`UPDATE.ADDRESS.WHEN.TITLE`)}*`'>
                <div class='b-working-hours__date-picker h-mt-15'>
                    <FwDatepicker
                        v-model='dateFrom'
                        name='datepicker'
                        :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                        :propsRange='false'
                        :propsLang='`${$i18n.locale}`'
                        :selectMode='false'
                        pickerStyle='static'
                        propsType='date'
                        propsFormat='DD/MM/YYYY'
                        openOnTop
                        :disabledDate='disabledDateByObject'
                        propsInputClass='b-add-reserved-slot-form__datepicker'>
                        <template #icon>
                            <FwIcon
                                class='h-flex'
                                icon='calendar'
                                size='16'
                                color='#203f6a'>
                            </FwIcon>
                        </template>
                    </FwDatepicker>
                </div>
            </FwFormInput>
        </div>
    </form>
    <div class='b-form-hint__wrapper'>
        <div class='b-form-hint'>
            <p class='h-mt-5'>
                * {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
            </p>
        </div>
    </div>

    <div class='h-flex h-flex-center h-flex-dir-column'>
        <FwButton
            v-if='!workerStoreUpdateData'
            class='h-mt-20'
            :disabled='(!isFormDisabled || isRequestSending || (!dateFrom && companyData.is_address_confirmed))'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-else class='h-mt-40 h-mb-0 h-fw-500 h-font-16 h-opacity-0_5'>
            {{ $t('UPDATE.ADDRESS.ALREADY.UPDATED') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Emit, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ValidationMixin from '@/mixins/validation';
import DateMixin from '@/mixins/dateMixin';
import { CompanyData } from '@/types/Companies';

import {
    COMPANY_INFORMATION_ADDRESS,
} from '@/helpers/company';

@Component({
    validators: {
        [COMPANY_INFORMATION_ADDRESS](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class EditAddressForm extends Mixins(ValidationMixin, DateMixin) {
    @Prop({ type: Object, default: null }) readonly companyData!: CompanyData;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    [COMPANY_INFORMATION_ADDRESS]: string = '';
    phoneCode: string = '+33';
    dateFrom: string = '';
    currentDate: Date = this.getDayDateAtNight();
    COMPANY_INFORMATION_ADDRESS: string = COMPANY_INFORMATION_ADDRESS;

    formTitlesMapping: { [key: string]: TranslateResult | string } = {};

    serverErrors: serverErrors = {
        [COMPANY_INFORMATION_ADDRESS]: null,
    };
    get isFormDisabled() {
        return this[COMPANY_INFORMATION_ADDRESS];
    }
    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }
    get workerStoreUpdateData(): any {
        return this.$store.state.WorkerPageStore.companyUpdateData;
    }
    updateFormByServerValues() {
        if (this.companyData.address) {
            this[COMPANY_INFORMATION_ADDRESS] = this.companyData.address;
        }
    }

    created() {
        this.formTitlesMapping = {
            [COMPANY_INFORMATION_ADDRESS]: this.$i18n.t('COMPANIES.FORM.INPUT.ADDRESS.LABEL'),
        };
        if (this.companyData) {
            this.updateFormByServerValues();
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            address: this[COMPANY_INFORMATION_ADDRESS],
            dateFrom: this.dateFrom,
        };
    }
}
</script>

<style lang='sass'>
.b-add-company__form--edit
    display: flex
    flex-wrap: wrap
    margin: 0 -15px
    .mx-datepicker
        height: 60px
        background-color: #fff
        .mx-datepicker-popup
            background-color: #fff!important
    .b-working-hours__date-picker
        width: 280px
        border-radius: 10px
    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-company__form--edit__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: $cancel-red
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
