export default {
    state: {
        showAddUserPopup: false,
        showEditPopup: false,
        showFinalePopup: false,
        rolesState: false,
    },
    mutations: {
        STATE_ROLES(state:any, data:any) {
            state.rolesState = data;
        },
        STATE_ADD_USER_POPUP(state:any, data:any) {
            state.showAddUserPopup = data;
        },
        STATE_EDIT_POPUP(state:any, data:any) {
            state.showEditPopup = data;
        },
        STATE_FINALE_POPUP(state:any, data:any) {
            state.showFinalePopup = data;
        },
    },
    actions: {
        storeRoles({ commit, state }: any, payload: object) {
            commit('STATE_ROLES', payload);
        },
        storeAddUserPopup({ commit, state }: any, payload: object) {
            commit('STATE_ADD_USER_POPUP', payload);
        },
        storeEditPopup({ commit, state }: any, payload: object) {
            commit('STATE_EDIT_POPUP', payload);
        },
        storeFinalePopup({ commit, state }: any, payload: object) {
            commit('STATE_FINALE_POPUP', payload);
        },
    },
};
