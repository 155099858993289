import axios from 'axios';
import {
    CalendarSyncResponseType,
    CalendarSyncType,
    CalendarViewSettingsResponseType,
    PayloadAvailabilitiesDataType,
} from '@/types/Calendar';
import { AvailabilityType } from '@/types/Events/Workers';
import WebApi from '../WebApi';

interface companyData {
    company_name: string,
    client_surname: string,
    client_name: string,
    contract_number: string,
    location_value: string,
    company: string,
}

class CalendarApi extends WebApi {
    async getAvailabilitiesData({ appointment_id, from, to, company_id }: PayloadAvailabilitiesDataType): Promise<Array<AvailabilityType>> {
        const urlParams = `?pagination[per_page]=100`;
        const { data } = await axios.get(
            `${this.urlPrefix}/availabilities${urlParams}`,
            {
                params: {
                    appointment_id,
                    from,
                    to,
                    company_id,
                },
            }
        );
        if (Array.isArray(data)) {
            return data;
        }
        return data.availabilities;
    }

    async getMonthTimeslotDates(
        { appointment_id, from, to, company_id }: PayloadAvailabilitiesDataType
    ): Promise<Array<string>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/availabilities/timeslot_dates`,
            {
                params: {
                    appointment_id,
                    from,
                    to,
                    company_id,
                },
            }
        );
        return Array.isArray(data) ? data : [];
    }

    async sendData({ company_name, client_surname,
        client_name, contract_number,
        location_value, company }: companyData) {
        return axios.post(
            `${this.urlPrefix}/some_endpoint`,
            {
                'user': {
                    company_name,
                    client_surname,
                    client_name,
                    contract_number,
                    location_value,
                    company,
                },
            },
        );
    }

    async getCalendarViewData(): Promise<CalendarViewSettingsResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/calendar_view_settings`);
        return data;
    }

    async getRemoteCredentials(id: string): Promise<CalendarSyncResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/remote_credentials?user_id=${id}`);
        return data;
    }
}

const calendarApi = new CalendarApi();

export {
    calendarApi as CalendarApi,
};
