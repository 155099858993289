import { informationDataType } from '@/types/Consult';
import { User, workerDataType } from '@/types/User';
import {
    COLOR_ORANGE,
    COLOR_LIGHT_BLUE,
    COLOR_BLUE,
    COLOR_DARK_BLUE,
    COLOR_PINK,
    COLOR_LIGHT_GREEN,
    COLOR_RED,
    COLOR_BROWN,
} from '@/helpers/colors';
import { WorkersInformationFormEditType } from '@/types/Workers';
import {
    WORKERS_INFORMATION_FIRST_NAME,
    WORKERS_INFORMATION_LAST_NAME,
    WORKERS_INFORMATION_PHONE,
    WORKERS_INFORMATION_COLOR,
    WORKERS_INFORMATION_EMAIL,
    WORKERS_INFORMATION_AVATAR,
    WORKERS_INFORMATION_ROLE,
} from '@/helpers/workers';

const informationData: Array<informationDataType> = [
    {
        title: 'LABEL.COMPANY',
        aside: 'Kheops Securité',
    },
    {
        title: 'ID',
        aside: '2398562',
    },
    {
        title: 'LABEL.QUALIFICATIONS',
        tags: [
            'RMB',
            'A',
        ],
    },
    {
        title: 'LABEL.PHONE',
        aside: '0927384718',
    },
    {
        title: 'LABEL.EMAIL',
        aside: 'addresse@mail.com',
    },
    {
        title: 'LABEL.COLOR',
        color: '#AA22BC',
    },
];

const user: User = {
    'avatar_url': 'https://www.stickees.com/files/avatars/male-avatars/1698-david-sticker.png',
    'id': 3,
    'authentication_token': '3Q1dLbzCeFvy4CfqsrCv',
    'locale': 'fr',
    'name': 'test',
    'first_name': 'Vlad',
    'last_name': 'Kondratenko',
    'address': 'Some address 32, 11',
    'description': 'It is desciption',
    'email': 'vlad@vlad.com',
    'phone': '+33872138439',
    'account_id': 'd3ac4d22-0ebb-4a60-ac0d-bc6016e9dc28',
};

const worker: workerDataType = {
    avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1698-david-sticker.png',
    name: 'Ashish Asharaful',
    href: '/consult/technicians/1',
    role_name: 'manager',
    kind: '',
    position: 'Technicien 123',
    color: COLOR_ORANGE,
};

const workersData: Array<workerDataType> = [
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1698-david-sticker.png',
        name: 'John Doe',
        href: '/consult/technicians/1',
        role_name: 'manager',
        kind: '',
        color: COLOR_ORANGE,
    },
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1697-andrew-sticker.png',
        name: 'Rim Chong-Suk',
        href: '/consult/technicians/2',
        role_name: 'manager',
        kind: '',
        color: COLOR_LIGHT_BLUE,
    },
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1699-matthew-sticker.png',
        name: 'Jordan Ntolo',
        href: '/consult/technicians/3',
        role_name: 'manager',
        kind: '',
        color: COLOR_PINK,
    },
    {
        avatar_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAzZUT7iY1GzybZt3WXYLW4h_U9A47zttcMb_QhDgb1C_nT908&s',
        name: 'Abayo Landu',
        href: '/consult/technicians/4',
        role_name: 'manager',
        kind: '',
        color: COLOR_BLUE,
    },
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1697-andrew-sticker.png',
        name: 'Jason Bailey',
        href: '/consult/technicians/5',
        role_name: 'manager',
        kind: '',
        color: COLOR_RED,
    },
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1699-matthew-sticker.png',
        name: 'Kay Totleben',
        href: '/consult/technicians/6',
        role_name: 'manager',
        kind: '',
        color: COLOR_DARK_BLUE,
    },
    {
        avatar_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAzZUT7iY1GzybZt3WXYLW4h_U9A47zttcMb_QhDgb1C_nT908&s',
        name: 'Abayo Landu',
        href: '/consult/technicians/4',
        role_name: 'manager',
        kind: '',
        color: COLOR_LIGHT_GREEN,
    },
    {
        avatar_url: 'https://www.stickees.com/files/avatars/male-avatars/1697-andrew-sticker.png',
        name: 'Jason Bailey',
        href: '/consult/technicians/5',
        role_name: 'manager',
        kind: '',
        color: COLOR_BROWN,
    },
];

const WORKER_FORM_DATA: WorkersInformationFormEditType = {
    [WORKERS_INFORMATION_FIRST_NAME]: 'Sukhbirpal',
    [WORKERS_INFORMATION_LAST_NAME]: 'Dhalan',
    [WORKERS_INFORMATION_PHONE]: '+33 6 20 45 30 79',
    [WORKERS_INFORMATION_COLOR]: COLOR_LIGHT_GREEN,
    [WORKERS_INFORMATION_EMAIL]: 'john.doe@gmail.com',
    [WORKERS_INFORMATION_ROLE]: 'Some role',
    [WORKERS_INFORMATION_AVATAR]: 'https://www.stickees.com/files/avatars/male-avatars/1697-andrew-sticker.png',
};

export {
    informationData,
    user,
    worker,
    workersData,
    WORKER_FORM_DATA,
};
