export default {
    'ERRORS.ONLY.ONE.SLOT': 'Sorry! You cannot create more than one unavailability/reserved slot in the same day.',
    'ERRORS.PERMISSION': 'Sorry you do not have the permission to access',
    'ERRORS.LOGIN.PASSWORD': 'Name or password is incorrect. Please, try again.',
    'ERRORS.404': 'Record not found',
    'ERRORS.401': 'Unauthorized',
    'ERRORS.403': 'You do not have access rights',
    'ERRORS.500': 'Internal server error',
    'ERRORS.SERVER.ISSUE': 'Sorry, server error! Please, try it later',

    'ERRORS.CALENDAR.NO.DATA': 'Calendar booking data is not exists',

    'NOTIFICATION.BAR.SUCCESS.CREATED': 'Successfully created',
    'NOTIFICATION.BAR.SUCCESS.UPDATED': 'Successfully updated',
    'NOTIFICATION.BAR.SUCCESS.BAR.REMOVED': 'Successfully removed',
    'NOTIFICATION.BAR.SUCCESS.CONFIRMED': 'Successfully confirmed',
    'NOTIFICATION.BAR.SUCCESS.CANCELED': 'Successfully canceled',
    'ERROR.IMAGE.FORMAT': 'This format is not supported. Format supported are : PNG, JPEG',
    'ERROR.IMAGE.MAXLIMIT': 'The size of this file is too big. The maximum size supported is 3mb',
};
