import { RawLocation } from 'vue-router';
import { pickBy } from 'ramda';
import { FullPageSpinLoader } from '@/components/global/FullPageSpinLoader';
import { LazyLoadViewType } from '@/types/router';

function updateUrlGetParams(url: string, params: { [key: string]: string | number}) {
    let newUrl;
    if (url.startsWith(`http`)) {
        newUrl = new URL(url);
    } else {
        newUrl = new URL(url, new URL(window.location.origin));
    }

    const { searchParams } = newUrl;
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (params[key] !== null) {
            searchParams.set(key, String(params[key]));
        }
    }
    return newUrl.href;
}

function lazyLoadView(relativePath: string): LazyLoadViewType {
    return () => ({
        component: import(`@/views/${relativePath}.vue`),
        loading: {
            render: (h: any) => h(FullPageSpinLoader, { props: { isActive: true }}),
        },
    });
}

function updateUrlPageAndQuery(url: string, page?: number, query?: { [key: string]: string | boolean | number | Array<string> } | undefined): RawLocation {
    const pagePostFix = !page || page === 1 ? '' : `/${page}`;
    return { path: `${url}${pagePostFix}`, query: query ? pickBy(param => !!param, query) : query };
}

export {
    lazyLoadView,
    updateUrlGetParams,
    updateUrlPageAndQuery,
};
