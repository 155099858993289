import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { WorkerListType } from '@/types/Workers';

@Module({
    namespaced: true,
})
class WorkersPageStore extends VuexModule {
    workersCardsData: Array<WorkerListType> = [];

    @Mutation
    setWorkersCardsData(workers: Array<WorkerListType>) {
        this.workersCardsData = workers;
    }

    get confirmedUsers(): Array<WorkerListType> {
        return this.workersCardsData.filter(worker => worker.active && worker.kind === `technician`);
    }

    get unconfirmedUsers(): Array<WorkerListType> {
        return this.workersCardsData.filter(worker => !worker.active && worker.kind === `technician`);
    }

    get workerCardFromList(): (id: string) => WorkerListType | null {
        return id => this.workersCardsData.find(worker => worker.id === id) || null;
    }
}

export default WorkersPageStore;
