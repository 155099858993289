<template>
<transition :style='getTransitionStyle'
            name='spinner'>
    <div v-show='isActive'
         :style='getTransitionStyle'
         :class='getSpinnerClass'>
        <div
            :class='getSpinnerInnerClass'
            :style='getSpinnerInnerStyle'
            class='b-bouncing-loader'>
            <div v-for='(i, index) in 3'
                 :key='index'
                 :style='styles'></div>
        </div>
    </div>
</transition>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SpinLoader extends Vue {
    @Prop(Boolean) readonly isActive!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAbsolute!: boolean;
    @Prop({ type: Boolean, default: false }) readonly addRelativeWrapper!: boolean;
    @Prop({ type: Boolean, default: false }) readonly smallPadding!: boolean;
    @Prop({ type: Number, default: 0 }) readonly delay!: number;
    @Prop({ type: Number, default: null }) readonly size!: number | null;
    @Prop(String) readonly className!: string;

    get getTransitionStyle() {
        return {
            transitionDelay: this.isActive ? `${this.delay}s` : null,
        };
    }

    get getSpinnerInnerClass() {
        return [
            {
                'spinner-absolute': this.isAbsolute,
            },
            this.className,
        ];
    }

    get getSpinnerClass() {
        return {
            'b-spin-loader--small-padding': this.smallPadding,
            'b-bouncing-loader-wrapper': this.addRelativeWrapper,
        };
    }

    get getSpinnerInnerStyle() {
        return {
            bottom: this.isAbsoluteSpinnerWithOutWrapper ? `auto` : `0`,
        };
    }

    get isAbsoluteSpinnerWithOutWrapper() {
        return !this.addRelativeWrapper && this.isAbsolute;
    }

    get styles() {
        if (!this.size) {
            return null;
        }
        return {
            width: `${this.size}rem`,
            height: `${this.size}rem`,
            margin: `${this.size * 3}rem ${this.size / 3}rem ${this.size * 2}rem`,
        };
    }
};
</script>

<style lang='sass'>
.spinner-enter-active
    transition: opacity .22s, transform .22s

.spinner-leave-active
    transition: opacity 0s, transform 0s

.spinner-enter
    opacity: 0
    transform: scale(0.8)

.spinner-leave-to
    opacity: 0
    transform: scale(0.8)

.spinner-absolute
    position: absolute
    left: 0
    right: 0
    text-align: center
    top: 0
    bottom: 0
    margin-left: auto
    margin-right: auto

.b-bouncing-loader-wrapper
    position: relative
    height: 200px
    display: flex
    align-items: center
    justify-content: center
    padding-bottom: 18px

    @include media('<=phone')
        height: 150px

.b-bouncing-loader
    display: flex
    justify-content: center

    &:not(.spinner-absolute)
        padding-top: 18px

    & > div
        width: 0.9rem
        height: 0.9rem
        margin: 3rem 0.15rem 2rem
        background: #3db83a
        border-radius: 50%
        animation: bouncing-loader 0.6s infinite alternate

        @include media('<=phone')
            width: 0.7rem
            height: 0.7rem
            margin: 0.15rem 0.2rem

        &:nth-child(1)
            background: #F7D000

        &:nth-child(2)
            animation-delay: 0.2s
            background: #3db83a

        &:nth-child(3)
            animation-delay: 0.4s
            background: #EA2900

@keyframes bouncing-loader
    from
        opacity: 0.9
        transform: translateY(0)

    to
        opacity: 0.45
        transform: translateY(-100%)

        @include media('<=phone')
           transform: translateY(-100%)

.b-spin-loader--small-padding
    .b-bouncing-loader
        display: flex
        justify-content: center

        & > div
            margin: 0.15rem 0.2rem
</style>

