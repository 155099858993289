<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :max-width='width'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column  h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ headerText }}
                </h2>
                <p class='h-mt-10 h-mb-20 h-text-center h-fw-500'>
                    {{ subMessage }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-flex-dir-column'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-10'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='deleteItem'>
                    {{ $t(buttonText) }}
                </FwButton>
                <div class='delete-popup__delete--btn h-mt-20'
                     @click='$emit("close")'>
                    {{ $t(cancelButtonText) }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class DeletePopup extends Vue {
    @Prop({ type: String, default: null }) readonly headerText!: string;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: 'SETTINGS.ROLE.DELETE.BUTTON.CONFIRM' }) readonly buttonText!: string;
    @Prop({ type: String, default: 'SETTINGS.ROLE.DELETE.BUTTON.CANCEL' }) readonly cancelButtonText!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: '400px' }) readonly width!: string;

    @Emit('deleteItem')
    deleteItem() {}
}
</script>

<style lang="sass">
    .delete-popup
        &__delete--btn
            text-transform: uppercase
            margin-top: 20px
            font-size: 12px
            color: $cancel-red
            font-weight: bold
            cursor: pointer
            &:hover
                opacity: 0.7
</style>
