<template>
<div class='b-reserved-slot-card'>
    <div class='b-reserved-slot-card__title'>
        {{ $t('CALENDAR.POPUP.RESERVED.SLOT.TITLE') }}
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ReservedSlotsCard extends Vue {}
</script>

<style lang='sass'>
.b-reserved-slot-card
    background-color: #f0f2f5
    border-radius: 5px
    color: #bec7d3
    font-size: 12px
    font-weight: 500
    &__title
        text-transform: uppercase
        text-align: center
        padding-top: 5px
</style>
