const PLAN_AN_APPOINTMENT_CALENDAR: string = 'PlanAnAppointmentCalendar';
const COMPANY_CALENDAR: string = 'CompanyCalendar';
const WORKERS_CALENDAR: string = 'WorkersCalendar';

const CALENDAR_TYPES: Array<string> = [PLAN_AN_APPOINTMENT_CALENDAR, COMPANY_CALENDAR, WORKERS_CALENDAR];

export {
    PLAN_AN_APPOINTMENT_CALENDAR,
    COMPANY_CALENDAR,
    CALENDAR_TYPES,
    WORKERS_CALENDAR,
};
