<template>
<router-link
    :to='companyUrl'
    class='b-company-card__wrapper'>
    <div class='b-company-card'>
        <div class='b-company-card__title'>
            {{ company.title || company.company_title }}
        </div>

        <div class='b-company-card__workers-wrapper'>
            <div v-for='worker in workersPart'
                 class='b-company-card__worker-inner'>
                <WorkerAvatar
                    :worker='worker'
                    class='b-company-card__worker'
                    :avatarSize='23'
                    :hideInfo='true'
                    align='right'>
                </WorkerAvatar>
            </div>
            <div v-if='company.technicians'
                 class='b-company-card__workers-title h-font-14'
                 :class='{ "b-company-card__workers-title--without": !company.technicians.length }'>
                <span>
                    {{ company.technicians.length }}
                </span>
                <span v-if='company.technicians.length !== 0' class='h-ml-5'>
                    {{ $tc('CHOOSE_MANAGE.COMMON.WORKER.PLURAL', company.technicians.length) }}
                </span>
                <span v-else class='h-ml-5'>
                    {{ $tc('CHOOSE_MANAGE.COMMON.NO.WORKER.PLURAL', company.technicians.length) }}
                </span>
            </div>
        </div>
    </div>
</router-link>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CompanyCardWorkerType, CompanyData } from '@/types/Companies';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

const MAX_WORKERS_QUANTITY = 5;
@Component({
    components: {
        WorkerAvatar,
    },
})
export default class CompanyCard extends Vue {
    @Prop({ type: Object, default: null }) readonly company!: CompanyData;

    get workersPart(): CompanyData {
        let val = null;
        if (this.company && this.company.technicians) {
            val = (this.company.technicians as any).slice(0, MAX_WORKERS_QUANTITY);
        }
        return val;
    }

    get companyUrl(): string {
        return `/consult/companies/${this.company.id || this.company.company_id}`;
    }

    getWorkerAvatarBg(worker: CompanyCardWorkerType, index: number) {
        if (worker && !worker.avatar) {
            return {
                backgroundImage: `url(${worker.avatar})`,
                zIndex: index,
            };
        }
        return null;
    }
}
</script>

<style lang='sass'>
$worker-size: 25px

.b-company-card
    display: flex
    flex-direction: column

    &__wrapper
        max-width: 455px
        background-color: #fff
        flex: 0 0 50%
        padding: 20px
        border-radius: 20px
        box-shadow: $big-box-shadow
        min-height: 94px

    &__title
        font-size: 16px
        font-weight: 600
        color: $alt-blue

    &__workers-wrapper
        display: flex
        margin-top: 5px
        margin-bottom: -10px
        align-items: center

    &__worker
        width: $worker-size
        height: $worker-size
        border-radius: 50%
        border: 1px solid #FFFFFF
        position: relative
        background-size: cover
        background-position: center
        background-color: lighten($main-green, 30)

    &__worker-inner
        width: calc($worker-size / 1.4)

    &__workers-title
        display: flex
        align-items: center
        min-height: 40px
        font-weight: 300
        color: $main-gray
        margin-left: 34px
        margin-top: 3px
        text-transform: uppercase

        &--without
            margin-left: 0

@include media('<tablet')
    .b-company-card
        width: 100%
        &__wrapper
            margin: 5px 0

</style>
