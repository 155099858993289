<template>
<div class='b-add-company__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ companyData ? companyData.title : $t('LOADING.TEXT') }}
        </h1>
    </div>
    <LocationPopup
        v-if='showLocationPopup'
        :isRequestSending='isRequestSending'
        @closeLocation='showLocationPopup = false'
        @confirmAddress='confirmAddress'>
    </LocationPopup>
    <DeleteCompanyPopup
        v-else-if='showDeletePopup'
        :isRequestSending='isRequestSending'
        :bodyText='companyData.technicians.length > 0 ?
            $t("CONSULT.DELETE.COMPANY.WORKERS") :
            $t("CONSULT.DELETE.COMPANY.SUBTITLE")'
        :technicianLength='companyData.technicians.length'
        @deleteCompany='deleteCompany'
        @closePopup='closePopup'>
    </DeleteCompanyPopup>
    <AddOrEditCompanyForm
        v-if='companyData'
        :disabledEdit='true'
        :isRequestSending='isRequestSending'
        :companyData='companyData'
        @delete='showDeletePopup = true'
        @onSubmit='sendDataToServer'>
    </AddOrEditCompanyForm>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AddOrEditCompanyForm } from '@/components/forms/add/AddOrEditCompanyForm';
import { LocationPopup } from '@/components/popups/LocationPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CompanyData, CompaniesCreateFormDataType } from '@/types/Companies';
import { DeleteCompanyPopup } from '@/components/popups/DeleteCompanyPopup';

@Component({
    components: {
        AddOrEditCompanyForm,
        LocationPopup,
        DeleteCompanyPopup,
    },
})
export default class ConsultCompanyEditPage extends Vue {
    showLocationPopup: boolean = false;
    companyData: CompanyData | null = null;
    showDeletePopup: boolean = false;
    isRequestSending: boolean = false;
    isAddressConfirmed: boolean = false;

    confirmAddress(data: CompaniesCreateFormDataType) {
        this.isAddressConfirmed = true;
        this.sendDataToServer(data);
    }

    async sendDataToServer(data: CompaniesCreateFormDataType) {
        this.isRequestSending = true;
        try {
            const company = await AppointmentWebApi.changeCompanyData(
                {
                    address: data.address as string,
                    address_confirmed: this.showLocationPopup ? this.isAddressConfirmed : data.is_address_confirmed as boolean,
                    title: data.name,
                    siret: data.siret,
                    siren: data.siren,
                    phone: data.phone,
                    insee_code: data.insee_code,
                    contact_email: data.contact_email,
                },
                this.$route.params.id
            );
            this.isRequestSending = false;
            this.$store.commit('PLAN_COMPANY', company.data.company);
            this.$router.replace({ path: `/consult/companies/${this.$route.params.id}` });
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', 'success');
            // @ts-ignore-next-line
        } catch ({ response }) {
            this.isRequestSending = false;
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async deleteCompany() {
        try {
            this.isRequestSending = true;
            const response = await AppointmentWebApi.deleteCompany(this.$route.params.id);
            this.isRequestSending = false;
            this.$router.replace({ path: '/consult/companies' });
            this.$store.commit('PLAN_COMPANY', '');
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', 'success');
            // @ts-ignore-next-line
        } catch ({ response }) {
            this.isRequestSending = false;
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    closePopup() {
        this.showDeletePopup = false;
    }

    async created() {
        if (this.$store.state.CalendarPageStore.company) {
            this.companyData = this.$store.state.CalendarPageStore.company;
        } else {
            this.loading = true;
            const data = await AppointmentWebApi.getSocietyItemData(this.$route.params.id);
            this.$store.commit('PLAN_COMPANY', data.company);
            this.companyData = data.company;
            this.loading = false;
        }
    }
}
</script>

<style lang='sass'>
.b-add-company
    &__wrapper
        width: 1000px
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%
</style>
