<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='430px'
        :width='width'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='isShowCloseCross'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column  h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ headerText }}
                </h2>
                <p v-if='bodyText'
                   :style='{"background: white; border-radius: 10px; width: 100%; padding: 15px 25px": isColorTransparent}'
                   class='h-mt-10 h-mb-20 h-text-center'>
                    {{ bodyText }}
                </p>
                <p v-if='subMessage'
                   class='h-mt-10 h-mb-20 h-text-center h-fw-500'>
                    {{ $t('WORKER.POPUP.INVITATION.CHECK') }}
                </p>
                <ul v-if='listToApprove' class='h-mv-0 h-pl-0'>
                    <li v-if='!workingHours' class='h-fw-500 h-mv-10'>
                        - {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE') }}
                        <span class='h-font-14'>
                            {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT') }}
                        </span>
                    </li>
                    <li v-if='!addressConfirm' class='h-fw-500 h-mv-10'>
                        - {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE') }}
                        <router-link :to='`/consult/companies/address/${companyId}`'>
                            <span class='h-font-14 text-underline'>
                                {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT') }}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'>
                <FwButton
                    class='h-mt-20'
                    :class='{ "h-opacity-0_5": isColorTransparent }'
                    borderRadiusType='small-border'
                    :color='isColorTransparent ? "transparent" : "primary"'
                    @click='$emit("close") && goToPage()'>
                    {{ buttonText }}
                    <FwIcon
                        v-if='isColorTransparent'
                        class='h-ml-10 h-mt-2'
                        icon='arrow-right'
                        size='12'
                        color='#213F6B'>
                    </FwIcon>
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class InformationPopup extends Vue {
    @Prop({ type: String, default: null }) readonly headerText!: string;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: null }) readonly bodyText!: string;
    @Prop({ type: String, default: null }) readonly buttonText!: string;
    @Prop({ type: String, default: null }) readonly companyId!: string;
    @Prop({ type: String, default: 'auto' }) readonly width!: string;
    @Prop({ type: Boolean, default: false }) readonly listToApprove!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isShowCloseCross!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isColorTransparent!: boolean;
    @Prop({ type: Boolean, default: false }) readonly workingHours!: boolean;
    @Prop({ type: Boolean, default: false }) readonly addressConfirm!: boolean;
    @Emit('goToPage')
    goToPage() {
        return true;
    }
}
</script>
