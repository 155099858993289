export default {
    'BUTTON.NEXT': 'Next',
    'BUTTON.SAVE': 'Save',
    'BUTTON.ADD': 'Add',
    'BUTTON.CLEAR.ALL': 'Clear All',
    'BUTTON.SEARCH': 'Search',
    'BUTTON.ADD.NEW': 'Add new',
    'BUTTON.SORT_BY': 'Sort by',
    'BUTTON.SUBMIT': 'Submit',
    'BUTTON.BACK': 'back',
    'BUTTON.CANCEL': 'Cancel',
    'BUTTON.QUIT': 'Quit',
    'BUTTON.SEE_CALENDAR': 'See calendar',
    'HEADER.PLAN': 'Plan',
    'HEADER.CONSULT': 'Consult',
    'HEADER.CANCEL': 'Cancel',
    'HEADER.SETTINGS': 'Settings',
    'HEADER.INFORMATION': 'Information',
    'LABEL.SEND': 'Send',
    'LABEL.CATEGORY': 'Category',
    'LABEL.COMPANY': 'Company',
    'LABEL.COMPANY.ADDRESS': 'Company address',
    'LABEL.COMPANIES': 'Companies',
    'LABEL.WORKERS': 'Workers',
    'LABEL.WORKERS.PLURAL': 'Worker | Workers',
    'LABEL.TECHNICIANS': 'Technicians',
    'LABEL.TECHNICIANS.PLURAL': 'Technician | Technicians',
    'LABEL.YOUR.COMPANY': 'Your account',
    'LABEL.NAME': 'Name',
    'LABEL.EXTERNAL.ID': 'External ID',
    'LABEL.CONTACT': 'Contact',
    'LABEL.LOGO': 'Logo',
    'LABEL.QUALIFICATIONS': 'Qualifications',
    'LABEL.ADDRESS': 'Address',
    'LABEL.ADDRESS.POST.CODE': 'Post code on this address does not exist, Please write post code in input or change address',
    'LABEL.ZIP.CODE': 'ZIP code',
    'LABEL.START.POINT': 'Starting point',
    'LABEL.PHONE': 'Phone',
    'LABEL.DELETE': 'DELETE',
    'LABEL.PLACEHOLDER.SEARCH': 'Search',
    'LABEL.PLACEHOLDER.CHOOSE_LANG': 'Choose language',
    'LABEL.PLACEHOLDER.SEARCH.COMPANY': 'Type the company name',
    'LABEL.SEARCH.BY': 'Search by',
    'LABEL.SEARCH.COMPANIES': 'Search companies',
    'LABEL.APPT.ADDRESS': 'by appointment address',
    'LABEL.BY.COMPANY.NAME': 'by company name',
    'LABEL.APPOINTMENT.METHOD': 'Appointment method',
    'LABEL.APPOINTMENT.METHODS': 'Appointment methods',
    'LABEL.GEOGRAFIC.ZONE': 'Geografic zones',
    'LABEL.SKILLS': 'Skills',
    'SKILLS.YOU.NEED.SELECT': 'Select skills',
    'LABEL.SELECT.COMPANY.FIRST': 'Select company first',
    'LABEL.WARNING': 'Warning',
    'LABEL.SKILLS.NEEDED': 'Skills needed',
    'LOADING.TEXT': 'LOADING...',
    'FINISH.POPUP.SUCCESS.TITLE': 'Success!',
    'FINISH.POPUP.SUCCESS.CLOSE': 'Close',
    'VALIDATION.FIELD.REQUIRED': 'This field is required',
    'VALIDATION.FIELD.EMAIL': 'Invalid email',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Must have up to 10 characters',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Must have at least 2 characters',
    'WRONG.CODE': 'Please enter a valid intervention code',
    'UPDATE.TEXT': 'Update done',

    'GLOBAL.TECHNICIAN': 'Technician',
};
