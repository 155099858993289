export default {
    'LOGIN.HEADER': 'Welcome! Please log in to access to the platform',
    'LOGIN.TXT': 'Log in',
    'LOGIN.EMAIL.LABEL': 'Your email',
    'LOGIN.PASSWORD.LABEL': 'Your password',
    'LOGIN.PASSWORD.PLACEHOLDER': 'It\'ll stay between us',
    'LOGIN.FORGET.PASSWORD': 'Forget password?',
    'LOGIN.WITH.AZURE': 'Log in with Azure AD',
    'LOGIN.WITH.MICROSOFT': 'Sign in with Microsoft',
    'LOGIN.PLACEHOLDER.EMAIL': 'address@email.com',
    'LOGIN.PLACEHOLDER.EX.EMAIL': 'ex: address@email.com',
};
