<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <CancelReasonPopup
        v-if='cancelPopupValue'
        :appointmentData='data'
        isConsultPart
        @close='removeAppointment'>
    </CancelReasonPopup>
    <FwPopup
        v-else
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        style='maxHeight: 90vh'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        class='b-over-fixed-background'
        @close='$emit("close")'>
        <template #body>
            <div class='h-text-center'>
                <h1 v-if='companyEvent'>
                    {{ companyEvent.title }}
                </h1>
                <h1 v-else>
                    {{ data.title }}
                </h1>
            </div>
            <div class='h-flex-center'>
                <FormTabs
                    v-if='isWorkersCalendar || isCompanyCalendar'
                    :appointmentData='data'
                    :isDayLessThenCurrent='isDayLessThenCurrent'
                    @updateTime='updateTime'>
                </FormTabs>
                <EventForm
                    v-else
                    formClass='b-cancel-appointment__form'
                    style='max-height: 60vh; overflow: auto'
                    iconsWrapperClass='b-cancel-appointment__form__events'
                    iconsInnerClass='h-mh-20'
                    :eventTime='eventTime'
                    :dates='dates'
                    :defaultFormValues='data'
                    :disabledForm='true'
                    :isEditMode='isEditMode'
                    submitText=''
                    :appointmentStartDate='appointmentStartDate'
                    @updateTime='updateTime'>
                </EventForm>
            </div>
        </template>
        <template v-if='isWorkersCalendar || isCompanyCalendar' #footer>
            <div class='h-pt-10 h-pb-30 h-ph-50'>
                <div class='h-flex h-flex-center'>
                    <FwButton
                        class='h-mt-10'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending || isDisabled || isDayLessThenCurrent'
                        @click='sendDataToServer'>
                        {{ $t('BUTTON.SAVE') }}
                    </FwButton>
                </div>
                <div class='b-add-branch__form__delete'
                     @click='cancelPopupValue = true'>
                    {{ $t('CANCEL.BUTTON.APPOINTMENT') }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EventForm } from '@/components/forms/event/EventForm';
import { dateAsEventTime } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { CreateEventUnavailabilityType } from '@/types/Events/Workers';
import CalendarMixin from '@/mixins/calendar';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CancelReasonPopup } from '@/components/popups/CancelReasonPopup';
import { AppointmentDataType } from '@/types/Appointment';
import { InstanceAppointmentsWithSlotsType } from '@/types/Events';
import { FormTabs } from '@/components/forms/tabs/FormTabs';

@Component({
    components: {
        EventForm,
        FormTabs,
        CancelReasonPopup,
    },
})

export default class CompanyPopup extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Object, required: true }) readonly data!: AppointmentDataType;
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: String, default: null }) readonly localEventId!: string;

    companyEvent: null | CreateEventUnavailabilityType = null;
    isEditMode: boolean = false;
    newTimeData: any = null;
    isRequestSending: boolean = false;
    cancelPopupValue: boolean = false;

    get eventTime(): string {
        return dateAsEventTime(new Date(this.data.dt_start), this.user);
    }

    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_start)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_end)),
        ];
    }

    get isDayLessThenCurrent() {
        return this.data ? new Date(this.data.dt_start as string) < new Date() : false;
    }

    get isDisabled() {
        return !this.newTimeData || this.newTimeData.dt_start === this.data.dt_start;
    }

    updateTime(data: any) {
        this.newTimeData = data;
    }

    prepareAppointmentAsEvent(appointment: AppointmentDataType): InstanceAppointmentsWithSlotsType {
        return {
            id: this.localEventId || `front_id_${appointment.id}`,
            appointment_id: appointment.id,
            dt_start: appointment.dt_start,
            dt_end: appointment.dt_end,
            title: appointment.title,
            full_day: false,
            origin: 'from_appointment',
        };
    }

    async sendDataToServer() {
        try {
            this.isRequestSending = true;
            const appointment: null | AppointmentDataType = await AppointmentWebApi.changeAppointmentTime({
                ...this.newTimeData,
            }, this.data.id);
            if (appointment) {
                this.$store.commit('CACHE_APPOINTMENT', appointment);
                this.$store.commit('UPDATE_CALENDAR_EVENT', this.prepareAppointmentAsEvent(appointment));
                this.$emit('close');
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', 'success');
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    get appointmentStartDate(): Date {
        const startDay = new Date(this.data.dt_start);
        const timeData = this.getHoursAndMinutesFromAMPM((this.formatAMPM(startDay) as string));
        return new Date(startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    removeAppointment() {
        this.$emit('close');
    }
}
</script>
<style lang='sass'>
    .b-blue-background
        display: flex
        justify-content: center
        align-items: center
        z-index: 32
</style>
