import axios from 'axios';
import {
    WorkerScheduleTimeType,
    workerTravelTimeData,
    workerTakingAppointmentData,
    WorkerScheduleUpdateType,
} from '@/types/Workers';
import {
    CategoryCompanySkillNamesResponseType, CategorySkillNameType,
} from '@/types/Appointment';
import WebApi from '../WebApi';

interface workerCreateData {
    email?: string,
    first_name?: string,
    last_name?: string,
    label?: string,
    kind?: string,
    company_id?: string,
    external_id?: string,
    role_id?: string,
    role_name?: string,
    phone?: string,
    color?: string,
    add_travel_time?: boolean,
    company_admin?: boolean,
    is_taking_appointments?: boolean,
    skill_ids?: Array<string>,
}
interface workerIdData {
    id: string | number | null,
    title?: string,
}

interface pageData {
    scope?: string,
    per_page: number,
    current_page: number,
    sort?:boolean,
}

class WorkersWebApi extends WebApi {
    async getTechniciansData(payload: pageData) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        return axios.get(
            `${this.urlPrefix}/users?scope=technician${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getTechniciansDataBySearch(payload: pageData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const userTechParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const urlParams = payload.scope ? `${userTechParams}&scope=${payload.scope}` : userTechParams;
        return axios.get(
            `${this.urlPrefix}/users?detail=${searchValue}${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getManagersData(payload: pageData) {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        return axios.get(
            `${this.urlPrefix}/managers${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getManagersDataBySearch(payload: pageData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const userTechParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const urlParams = payload.scope ? `${userTechParams}&scope=${payload.scope}` : userTechParams;
        return axios.get(
            `${this.urlPrefix}/managers?detail=${searchValue}${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getManagerData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/managers/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getWorkerData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/users/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getTechnicianCaledarData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/remote_credentials?${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async createWorker({ email, first_name, label, last_name, kind, company_id, company_admin, phone, is_taking_appointments, skill_ids }: workerCreateData) {
        return axios.post(
            `${this.urlPrefix}/users`,
            {
                'user': {
                    email,
                    first_name,
                    last_name,
                    label,
                    kind,
                    company_id,
                    company_admin,
                    phone,
                    is_taking_appointments,
                    skill_ids,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async createManager({ email, first_name, last_name, kind, role_id, role_name, phone, external_id }: workerCreateData) {
        return axios.post(
            `${this.urlPrefix}/managers`,
            {
                'manager': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    role_id,
                    role_name,
                    phone,
                    external_id,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    // eslint-disable-next-line max-len
    async editWorker({ email, first_name, last_name, kind, company_id, color, add_travel_time, company_admin, phone, is_taking_appointments, skill_ids }: workerCreateData, workerId: number | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}`,
            {
                'user': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    company_id,
                    color,
                    add_travel_time,
                    phone,
                    company_admin,
                    is_taking_appointments,
                    skill_ids,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async userToggleTravelTime({ user_id, toggle_travel_time }: workerTravelTimeData, workerId: number | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}/toggle_travel_time`,
            {
                'user': {
                    user_id,
                    toggle_travel_time,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async userToggleTakingAppointment({ booking }: workerTakingAppointmentData, workerId: number | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}/toggle_booking`,
            {
                'user': {
                    booking,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async editManager({ email, first_name, last_name, role_id, role_name, phone, external_id }: workerCreateData, workerId: number | null) {
        return axios.put(
            `${this.urlPrefix}/managers/${workerId}`,
            {
                'manager': {
                    email,
                    first_name,
                    last_name,
                    role_id,
                    role_name,
                    phone,
                    external_id,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async deleteManager({ id }: workerIdData) {
        return axios.delete(
            `${this.urlPrefix}/managers/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async deleteWorker({ id }: workerIdData) {
        return axios.delete(
            `${this.urlPrefix}/users/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getTechnicianSchedulesData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/working_schedules/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editTechnicianSchedulesData(payload: WorkerScheduleTimeType, id: workerIdData) {
        return axios.put(
            `${this.urlPrefix}/working_schedules/${id}`,
            {
                'working_schedule': payload,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async confirmTechnicianSchedulesData(payload: WorkerScheduleTimeType, currentData: Date, id: workerIdData) {
        payload.confirmed_at = currentData;
        return axios.put(
            `${this.urlPrefix}/working_schedules/${id}/confirm`,
            {
                'working_schedule': payload,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getTechnicianUpdateData(id: string) {
        return axios.get(
            `${this.urlPrefix}/update_requests/${id}`,
        );
    }

    async updateTechnicianSchedulesData(payload: WorkerScheduleUpdateType) {
        return axios.post(
            `${this.urlPrefix}/update_requests`,
            {
                'update_request': payload,
            },
        );
    }

    async deleteTechnicianUpdateData(id: string) {
        return axios.delete(
            `${this.urlPrefix}/update_requests/${id}`,
        );
    }

    async fetchSkillNames(id: string): Promise<CategoryCompanySkillNamesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/company_skill_names?company_id=${id}`);
        return data;
    }
}

const workersApi = new WorkersWebApi();

export {
    workersApi as WorkersWebApi,
};
