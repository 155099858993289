<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <ErrorPopup
        v-if='showErrorPopup'
        :statusCode='errorStatus'
        :statusText='errorStatusText'
        :text='errorText'
        :buttonText='$t("FINISH.POPUP.SUCCESS.CLOSE")'
        @close='showErrorPopup = false'>
    </ErrorPopup>
    <FwPopup
        v-else
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :max-width='isWorkersCalendar ? `750px` : `600px`'
        :width='isWorkersCalendar ? `100%` : `auto`'
        padding='30px 40px 0'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit(`closePopup`)'>
        <template #body>
            <div class='h-text-center'>
                <h1 class='h-font-22'>
                    {{ formData.title }}
                </h1>
                <div v-if='formData.appointment_delay' style='line-height: 10px' class='h-flex h-flex-center'>
                    <FwIcon
                        class='h-mh-5'
                        icon='alert-info'
                        size='16'
                        color='#ff004d'>
                    </FwIcon>
                    <span class='h-font-14 h-fw-500' style='color: #ff004d'>
                        {{ $t('PLAN.APPOINTMENT.DELAY.TXT', { delay_value: formData.appointment_delay }) }}
                    </span>
                </div>
            </div>
            <div class='h-flex-center b-book-appointment__popup'>
                <EventForm
                    formClass='b-cancel-appointment__form'
                    iconsWrapperClass='b-cancel-appointment__form__events'
                    iconsInnerClass='h-mh-20'
                    :eventTime='eventTime'
                    :dates='dates'
                    :defaultFormValues='formData'
                    :disabledForm='true'
                    :activeType='activeType'
                    :appointmentStartDate='startDate'
                    submitText=''
                    @updateTime='updateTime'>
                </EventForm>
            </div>
            <div class='h-pt-10 h-pb-30 h-ph-50'>
                <div class='h-flex h-flex-center'>
                    <FwButton
                        class='h-mt-10'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='sendDataToServer'>
                        {{ $t('LABEL.BOOK') }}
                    </FwButton>
                </div>
                <div class='h-flex h-flex-center'>
                    <FwButton
                        class='h-mt-20'
                        :disabled='isRequestSending'
                        :style='{
                            color: `rgba(32, 63, 106, 0.3)`,
                            textTransform: `uppercase`
                        }'
                        color='transparent'
                        fontSize='14px'
                        @click='$emit(`closePopup`)'>
                        {{ $t('BUTTON.CANCEL') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { EventForm } from '@/components/forms/event/EventForm';
import { dateAsEventTime } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { ErrorPopup } from '@/components/popups/ErrorPopup';

@Component({
    components: {
        EventForm,
        ErrorPopup,
    },
})
export default class BookAppointmentPopup extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: String, default: null }) readonly activeType!: string;
    @Prop({ type: Date, default: null }) readonly startDate!: Date;
    isRequestSending: boolean = false;
    errorStatus: number | '' = '';
    errorStatusText: string = '';
    errorText: string = '';
    showErrorPopup: boolean = false;
    newTimeData: any = null;
    get formData() {
        return this.$store.state.AppointmentStore.appointmentData;
    }
    get eventTime(): string {
        return dateAsEventTime(new Date(this.formData.dt_start), this.user);
    }

    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.formData.dt_start)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.formData.dt_end)),
        ];
    }
    get technicianId() {
        return this.$route.params.id;
    }

    // get appointmentStartDate(): Date {
    //     const startDay = new Date(this.startDate);
    //     const timeData = this.getHoursAndMinutesFromAMPM((this.formatAMPM(startDay) as string));
    //     return new Date(startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    // }

    updateTime(data: any) {
        this.newTimeData = data;
    }

    async sendDataToServer() {
        this.isRequestSending = true;
        try {
            const response = await AppointmentWebApi.bookAppointment(
                {
                    ...this.newTimeData,
                    company_id: this.formData.company_id,
                    technician_id: this.technicianId,
                    direct_booking: true,
                }, this.formData.id);
            this.isRequestSending = false;
            this.sentNotif('APPOINTMENT.SUCCESS.POPUP.TXT', 'success');
            this.$emit('book');
            this.$emit('closePopup');
        } catch (error) {
            const { response } = (error as AxiosError);
            this.showErrorPopup = true;
            if (response) {
                this.errorText = response.data.errors && Array.isArray(response.data.errors) ? response.data.errors[0] : '';
                this.errorStatus = response.status;
                this.errorStatusText = response.statusText;
            }
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>

<style lang='sass'>
.b-book-appointment
    &__popup
        .b-cancel-appointment__form__events
            margin-bottom: 20px
.fw-button--transparent
    background-color: transparent !important
</style>
