export default {
    'SETTINGS.TEAM.TITLE': 'Votre équipe',
    'SETTINGS.TEAM.RESOURCES.TITLE': 'Ressources',
    'SETTINGS.TEAM.ROLES.TITLE': 'Fonctions',
    'SETTINGS.TEAM.ROLES.ADD': 'Ajouter une nouvelle fonction',
    'SETTINGS.TEAM.ROLES.EXISTING': 'Fonctions existantes',
    'SETTINGS.TEAM.PEOPLE.TITLE': 'Utilisateurs',
    'SETTINGS.TEAM.PEOPLE.ADD': 'Ajouter un utilisateur',
    'SETTINGS.TEAM.PLACEHOLDER.SEARCH': 'Rechercher par nom',
    'SETTINGS.TEAM.PEOPLE.EXISTING': 'Utilisateurs existants',
    'SETTINGS.TEAM.PEOPLE.RESULTS': 'Résultats',
    'SETTINGS.TEAM.PEOPLE.OTHER': 'Autres ressources',
};
