<template>
<div class='b-add-company__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ $t('WORKERS.FORM.TITLE') }}
        </h1>
    </div>

    <AddWorkersForm
        :key='formKey'
        :isRequestSending='isRequestSending'
        :errorFromServer='errorFromServer'
        submitText='BUTTON.ADD'
        @onSubmit='sendDataToServer'>
    </AddWorkersForm>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { AddWorkersForm } from '@/components/forms/add/AddWorkersForm';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import ValidationMixin from '@/mixins/validation';

@Component({
    components: {
        AddWorkersForm,
    },
})
export default class AddWorkersByForm extends Mixins(ValidationMixin) {
    isRequestSending: boolean = false;
    formKey: number = 1;
    errorFromServer: string | null | TranslateResult = null;

    async sendDataToServer(data: any) {
        this.isRequestSending = true;
        try {
            const response = await WorkersWebApi.createWorker({
                first_name: data.first_name,
                last_name: data.last_name,
                label: data.label,
                kind: 'technician',
                company_id: data.company,
                email: data.email,
                company_admin: data.company_admin,
                phone: data.phone,
                is_taking_appointments: data.is_taking_appointments ? data.is_taking_appointments : false,
                skill_ids: data.skill_ids,
            });
            this.isRequestSending = false;
            this.showSuccessPopup = true;
            this.$router.push({ path: `/consult/technicians/${response.data.user.id}` });
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', 'success');
        } catch ({ response }) {
            this.isRequestSending = false;
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    closePopup() {
        this.showSuccessPopup = false;
        this.formKey += 1;
    }
}
</script>

<style lang='sass'>
.b-add-worker
    &__wrapper
        width: 800px
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%
</style>
