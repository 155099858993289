export default {
    'SETTINGS.ROLES.TITLE': 'Roles',
    'SETTINGS.ROLES.SEARCH.TEXT': 'Search for a role',

    'SETTINGS.MENU.HEADER': 'Settings menu',
    'SETTINGS.MENU.ACCOUNT': 'Account',
    'SETTINGS.MENU.MANAGERS': 'Managers',
    'SETTINGS.MENU.ROLES': 'Roles',
    'SETTINGS.MENU.APPT.TYPES': 'Appointment types',
    'SETTINGS.MENU.APPT.REMINDERS': 'Appointment reminders',
    'SETTINGS.MENU.CALENDAR.VIEW': 'Calendar view',
    'SETTINGS.SEARCH.PLACEHOLDER': 'Search for a manager',
    'SETTINGS.SEARCH.APPT.PLACEHOLDER': 'Search for an appointment',
    'SETTINGS.SORT.APPT.CLOSEST': 'Time (the closest to the appointment)',
    'SETTINGS.SORT.APPT.FAREST': 'Time (The farest to the appointment)"',
    'SETTINGS.SORT.APPT.PEOPLE.IN': 'People involved"',

    'SETTINGS.ROLE.CONSULT': 'Consult',
    'SETTINGS.ROLE.VIEW': 'View',
    'SETTINGS.ROLE.ADD': 'Add',
    'SETTINGS.ROLE.EDIT': 'Edit',
    'SETTINGS.ROLE.LIST': 'Consult (list)',
    'SETTINGS.ROLE.READ': 'Consult',
    'SETTINGS.ROLE.CANCEL': 'Cancel',
    'SETTINGS.ROLE.DELETE': 'Delete',
    'SETTINGS.ROLE.CREATE': 'Create',
    'SETTINGS.ROLE.BOOK': 'Book',

    'SETTINGS.ROLE.RIGHTS.TITLE': 'Configuration',
    'SETTINGS.ROLE.COMPANY.LIST.TITLE': 'Company list',
    'SETTINGS.ROLE.COMPANY.WORKER.TITLE': 'Worker list',
    'SETTINGS.ROLE.COMPANY.CALENDAR.TITLE': 'Company calendar',
    'SETTINGS.ROLE.WORKER.CALENDAR.TITLE': 'Worker calendar',
    'SETTINGS.ROLE.NAME.TITLE': 'Role name',

    'SETTINGS.ROLE.RIGHTS.ACCOUNT': 'Account',
    'SETTINGS.ROLE.RIGHTS.ADVISOR': 'Advisor',
    'SETTINGS.ROLE.RIGHTS.ANONYMOUS_CALENDAR': 'Calendar anonymization',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY': 'Appointment type',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_NOTIFICATION': 'Appointment notification',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT': 'Appointment',
    'SETTINGS.ROLE.RIGHTS.BRANCH': 'Branch',

    'SETTINGS.ROLE.RIGHTS.TECHNICIAN.CALENDAR': 'Booking calendar display',
    'SETTINGS.ROLE.RIGHTS.ADVISOR.CALENDAR': 'Advisor Calendar',
    'SETTINGS.ROLE.RIGHTS.COMPANY_CALENDAR': 'Company Calendar',
    'SETTINGS.ROLE.RIGHTS.HUB_BRANCH_CALENDAR': 'Hub/Branch Calendar',
    'SETTINGS.ROLE.RIGHTS.HUB_CALENDAR': 'Hub Calendar',
    'SETTINGS.ROLE.RIGHTS.BRANCH_CALENDAR': 'Branch Calendar',
    'SETTINGS.ROLE.RIGHTS.CALENDAR': 'Calendar (company)',

    'SETTINGS.ROLE.RIGHTS.PROFILE': 'Calendar (technician)',
    'SETTINGS.ROLE.RIGHTS.COMPANY': 'Company',
    'SETTINGS.ROLE.RIGHTS.HUB': 'Hub',
    'SETTINGS.ROLE.RIGHTS.KPI': 'Statistics',
    'SETTINGS.ROLE.RIGHTS.MANAGER': 'Manager',
    'SETTINGS.ROLE.RIGHTS.RIGHTS': 'Role',
    'SETTINGS.ROLE.RIGHTS.SETTINGS': 'Settings',
    'SETTINGS.ROLE.RIGHTS.TECHNICIAN': 'Technician',
    'SETTINGS.ROLE.RIGHTS.ONBOARDING': 'Onboarding',

    'SETTINGS.ROLE.CREATE.SUCCESSFULLY': 'Role created successfully',
    'SETTINGS.ROLE.UPDATE.SUCCESSFULLY': 'Role updated successfully',
    'SETTINGS.ROLE.DELETE.SUCCESSFULLY': 'Role successfully deleted',
    'SETTINGS.ROLE.DELETE.TITLE': 'Delete the role',
    'SETTINGS.ROLE.DELETE.SUB': 'Are you sure you want to delete this role?',

    'SETTINGS.ROLE.GROUP.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.GROUP.BOOKING_FLOW': 'Booking flow',
    'SETTINGS.ROLE.GROUP.RESOURCES': 'Resources',
    'SETTINGS.ROLE.GROUP.CONFIGURATION': 'Configuration',
};
