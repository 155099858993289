<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h1>
            {{ $t('CONSULT.ADD.FILE.TITLE') }}
        </h1>
    </div>
    <div class='h-flex-center h-flex-dir-column h-ph-10'>
        <FwFileLoader
            class='h-mb-50'
            :dragText='$t("CONSULT.ADD.FILE.DRAG")'
            :dragAsideText='$t("CONSULT.ADD.FILE.FRAG_ASIDE_TEXT")'
            @error='showErrorPopup = true'
            @upload='handleUpload'>
        </FwFileLoader>
        <router-link
            :to='importFileInfoRoute'
            class='b-add-company-by-file__link h-flex-center h-pointer'>
            {{ $t('CONSULT.ADD.FILE.QUESTION') }}
            <FwIcon
                class='h-ml-10 h-mt-2'
                icon='arrow-right'
                size='12'
                color='#213F6B'>
            </FwIcon>
        </router-link>
    </div>

    <ErrorPopup
        v-if='showErrorPopup'
        :text='$t("CONSULT.ADD.POPUP.ERROR.TEXT")'
        :buttonText='$t("CONSULT.ADD.POPUP.ERROR.BUTTON_TEXT")'
        @close='showErrorPopup = false'>
    </ErrorPopup>

    <InProgressPopup
        v-if='showLoadingPopup'
        subMessage='Just a few minute…'
        title='Import in progress'>
    </InProgressPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { InProgressPopup } from '@/components/popups/InProgressPopup';
import { CONSULT_PREFIX } from '@/routes/config';
import { ErrorPopup } from '@/components/popups/ErrorPopup';

@Component({
    components: {
        InProgressPopup,
        ErrorPopup,
    },
})
export default class AddCompanyByFile extends Vue {
    showLoadingPopup: boolean = false;
    showErrorPopup: boolean = false;

    get importFileInfoRoute() {
        return this.$route.meta ? `${CONSULT_PREFIX}${this.$route.meta.urlPrefix}/import-info` : ``;
    }

    handleUpload() {
        this.showLoadingPopup = true;
        window.setTimeout(() => {
            this.showLoadingPopup = false;
            if (this.$route.meta) {
                this.$router.push(`${CONSULT_PREFIX}${this.$route.meta.urlPrefix}/file-data`);
            }
        }, 4000);
    }
}
</script>

<style lang='sass'>
.b-add-company-by-file__link
    font-size: 16px
    line-height: 19px
    font-weight: 500
</style>
