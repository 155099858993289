<template>
<div class='b-blue-background b-blue-background--fixed b-location-popup'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='550px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mv-0 h-text-center'>
                    {{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.TITLE') }} {{ curentDate }}{{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.TITLE2') }}
                </h2>
                <p class='h-font-16 h-text-center h-mv-30'>
                    {{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.TEXT1') }}
                    <span class='h-fw-500'>
                        {{ companyDate.title }}
                    </span>
                    {{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.TEXT2') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-flex-dir-column'>
                <FwButton
                    class='h-width-100p'
                    borderRadiusType='small-border'
                    @click='$emit(`getChooseData`, timeSlotDate, timeSlotData)'>
                    {{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.BUTTON.YES') }}
                </FwButton>
                <FwButton
                    class='h-mt-20 h-font-16 h-width-100p'
                    color='cancel'
                    borderRadiusType='small-border'
                    @click='$emit(`close`)'>
                    {{ $t('CALENDAR.APPOINTMENT.DAY.POPUP.BUTTON.NO') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { dateAsEventTime } from '@/helpers/dates';
import {
    EventsType,
} from '@/types/Events';

@Component
export default class AppointmentDayInfoPopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Date, default: null }) readonly timeSlotDate!: Date;
    @Prop({ type: Array, default: null }) readonly timeSlotData!: Array<EventsType>;
    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }
    get curentDate() {
        return dateAsEventTime(this.timeSlotDate, this.user());
    }
}
</script>
<style lang='sass'>
    .b-location-popup
        .fw-button--cancel
            font-size: 16px
        .gmnoprint a, .gmnoprint span, .gm-style-cc
            display: none
</style>
