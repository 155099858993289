export default {
    'SETTINGS.ROLE.EDIT.POPUP.TITLE': 'Modifier une fonction',
    'SETTINGS.ROLE.ADD.POPUP.TITLE': 'Ajouter une fonction',
    'SETTINGS.ROLE.SUBTITLE': 'Veuillez assigner au moins un droit',
    'SETTINGS.ROLE.NAME.LABEL': 'Nom de la fonction',
    'SETTINGS.ROLE.NAME.PLACEHOLDER': 'Donnez un nom à cette nouvelle fonction',
    'SETTINGS.ROLE.APPOINTMENT.TITLE': 'Rendez-vous',
    'APPOINTMENT.TITLE.CANCELED': 'L\'intervention a bien été annulée',
    'appointment_booking': 'Prendre des rendez-vous',
    'appointment_cancelling': 'Annuler des rendez-vous',
    'SETTINGS.ROLE.COMPANIES.TITLE': 'Sociétés',
    'company_list_consulting': 'Consulter la liste des sociétés',
    'company_list_cancelling': 'Supprimer une société',
    'company_calendar_consulting': 'Consulter le calendrier des sociétés',
    'company_list_adding': 'Ajouter une société',
    'company_list_editing': 'Modifier une société',
    'SETTINGS.ROLE.WORKERS.TITLE': 'Ressources',
    'worker_list_consulting': 'Consulter la liste des ressources',
    'worker_list_cancelling': 'Supprimer une ressource',
    'worker_list_adding': 'Ajouter une ressource',
    'worker_calendar_consulting': 'Consulter le calendrier des ressources',
    'worker_list_editing': 'Modifier une ressource',
    'SETTINGS.ROLE.DELETE': 'Supprimer',
    'settings_adding_team_member': 'Ajouter un utilisateur',
    'SETTINGS.ROLE.REQUIRED.FIELD': 'Champ obligatoire',
    'SETTINGS.ROLE.DELETE.TITLE': 'Supprimer une fonction',
    'SETTINGS.ROLE.DELETE.TXT.EXISTING.USER': ' Il y a des utilisateurs qui ont cette ' +
        'fonction. Avant de pouvoir supprimer cette fonction, vous devez modifier la fonction de ces utilisateurs.',
    'SETTINGS.ROLE.DELETE.TXT': 'Souhaitez-vous vraiment supprimer cette fonction?',
    'SETTINGS.ROLE.DELETE.BUTTON.CONFIRM': 'Oui, supprimer',
    'SETTINGS.ROLE.DELETE.BUTTON.CANCEL': 'Annuler',
    'settings_editing_team_member': 'Modifier un utilisateur',
    'settings_deleting_team_member': 'Supprimer un utilisateur',
    'settings_adding_role': 'Ajouter une fonction',
    'settings_editing_role': 'Modifier une fonction',
    'settings_deleting_role': 'Supprimer une fonction',
};
