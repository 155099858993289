export default {
    'CANCEL.REASON.HEADER': 'Veuillez préciser le motif de l\'annulation',
    'CANCEL.ORIGIN.LABEL': 'Origine de l\'annulation',
    'CANCEL.REASON.LABEL': 'Motif de l\'annulation',
    'CANCEL.REASON.PERSON.PLACEHOLDER': 'Sélectionner une personne',
    'CANCEL.REASON.PLACEHOLDER': 'Sélectionner une origine',
    'CANCEL.BUTTON.APPOINTMENT': 'Annuler l\'intervention',
    'PAST.APPOINTMENTS.CANT.BE.CANCELED': 'Les RDV passés ne peuvent pas être annulés',
    'CANCEL.REASON.APPOINTMENT': 'L\'intervention a bien été annulée',
    'CANCEL.GO.HOME.BUTTON': 'Planifier une intervention',
    'CANCEL.REASON.EMERGENCY': 'Urgence société / force majeure',
    'CANCEL.REASON.SUITABILITY': 'Convenance personnelle',
    'CANCEL.REASON.COMPLIANCE': 'Conformité site',
    'CANCEL.REASON.INTERVENTION.NECESSARY': 'Intervention plus nécessaire',
    'CANCEL.REASON.WORKER.MISTAKE': 'Erreur planification société',
    'CANCEL.REASON.WORKER.SUITABILITY': 'Convenance société',
    'CANCEL.REASON.DELAY': 'Retard',
    'CANCEL.REASON.STOCK.ISSUE.DELAY': 'Problème de stock (réception retardée)',
    'CANCEL.REASON.STOCK': 'Stock (injustifié)',
    'CANCEL.REASON.GOOD.AGREEMENT': 'Bon pour accord KO',
    'CANCEL.REASON.BAD.APPRECIATION': 'Mauvaise appréciation distance',
    'CANCEL.REASON.REQUEST.MANAGER': 'A la demande des réseaux',
    'CANCEL.REASON.BAD.WEATHER': 'Intempéries/Evènement spécial',
    'CANCEL.REASON.OTHER.URGENT': 'Autre intervention plus urgente',
    'CANCEL.REASON.PLAN.ERROR': 'Erreur planification',
    'CANCEL.REASON.BAD.COMPLIANCE': 'Mauvaise conformité',
    'CANCEL.REASON.DELAYED': 'Réception retardée',
    'CANCEL.REASON.RIN.CANCELLATION': 'Annulation RIN',
    'CANCEL.REASON.RIN.TRANSFORMATION': 'Transformation RIN',
    'CANCEL.ORIGIN.CUSTOMER': 'Client',
    'CANCEL.ORIGIN.WORKER': 'Installateur',
    'CANCEL.ORIGIN.IMA': 'IMA Protect',
    'CANCEL.ORIGIN.MANAGER': 'Planification',
    'CANCEL.ORIGIN.STOCK': 'Stock',
    'CANCEL.ORIGIN.TECH.INCOMPATIBILITY': 'Incompatibilité technique',
    'CANCEL.TECHNICIAN.TRAVEL': 'Déplacement du technicien',
    'CANCEL.SUCCESS.APPOINTMENT': 'Le rendez-vous a été annulé',
};
