import addCompanies from './add-companies';
import addTechnicians from './add-technicians';
import worker from './worker';
import update from './update';
import common from './common';
import forms from './forms';
import reservedSlots from './reserved-solots';
import appointments from './appointments';

export default {
    ...addCompanies,
    ...addTechnicians,
    ...appointments,
    ...worker,
    ...update,
    ...common,
    ...forms,
    ...reservedSlots,
};
