export default {
    'LOGIN.HEADER': 'Bienvenue ! Veuillez vous connecter pour accéder à la plateforme',
    'LOGIN.TXT': 'Se connecter',
    'LOGIN.EMAIL.LABEL': 'Votre email',
    'LOGIN.PASSWORD.LABEL': 'Votre mot de passe',
    'LOGIN.PASSWORD.PLACEHOLDER': 'Cela restera entre nous',
    'LOGIN.FORGET.PASSWORD': 'Mot de passe oublié ?',
    'LOGIN.WITH.AZURE': 'Se connecter avec Azure AD',
    'LOGIN.WITH.MICROSOFT': 'Se connecter avec Microsoft',
    'LOGIN.PLACEHOLDER.EMAIL': 'adresse@email.com',
    'LOGIN.PLACEHOLDER.EX.EMAIL': 'ex: adresse@email.com',
};
