export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Ajouter un créneau',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Créneaux réservé',

    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.CHOOSE.TITLE': 'Consulter les créneaux réservés',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.CHOOSE.TITLE': 'Consulter les indisponibilités',

    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Modifier l\'indisponibilité',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Ajouter une indisponibilité',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Indisponibilité passée',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'JOUR ENTIER',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'MATIN',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'APRÈS-MIDI',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'PAS DE RECURRENCE',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'FIN DE RECURRENCE',

    'ADD.RESERVED.SLOT.FORM.TO': 'à',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'TOUS LES JOURS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'TOUTES LES SEMAINES',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'TOUS LES MOIS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'TOUS LES ANS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'SELECTIONNER UNE DATE',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Semaine du mois',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'Première semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'Deuxième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': 'Troisième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': 'Quatrième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'Dernière semaine',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOISISSEZ UN TITRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'INVENTAIRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.INACTIVE': 'INACTIF',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'JOUR DE CONGÉ',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'FORMATION',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'INDISPONIBLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.STOPPAGE': 'FERMETURE',
};
