<template>
<div class='fw-time-card'
     :class='{ "fw-time-card--reserved": data.reserved }'>
    <div class='fw-time-card__time-wrapper'>
        <div class='h-flex-center'>
            <div class='fw-time-card__time'>
                {{ startDate }}
            </div>
            <div class='fw-time-card__time'>
                {{ endDate }}
            </div>
        </div>
        <div v-if='data.distance_to_in_km_text'
             class='fw-time-card__distance'>
            {{ data.distance_to_in_km_text }}
        </div>

        <div v-if='isNumberExists'
             class='fw-border-index
                    h-pos-abs'
             :style='{ backgroundColor: color }'>
            {{ number }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AvailabilityType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';

@Component
export default class BookCalendarEventCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly data!: AvailabilityType;

    get color() {
        if (!this.data.priority) {
            return `rgba(113, 143, 157, 0.8)`;
        }
        const priorityColors: Array<string> = [
            `#27DBBD`,
            `#24B6E7`,
            `#FFC500`,
            `#FF901D`,
            `#FF004D`,
        ];
        const count = this.data.priority - 1;
        return priorityColors[count] || priorityColors[0];
    }

    get number(): number | null {
        if (Number.isInteger(this.data.priority)) {
            return this.data.priority;
        }
        return null;
    }

    get isNumberExists() {
        return Number.isInteger((this.number as number));
    }

    get startDate() {
        return this.getCurrentDateAsMinutes(new Date(this.data.slot));
    }
}
</script>

<style lang='sass'>
.fw-time-card
    padding: 2px 4px 2px 10px !important
    cursor: pointer
    width: 70px !important
    max-width: 70px !important
    height: 34px
    margin: 0 5px
    z-index: 1
    transition: transform 0.2s, box-shadow 0.2s

    &--reserved
        border: 1px solid #3D5E85

    &__outer
        width: 100%

    &__distance
        font-size: 11px
        display: flex

    &__time-wrapper
        padding-left: 4px

    .fw-time-card__label
        width: 5px

    .fw-border-index
        width: 12px
        height: 12px
        border-radius: 0 4px 0 4px
        top: 0
        right: 0
        text-align: center
        font-weight: 500
        position: absolute
        z-index: 1
        color: #fff
        font-size: 8px
        display: flex
        align-items: center
        justify-content: center
</style>
