<template>
<div class='b-app-wrapper'>
    <RouterWrapper></RouterWrapper>
    <NotificationBar></NotificationBar>
</div>
</template>

<script lang='ts'>
// if we change order of imports - webpack will crash)
/* eslint-disable */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { RouterWrapper } from './components/global/RouterWrapper';
import { NotificationBar } from './components/simple/NotificationBar';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { namespace } from 'vuex-class';
import { prepareServerFormErrors } from '@/helpers/validators';
import { AxiosError } from "axios";

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        RouterWrapper,
        NotificationBar,
    },
})
export default class App extends Vue {
    @CommonStore.Action('fetchOwnerAccountData') fetchOwnerAccountData!: Function;

    async getUser() {
        try {
            this.fetchOwnerAccountData(this.user().id)
        } catch (e) {
            console.log(e);
        }
    }

    created() {
        if (this.user().account_id) {
            this.getUser();
            this.$i18n.locale = this.user().locale;
        }
    }
}
</script>

<style lang='sass'>
@import '../node_modules/ui-framework/framework/style/base.sass'
@import '~normalize.css/normalize.css'
@import '../style/base.sass'

.b-app-wrapper
    min-height: 100vh
    width: 100%
</style>
