import axios from 'axios';
import WebApi from '../WebApi';
import { ClosuresItemType, fetchStoppagesEventsPayloadType, postStoppageEventPayloadType } from '@/types/Consult';
import { updateUrlGetParams } from '@/helpers/url';

class ConsultApi extends WebApi {
    async fetchStoppagesEvents(payload: fetchStoppagesEventsPayloadType): Promise<Array<ClosuresItemType>> {
        const url = updateUrlGetParams(`${this.urlPrefix}/stoppages`, payload);
        const { data } = await axios.get(url);
        return data.stoppages;
    }

    async postStoppageEvent(stoppage: postStoppageEventPayloadType): Promise<ClosuresItemType> {
        const { data } = await axios.post(`${this.urlPrefix}/stoppages`, { stoppage });
        return data.stoppage;
    }

    async updateStoppageEvent(id: string, stoppage: postStoppageEventPayloadType): Promise<ClosuresItemType> {
        const { data } = await axios.put(`${this.urlPrefix}/stoppages/${id}`, { stoppage });
        return data;
    }

    async deleteStoppageEvent(id: string): Promise<{}> {
        const { data } = await axios.delete(`${this.urlPrefix}/stoppages/${id}`);
        return data;
    }
}

const appointmentApi = new ConsultApi();

export {
    appointmentApi as ConsultApi,
};
