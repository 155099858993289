<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        v-if='!isBookAppointmentPopupShow'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        width='auto'
        title=''
        :style='{maxHeight: `90vh`, position: `relative`, padding: `20px 50px 20px`}'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t('CALENDAR.POPUP.ADD.APPOINTMENT.TITLE') }}
                </h2>
            </div>
            <AddAppointmentForm
                class='h-mt-30'
                :isRequestSending='isRequestSending'
                :popupStyle='true'
                :serverErrors='serverErrors'
                :appointmentStartDate='appointmentStartDate'
                isTechCalendar
                @book='updateDataHandler'
                @close='$emit("close")'
                @sendCode='sendDataToServer'>
            </AddAppointmentForm>
        </template>
    </FwPopup>
    <BookAppointmentPopup
        v-if='isBookAppointmentPopupShow'
        :activeType='activeType'
        :startDate='appointmentStartDate'
        @book='updateDataHandler'
        @click.native.stop
        @closePopup='closePopup'>
    </BookAppointmentPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { AddAppointmentForm } from '@/components/forms/add/AddAppointmentForm';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { BookAppointmentPopup } from '@/components/popups/BookAppointmentPopup';
import {
    dateToString,
} from '@/helpers/dates';

@Component({
    components: {
        AddAppointmentForm,
        BookAppointmentPopup,
    },
})
export default class AddAppointmentPopup extends Vue {
    @Prop({ type: Date, default: null }) readonly appointmentStartDate!: Date;
    isRequestSending: boolean = false;
    isBookAppointmentPopupShow: boolean = false;
    code: string | null = '';
    activeType: string | null = '';
    serverErrors = {
        code: this.error,
    };

    get technicianId() {
        return this.$route.params.id;
    }

    closePopup() {
        this.$emit('close');
        this.isBookAppointmentPopupShow = false;
    }

    async sendDataToServer({ code, app_type }: { code: string, app_type: string }) {
        if (!this.isRequestSending) {
            this.isRequestSending = true;
            try {
                const { appointment } = await AppointmentWebApi.sendInterventionData({
                    reference: code,
                    app_type: app_type.toLowerCase(),
                    account_id: this.user().account_id,
                    user_id: this.technicianId,
                });
                this.code = code;
                this.activeType = app_type;
                this.$store.dispatch('storeAppointmentData', appointment);
                this.isRequestSending = false;
                this.isBookAppointmentPopupShow = true;
                // this.updateDataHandler();
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    };

    @Emit('updateData')
    updateDataHandler() {}
}
</script>
