<template>
<div class='b-page-container b-page-container--big b-company-page--tabs'>
    <div class='h-flex h-flex-dir-column h-flex-center'>
        <div class='h-text-center'>
            <h1 class='h-font-22'>
                {{ $t('LABEL.SEARCH.COMPANIES') }}
            </h1>
        </div>
        <div class='b-page-title h-mh-10'>
            <div class='h-flex h-width-100p h-flex-center h-pos-rel'>
                <div class='b-intervention-code__inputs-wrapper h-flex'>
                    <FwButton
                        v-for='item in searchTypesArray'
                        :key='item.type'
                        class='h-mh-10 b-intervention-code__button'
                        color='blue'
                        :inversed='activeType !== item.type'
                        size='medium'
                        @click.native='updateActiveType(item.type)'>
                        {{ item.title }}
                    </FwButton>
                </div>
            </div>
        </div>
        <div v-if='activeType === `company`'
             class='h-flex h-flex-center h-mt-20 h-mb-40'>
            <FwInput
                class='h-flex-1 h-ml-15'
                style='width: 320px;'
                :value='searchText'
                :placeholder='$t(`LABEL.PLACEHOLDER.SEARCH.COMPANY`)'
                iconRight='loupe'
                iconSize='15'
                inputStyle='search'
                type='search'
                @update:value='value => searchText = value'>
            </FwInput>
            <div class='b-work-company-page fw-select-base h-ml-10'>
                <FwSelect
                    v-model='sortValue'
                    style='width: 200px;'
                    type='sort-simple'
                    label='name'
                    :canNotDelete='true'
                    :searchable='false'
                    :multiply='false'
                    :options='sortOptions'
                    @input='sortBy'>
                    <template #title>
                        {{ $t('BUTTON.SORT_BY') }}
                    </template>
                    <template v-slot:option='{ props }'>
                        <slot
                            :props='props'
                            name='option'>
                            <div class='multiselect__content-item'>
                                <div class='multiselect__item-header h-flex h-flex-space-between h-flex-center h-min-width-200'>
                                    <span class='multiselect__title-first'>
                                        {{ props.option.name }}
                                    </span>
                                </div>
                            </div>
                        </slot>
                    </template>
                </FwSelect>
            </div>
        </div>
        <div v-else class='h-mb-50 h-mt-30 h-width-100p h-flex-dir-column h-flex-center'>
            <div class='h-flex h-width-100p h-ph-30'>
                <FwFormInput
                    class='h-flex-0-0-50p h-ph-15'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.SKILLS`)}`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-model='skillChoosed'
                            class='fw-select-with-icon--big'
                            :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                            type='white'
                            :searchable='false'
                            :loading='isSkillsLoading'
                            label='fr_name'
                            :multiple='true'
                            :clearable='false'
                            :withCircles='true'
                            :canNotDelete='false'
                            :options='skills'
                            @input='selectSkills'>
                            <template #noOptions>
                                {{ $t('LABEL.NO.OPTION') }}
                            </template>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    class='h-flex-0-0-50p h-ph-15'
                    :title='`${$t(`LABEL.ADDRESS`)}`'
                    labelType='label-medium'>
                    <SelectAddress
                        :startAddress='address'
                        :autofocus='true'
                        autoCompleteServiceType='geocode'
                        @changePostCode='value => post_code = value'
                        @input='input'>
                    </SelectAddress>
                </FwFormInput>
            </div>
            <div v-show='isSendAddressButton'
                 class='h-flex h-flex-center h-width-100p'>
                <FwButton
                    class='h-mt-30'
                    borderRadiusType='tiny-border'
                    :disabled='!post_code'
                    @click='addSendAddress'>
                    {{ $t('LABEL.SEND') }}
                </FwButton>
            </div>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else-if='!loading && companiesList.length' :class='[{ "b-consult-cards-small__wrapper": !isActiveTypeCompany }, "b-consult-cards__wrapper"]'>
            <CompanyCard
                v-for='company in companiesList'
                :key='company.id'
                :company='company'
                class='h-mh-10 h-mb-20'
                :class='!isActiveTypeCompany ? `b-company-card--small` : null'>
            </CompanyCard>
        </div>
    </div>
    <FwPagination
        v-if='isActiveTypeCompany'
        style='position: sticky; bottom: 0;'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import throttle from 'lodash.throttle';
import { CompanyData } from '@/types/Companies';
import { ParsedAddressData } from '@/types/GoogleMap';
import { CategorySkillNameType } from '@/types/Appointment';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CompanyCard } from '@/components/simple/CompanyCard';
import { SelectAddress } from '@/components/common/SelectAddress';
import { updateUrlPageAndQuery } from '@/helpers/url';

import { addPostCodeToAddress } from '@/helpers/address';

const SORT_BY_NAME = 'name';
const SORT_BY_NUMBER = 'number';
const COMPANY_TAB = 'company';
const ADDRESS_TAB = 'appt_addres';

@Component({
    components: {
        CompanyCard,
        SelectAddress,
    },
})

export default class ConsultCompaniesByTabsPage extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly companiesCardsProps!: Array<CompanyData>;
    @Prop({ type: Number, default: 0 }) readonly totalPageProp!: number;
    @Prop({ type: Number, default: 1 }) readonly currentPageProp!: number;

    searchText: string = '';
    currentPage: number = this.currentPageProp;
    totalPage: number | null = this.totalPageProp;
    perPage: number = 10;
    loading: boolean = false;
    sortByWorkersCount: boolean = false;
    isSendAddressButton: boolean = false;
    isSkillsLoading: boolean = false;
    sortValue: null | { name: string, type: string } = null;
    sortOptions: Array<{ name: any | TranslateResult, type: string }> = [];
    activeType: string = 'company';
    companiesCards: Array<CompanyData> = [];
    skillChoosed: Array<CategorySkillNameType> = [];
    skills: Array<CategorySkillNameType> = [];
    companiesCardsByAddress = [];
    post_code: string = '';
    address: string = '';
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    get searchTypesArray() {
        return [
            {
                title: this.$i18n.t('LABEL.BY.COMPANY.NAME'),
                type: COMPANY_TAB,
            },
            {
                title: this.$i18n.t('LABEL.APPT.ADDRESS'),
                type: ADDRESS_TAB,
            },
        ];
    }

    get isActiveTypeCompany() {
        return this.activeType === COMPANY_TAB;
    }

    get companiesList() {
        return this.isActiveTypeCompany ? this.companiesCards : this.companiesCardsByAddress;
    }

    updateState(page: number = this.currentPage) {
        this.$router.replace(updateUrlPageAndQuery(`/consult/companies`, this.currentPageProp, {
            query: this.searchText,
            popup: true,
            page,
            ...(this.sortValue && { sort: this.sortValue.type }),
            ...(this.searchText && { search: this.searchText }),
            ...(this.activeType && { type: this.activeType }),
            ...(this.address && this.post_code && { address: this.address }),
            ...(this.post_code && this.address && { post_code: this.post_code }),
            ...(this.skillChoosed.length && { skills: this.skillChoosed.map(item => item.id) }),
        })).catch(() => {});
    }

    search(): void {
        this.updateState(1);
    }

    updateActiveType(type: string) {
        this.activeType = type;
        if (this.isActiveTypeCompany && !this.companiesCards.length) {
            this.address = '';
            this.post_code = '';
            this.skillChoosed = [];
            this.updateState();
        } else if (!this.isActiveTypeCompany && !this.companiesCardsByAddress.length) {
            this.updateState();
        }
    }

    input(address: ParsedAddressData) {
        this.address = address.calizyAddress;
        this.post_code = address.post_code;
        if (this.post_code) {
            this.updateState(1);
        } else {
            this.isSendAddressButton = true;
        }
    }

    addSendAddress() {
        this.address = addPostCodeToAddress(this.address, this.post_code);
        this.updateState(1);
    }

    async getDataByAddress(address: string) {
        this.loading = true;
        try {
            const skill_ids = this.skillChoosed.map(item => item.id);
            const { companies } = await AppointmentWebApi.getCompanyByAddress(address, skill_ids);
            this.companiesCardsByAddress = companies;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async selectSkills() {
        this.updateState(1);
    }

    getCompaniesList(currentPage: number, perPage = this.perPage) {
        if (this.searchText.length > 1 || this.skillChoosed.length) {
            this.getCompaniesBySearch(currentPage, perPage, this.searchText, this.sortByWorkersCount);
        } else if (this.searchText.length === 0) {
            this.getCompanies(currentPage, perPage, this.sortByWorkersCount);
        }
    }

    async getCompanies(currentPage: number, perPage: number, sort: boolean) {
        this.loading = true;
        this.currentPage = currentPage;
        const payload: any = {
            current_page: currentPage,
            per_page: perPage,
            sort,
        };
        try {
            const data = await AppointmentWebApi.getSocietyPaginationData(payload);
            this.totalPage = data.meta.total_count;
            this.loading = false;
            const key = this.isActiveTypeCompany ? 'companiesCards' : 'companiesCardsByAddress';
            this[key] = data.companies;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async getCompaniesBySearch(el: number, val: number, searchText: string, sort: boolean) {
        if (this.searchText.length > 1 || this.skillChoosed.length) {
            this.loading = true;
            this.currentPage = el;
            const payload: any = {
                current_page: el,
                per_page: val,
                sort,
            };
            try {
                const data = await AppointmentWebApi.getSocietyDataBySearch(payload, searchText);
                this.companiesCards = data.companies;
                this.totalPage = data.meta.total_count;
                this.loading = false;
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.loading = false;
            }
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        // @ts-ignore-next-line
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page, 10) : 1;
        this.searchText = (this.$route.query.query as string) || ``;
        this.activeType = (this.$route.query.type as string) || `company`;
        this.address = (this.$route.query.address as string) || ``;
        this.post_code = (this.$route.query.post_code as string) || ``;
        if (this.$route.query.skills) {
            this.skillChoosed = this.skills.filter(item => this.$route.query.skills.includes(item.id));
        }
        if (this.$route.query.sort) {
            const sotValue = this.sortOptions.find(item => item.type === this.$route.query.sort);
            if (sotValue) {
                this.sortValue = sotValue;
            }
        }
        if (!this.sortValue) {
            this.sortValue = this.sortOptions[0];
        }
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortValue', this.sortBy);
    }

    async getSkillsNames() {
        try {
            this.isSkillsLoading = true;
            const data = await AppointmentWebApi.fetchSkillNames();
            this.skills = data.skill_names;
            this.totalPage = data.meta.total_count;
            this.isSkillsLoading = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isSkillsLoading = false;
        }
    }

    async created() {
        await this.getSkillsNames();
        this.sortOptions = [
            {
                name: this.$i18n.t('PLAN.SOCIETY.LABEL'),
                type: SORT_BY_NAME,
            },
            {
                name: this.$i18n.t('CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NUMBER'),
                type: SORT_BY_NUMBER,
            },
        ];

        if (this.companiesCardsProps && this.companiesCardsProps.length) {
            this.takeParamsFromUrl();
        } else {
            this.takeParamsFromUrl();
            this.doRequest();
        }
    }

    doRequest() {
        if (this.address || !!this.skillChoosed.length) {
            this.getDataByAddress(this.address);
            this.isSendAddressButton = false;
        } else if (this.searchText.length > 1) {
            this.getCompaniesList(1);
        } else if (this.searchText.length === 0) {
            this.getCompanies(this.currentPage, this.perPage, this.sortByWorkersCount);
        }
    }

    sortBy() {
        this.updateState(1);
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-company-page
    .fw-input--search
        background-color: #eff0f0 !important

.b-work-company-page
    .multiselect__tags
        display: flex !important
        align-items: center !important
        padding-left: 12px !important

    .multiselect__single
        margin-bottom: 0 !important

.b-company-page--tabs
    height: 100%
    display: flex
    flex-flow: column
    justify-content: space-between
    margin-bottom: 0 !important

    .b-intervention-code__inputs-wrapper
        margin-bottom: 0

    .b-company-page
        min-width: 200px

    .fw-input--search
        background: rgba(33, 63, 107, 0.05)

    .fw-select-sort-simple
        .multiselect__single
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            width: 135px

    .b-add-company__form__input
        max-width: 500px
        width: 100% !important

    .fw-select-white .multiselect__element,
    .fw-select-white .multiselect__tags,
    .fw-select-white .multiselect__option--selected
        background: rgba(33, 63, 107, 0.05) !important

    .b-select-address .multiselect__content-wrapper
        min-width: 100%
        overflow-x: hidden

    .b-consult-cards-small__wrapper
        display: flex
        flex-flow: row wrap
        max-width: 500px

    .b-company-card--small
        min-height: 85px
        flex: 1 0 400px
</style>
