export default {
    'BUTTON.NEXT': 'Suivant',
    'BUTTON.SAVE': 'Enregistrer',
    'BUTTON.ADD': 'Ajouter',
    'BUTTON.CLEAR.ALL': 'Tout effacer',
    'BUTTON.SEARCH': 'Rechercher',
    'BUTTON.ADD.NEW': 'Ajouter un nouveau',
    'BUTTON.SORT_BY': 'Trier par',
    'BUTTON.SUBMIT': 'Confirmer',
    'BUTTON.BACK': 'Retour',
    'BUTTON.CANCEL': 'Annuler',
    'BUTTON.QUIT': 'Quitter l\'agenda',
    'BUTTON.SEE_CALENDAR': 'Voir l\'agenda',
    'HEADER.PLAN': 'Planifier',
    'HEADER.CONSULT': 'Consulter',
    'HEADER.CANCEL': 'Annuler',
    'HEADER.SETTINGS': 'Réglages',
    'HEADER.INFORMATION': 'Information',
    'LABEL.SEND': 'Envoyer',
    'LABEL.CATEGORY': 'Catégorie',
    'LABEL.COMPANY': 'Société',
    'LABEL.COMPANY.ADDRESS': 'Adresse société',
    'LABEL.COMPANIES': 'Sociétés',
    'LABEL.WORKERS': 'Ressources',
    'LABEL.WORKERS.PLURAL': 'Ressource | Ressources',
    'LABEL.TECHNICIANS': 'Ressources',
    'LABEL.TECHNICIANS.PLURAL': 'Ressource | Ressources',
    'LABEL.YOUR.COMPANY': 'Votre compte',
    'LABEL.NAME': 'Nom',
    'LABEL.EXTERNAL.ID': 'ID externe',
    'LABEL.CONTACT': 'Contact',
    'LABEL.LOGO': 'Logo',
    'LABEL.QUALIFICATIONS': 'Habilitations',
    'LABEL.ADDRESS': 'Adresse',
    'LABEL.ADDRESS.POST.CODE': 'Le code postal de cette adresse n’existe pas, merci de taper le code postal ou de modifier l’adresse',
    'LABEL.ZIP.CODE': 'Code Postal',
    'LABEL.START.POINT': 'Point de départ',
    'LABEL.PHONE': 'Téléphone',
    'LABEL.DELETE': 'SUPPRIMER',
    'LABEL.PLACEHOLDER.SEARCH': 'Recherche',
    'LABEL.PLACEHOLDER.CHOOSE_LANG': 'Choisissez la langue',
    'LABEL.PLACEHOLDER.SEARCH.COMPANY': 'Saisissez le nom de la société',
    'LABEL.SEARCH.BY': 'Rechercher par',
    'LABEL.SEARCH.COMPANIES': 'Recherche de société',
    'LABEL.APPT.ADDRESS': 'par adresse de site',
    'LABEL.BY.COMPANY.NAME': 'par nom de société',
    'LABEL.APPOINTMENT.METHOD': 'Mode de RDV',
    'LABEL.APPOINTMENT.METHODS': 'Modes de RDV',
    'LABEL.GEOGRAFIC.ZONE': 'Zone géographique',
    'LABEL.SKILLS': 'Compétences',
    'SKILLS.YOU.NEED.SELECT': 'Sélectionnez une compétence',
    'LABEL.SELECT.COMPANY.FIRST': 'Sélectionnez d\'abord l\'entreprise',
    'LOADING.TEXT': 'CHARGEMENT...',
    'LABEL.WARNING': 'Attention',
    'LABEL.SKILLS.NEEDED': 'Compétences requises',
    'FINISH.POPUP.SUCCESS.TITLE': 'Annulation réussie !',
    'FINISH.POPUP.SUCCESS.CLOSE': 'Fermer',
    'VALIDATION.FIELD.REQUIRED': 'Ce champ est obligatoire',
    'VALIDATION.FIELD.EMAIL': 'Email invalide',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Ne doit pas dépasser 10 caractères',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Doit contenir au moins 2 caractères',
    'WRONG.CODE': 'Veuillez saisir un code d\'intervention valide',
    'UPDATE.TEXT': 'Modification enregistrée',

    'GLOBAL.TECHNICIAN': 'Ressource',
};
