export default {
    'LABEL.COMPANY.NAME': 'Raison sociale',
    'LABEL.APPT.CLIENT.FIRST.NAME': 'Prénom du client',
    'LABEL.APPT.CLIENT.LAST.NAME': 'Nom du client',
    'LABEL.APPT.CLIENT.COMPANY': 'Société du client',
    'LABEL.APPT.CLIENT.CONTRACT': 'Numéro de contra',
    'LABEL.APPT.CLIENT.OI': 'Numéro d\'OI',
    'LABEL.APPT.CLIENT.LOCCATION': 'Lieu de l\'intervention',
    'LABEL.FIRST.NAME': 'Prénom',
    'LABEL.ADVISORS.NAME': 'Nom du conseiller',
    'LABEL.ADVISORS.PHONE': 'Téléphone du conseiller',
    'LABEL.TITLE': 'Titre',
    'LABEL.LAST.NAME': 'Nom',
    'LABEL.PHONE.NUMBER': 'Téléphone',
    'LABEL.CLIENT.NAME': 'Nom du client',
    'LABEL.CLIENT.LAST.NAME': 'Nom du client',
    'LABEL.ROLE': 'Fonction',
    'LABEL.ROLE.PLACEHOLDER': 'Sélectionnez un fonction',
    'LABEL.EMAIL': 'Adresse email',
    'LABEL.COLOR': 'Couleur',
    'LABEL.ACCOUNT': 'Statut du compte',
    'LABEL.CONTRACT.NUMBER': 'Numéro de contrat',
    'LABEL.TO': 'Au',
    'LABEL.FROM': 'Du',
    'LABEL.COMPANY.NAME.TITLE': 'Nom de la société',
    'LABEL.BOOK': 'Confirmer',
    'LABEL.APPOINTMENT.LOCATION': 'Lieu de l\'intervention',
    'LABEL.CHOOSE_DAY': 'Sélectionner une date',
    'LABEL.NO.RESULT': 'Aucun résultat',
    'LABEL.NO.OPTION': 'Pas d\'option',
    'LABEL.RESULT.HERE.INFO': 'Les résultats vont s\'afficher ici',
    'LABEL.COMPANY.MANUAL': 'Raison sociale',

    'POPUP.MANAGER.EDIT.HEADER': 'Modifier l\'utilisateur',
    'POPUP.MANAGER.SUCCESS': 'L\'utilisateur a été ajouté',
    'POPUP.MANAGER.SUCCESS.UPDATED': 'L\'utilisateur a été modifié',
    'POPUP.MANAGER.DELETE.HEADER': 'Supprimer l\'utilisateur',
    'POPUP.MANAGER.DELETE.QUESTION': 'Voulez-vous vraiment supprimer cet utilisateur ?',
};
