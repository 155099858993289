<template>
<div class='b-full-page-spin-loader'>
    <FwSpinLoader
        :delay='0.5'
        :size='1.2'
        isActive>
    </FwSpinLoader>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SpinLoader } from '@/components/global/FullPageSpinLoader/nodes/SpinLoader';

@Component({
    components: {
        SpinLoader,
    },
})
export default class FullPageSpinLoader extends Vue {

}

</script>

<style lang='sass'>
.b-full-page-spin-loader
    width: 100%
    height: 100%
    z-index: 12
    display: flex
    top: 0
    left: 0
    align-items: center
    justify-content: center
    position: fixed
</style>
