import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { isUserAuth, user } from '@/helpers/auth';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import { EventBus } from '@/utils/EventBus';
import { User } from '@/types/User';

@Component
export default class CalizyGlobalMixin extends Vue {
    analyticsEvents(text: string) {
        return this.$analytics ? this.$analytics.logEvent(text) : null;
    }
    // @ts-ignore-next-line
    requireImage(path: string): any {
        let image = null;
        try {
            image = require(`@/static/images/${path}`);
        } catch (e) {
            console.log(e);
        }
        return image;
    }

    isUserAuth() {
        return isUserAuth(this.$store);
    }

    user(): User {
        return user(this.$store);
    }

    async getCalendarView() {
        try {
            const data = await CalendarApi.getCalendarViewData();
            this.$store.commit('CALENDAR_SETTINGS_DATA', data.calendar_view_settings[0]);
        } catch ({ response }) {
            console.log(response);
        }
    }

    sentNotif(responseOrId: any, isSuccess?: boolean) {
        if (responseOrId && responseOrId.status === 404) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.404'));
        } else if (responseOrId && responseOrId.status === 401) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.401'));
        } else if (responseOrId && responseOrId.status === 403) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.403'));
        } else if (responseOrId && responseOrId.status === 500) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.500'));
        } else if (!isSuccess && responseOrId && responseOrId.data && responseOrId.data.errors) {
            const { errors } = responseOrId.data;
            if (Array.isArray(errors)) {
                EventBus.$emit(`sent-notification`, this.$i18n.t(errors.join(`\n`)));
            } else {
                EventBus.$emit(`sent-notification`, this.$i18n.t(errors));
            }
        } else if (responseOrId) {
            EventBus.$emit(`sent-notification`, this.$i18n.t(responseOrId), isSuccess);
        }
    }
};
