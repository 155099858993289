export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Add a slot',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Reserved slot',
    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Change the unavailability',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Add an unavailability',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Past unavailability',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'ALL DAY',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'MORNING',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'AFTERNOON',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'NO RECURRENCE',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'END RECURRENCE',

    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.CHOOSE.TITLE': 'Consult reserved slots',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.CHOOSE.TITLE': 'Consult unavailabilities',

    'ADD.RESERVED.SLOT.FORM.TO': 'to',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'EVERY DAY',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'EVERY WEEK',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'EVERY MONTH',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'EVERY YEAR',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'CHOOSE A DATE',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Week of the month',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'FIRST WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'SECOND WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': '3 WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': '4 WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'LAST WEEK',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOOSE A TITLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'INVENTORY',
    'ADD.RESERVED.SLOT.FORM.TITLE.INACTIVE': 'INACTIVE',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'DAY OFF',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'TRAINING',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'UNAVAILABLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.STOPPAGE': 'CLOSURE',
};
