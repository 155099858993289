<template>
<div class='h-mt-30'>
    <form class='b-add-company__form'
          @keyup.enter='onSubmit'>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_FIRST_NAME)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_FIRST_NAME]}*`'>
                <FwInput
                    :value='first_name'
                    type='text'
                    name='text'
                    :propsKeyDownMethod='noNumbersAndCharactersMethod'
                    inputStyle='white-bigger'
                    placeholder='ex: John'
                    @update:value='value => first_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_LAST_NAME)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_LAST_NAME]}*`'>
                <FwInput
                    :value='last_name'
                    type='text'
                    name='text'
                    :propsKeyDownMethod='noNumbersAndCharactersMethod'
                    inputStyle='white-bigger'
                    placeholder='ex: Doe'
                    @update:value='value => last_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input b-add-company__form__input--big'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_PHONE)'
                labelType='label-medium'
                :title='formTitlesMapping[WORKERS_INFORMATION_PHONE]'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    inputStyle='white-bigger'
                    placeholder='ex: 06 20 45 30 79'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => phone = value'>
                    <template #left>
                        <div class='b-phone-with-code fw-select-white fw-select-base__time'>
                            <FwSelect
                                v-model='phoneCode'
                                :disabled='true'
                                noElementsFoundText='no code'
                                type='phone-with-code'
                                :showRightIcon='false'
                                :canNotDelete='true'>
                            </FwSelect>
                        </div>
                    </template>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input b-add-company__form__input--big'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_EMAIL)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_EMAIL]}**`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    inputStyle='white-bigger'
                    placeholder='ex: adress@email.com'
                    :error='getFieldErrorText(WORKERS_INFORMATION_EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(WORKERS_INFORMATION_EMAIL)'
                    @blur='addCurrentInputToValidateArray(WORKERS_INFORMATION_EMAIL)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row' style='margin-bottom: 0'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_COMPANY)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_COMPANY]}**`'>
                <div class='fw-select-base' style='max-height: 300px'>
                    <FwSelect
                        v-model='value.title'
                        class='fw-select-with-icon--big'
                        :propsPlaceholder='$t(`COMPANIES.CHOOSE.PLACEHOLDER`)'
                        :pagination='true'
                        type='white'
                        :canNotDelete='true'
                        label='title'
                        :allowEmpty='true'
                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                        :paginationPerPage='perPage'
                        :options='companiesOption'
                        @focus.once='getCompanies'
                        @select='choosedCompany'>
                        <template v-slot:option='{ props }'>
                            <div v-if='selectorLoader' class='h-width-100p h-flex h-flex-center'>
                                <FwRoundLoader
                                    :size='50'
                                    :borderSize='4'
                                    borderColor='#27dbbd'>
                                </FwRoundLoader>
                            </div>
                            <slot
                                v-else
                                :props='props'
                                name='option'>
                                <div>
                                    {{ props.option.title }}
                                </div>
                            </slot>
                        </template>
                    </FwSelect>
                </div>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-ph-15'
                labelType='label-medium'
                :title='`${$t(`LABEL.SKILLS`)}***`'>
                <div class='fw-select-base'>
                    <FwSelect
                        v-model='skillChoosed'
                        class='fw-select-with-icon--big'
                        :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                        type='white'
                        :searchable='false'
                        :disabled='!companyId'
                        :loading='isCompanyLoading'
                        label='fr_name'
                        :multiple='true'
                        :clearable='false'
                        :withCircles='true'
                        :canNotDelete='false'
                        :options='skills'>
                        <template #noOptions>
                            {{ $t('LABEL.NO.OPTION') }}
                        </template>
                    </FwSelect>
                </div>
            </FwFormInput>
        </div>
        <div v-if='companyId' class='b-form-hint__wrapper'>
            <div class='h-flex h-flex-center'>
                <p class='h-mv-0 h-mh-25 h-font-16 h-fw-500'>
                    {{ $t('WORKER.POPUP.ADMIN.TEXT') }}
                </p>
                <FwSwitcher
                    :isActive='isAdmin'
                    @toggleSwitch='toggleStatus'>
                </FwSwitcher>
            </div>
        </div>
    </form>
    <div class='h-flex h-flex-space-between'>
        <div class='b-form-hint__wrapper'>
            <div class='b-form-hint'>
                * {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
            </div>
            <div class='b-form-hint'>
                ** {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
            </div>
            <div class='b-form-hint'>
                *** {{ $t('LABEL.SELECT.COMPANY.FIRST') }}
            </div>
        </div>
    </div>
    <div class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30'
            :disabled='!isFormValid || !companyId || !valid || isRequestSending'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop, Watch, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

import ValidationMixin from '@/mixins/validation';

import { CompanyData } from '@/types/Companies';
import { WorkersInformationFormType } from '@/types/Workers';
import {
    CategorySkillNameType,
    CategoryLocationSkillNameType,
} from '@/types/Appointment';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { WorkersWebApi } from '@/api/workers/WorkersApi';

import { AVAILABLE_COLORS } from '@/helpers/colors';
import { companyDataProps } from '@/helpers/company';
import {
    WORKERS_INFORMATION_FIRST_NAME,
    WORKERS_INFORMATION_LAST_NAME,
    WORKERS_INFORMATION_PHONE,
    WORKERS_INFORMATION_COLOR,
    WORKERS_INFORMATION_COMPANY,
    WORKERS_INFORMATION_EMAIL,
    WORKERS_INFORMATION_EMAIL_TITLE,
    WORKERS_INFORMATION_LABEL,
    WORKERS_INFORMATION_CODE,
    WORKERS_COMPANY_ADMIN,
} from '@/helpers/workers';

@Component({
    validators: {
        [WORKERS_INFORMATION_EMAIL](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class AddWorkersForm extends Mixins(ValidationMixin) {
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    selectorLoader: boolean = true;
    isAdmin: boolean = false;
    isTakingAppointments: boolean = true;
    isCompanyLoading: boolean = false;
    value: string = '';
    companyId: string = '';
    skillChoosed: Array<CategorySkillNameType> = [];
    skills: Array<CategorySkillNameType> = [];
    [WORKERS_INFORMATION_FIRST_NAME]: string = '';
    [WORKERS_INFORMATION_LAST_NAME]: string = '';
    [WORKERS_INFORMATION_PHONE]: string = '';
    [WORKERS_INFORMATION_COLOR]: string = AVAILABLE_COLORS[Math.floor(Math.random() * AVAILABLE_COLORS.length)];
    [WORKERS_INFORMATION_LABEL]: string = '';
    [WORKERS_INFORMATION_COMPANY]: string = '';
    [WORKERS_INFORMATION_EMAIL]: string = '';
    [WORKERS_INFORMATION_CODE]: string = '';
    [WORKERS_COMPANY_ADMIN]: boolean | undefined = false;
    phoneCode: string = '+33';
    WORKERS_INFORMATION_FIRST_NAME: string = WORKERS_INFORMATION_FIRST_NAME;
    WORKERS_INFORMATION_LAST_NAME: string = WORKERS_INFORMATION_LAST_NAME;
    WORKERS_INFORMATION_PHONE: string = WORKERS_INFORMATION_PHONE;
    WORKERS_INFORMATION_COMPANY: string = WORKERS_INFORMATION_COMPANY;
    WORKERS_INFORMATION_LABEL: string = WORKERS_INFORMATION_LABEL;
    WORKERS_INFORMATION_EMAIL: string = WORKERS_INFORMATION_EMAIL;
    WORKERS_INFORMATION_CODE: string = WORKERS_INFORMATION_CODE;
    AVAILABLE_COLORS: Array<string> = AVAILABLE_COLORS;

    companiesOption: Array<CompanyData> | null = [
        companyDataProps,
    ];
    currentCompaniesArray: Array<CompanyData> | null = null;
    companiesArrayPagination: Array<CompanyData> | null = null;

    formTitlesMapping: { [key: string]: TranslateResult | string } = {};

    serverErrors: serverErrors = {
        [WORKERS_INFORMATION_EMAIL]: this.errorFromServer,
    };
    get valid() {
        let result = null;
        if (this.first_name || this.last_name) {
            result = true;
        }
        return result;
    }
    get phoneNum() {
        return this[WORKERS_INFORMATION_PHONE] ? this.phoneCode + this[WORKERS_INFORMATION_PHONE] : this[WORKERS_INFORMATION_PHONE];
    }
    async getCompanies() {
        const data = await AppointmentWebApi.getSocietyData();
        this.selectorLoader = false;
        this.companiesOption = data.companies;
    }
    async getSkills(id: string) {
        try {
            this.isCompanyLoading = true;
            const data = await WorkersWebApi.fetchSkillNames(id);
            this.skills = data.company_skill_names;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isCompanyLoading = false;
        }
    }
    toggleStatus() {
        this.isAdmin = !this.isAdmin;
    }

    choosedCompany(val: any) {
        if (this.companiesOption) {
            this.value = val.title;
            this.companyId = val.id;
            this.getSkills(this.companyId);
        }
    }

    created() {
        this.formTitlesMapping = {
            [WORKERS_INFORMATION_FIRST_NAME]: this.$i18n.t('WORKERS.FORM.INPUT.FIRST_NAME.LABEL'),
            [WORKERS_INFORMATION_LAST_NAME]: this.$i18n.t('WORKERS.FORM.INPUT.LAST_NAME.LABEL'),
            [WORKERS_INFORMATION_PHONE]: this.$i18n.t('WORKERS.FORM.INPUT.PHONE.LABEL'),
            [WORKERS_INFORMATION_LABEL]: this.$i18n.t('WORKERS.FORM.INPUT.LABEL.LABEL'),
            [WORKERS_INFORMATION_COMPANY]: this.$i18n.t('WORKERS.FORM.INPUT.COMPANY.LABEL'),
            [WORKERS_INFORMATION_CODE]: 'IMA Protect ID',
            [WORKERS_INFORMATION_EMAIL]: WORKERS_INFORMATION_EMAIL_TITLE,
        };
    }
    @Watch('errorFromServer')
    checkEmailError() {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                [WORKERS_INFORMATION_EMAIL]: this.$i18n.t('WORKER.EMAIL.TAKEN'),
            };
        }
    }
    @Watch('companiesOption')
    checkCompaniesOption() {
        return this.companiesOption;
    }
    @Emit('onSubmit')
    onSubmit(): WorkersInformationFormType {
        const skill_ids = this.skillChoosed.map(item => item.id);
        return {
            [WORKERS_INFORMATION_FIRST_NAME]: this[WORKERS_INFORMATION_FIRST_NAME],
            [WORKERS_INFORMATION_LAST_NAME]: this[WORKERS_INFORMATION_LAST_NAME],
            [WORKERS_INFORMATION_PHONE]: this.phoneNum,
            [WORKERS_INFORMATION_LABEL]: this[WORKERS_INFORMATION_LABEL],
            [WORKERS_INFORMATION_COLOR]: this[WORKERS_INFORMATION_COLOR],
            [WORKERS_INFORMATION_COMPANY]: this.companyId,
            [WORKERS_INFORMATION_EMAIL]: this[WORKERS_INFORMATION_EMAIL],
            [WORKERS_INFORMATION_CODE]: this[WORKERS_INFORMATION_CODE],
            [WORKERS_COMPANY_ADMIN]: this.isAdmin,
            is_taking_appointments: this.isTakingAppointments,
            skill_ids,
        };
    }
}
</script>

<style lang='sass'>
.b-add-company__form
    .b-phone-with-code
        width: 100px
        .fw-select-phone-with-code
            height: 100%
            .fw-select__caret
                z-index: 0
                right: 20px!important
            .multiselect__tags
                padding: 0 22px!important
                width: 100px
        .multiselect__tags
            background-color: transparent !important
    .fw-input__wrapper--with-left-block .fw-input
        padding-left: 110px!important
    .icon-white__background--transparent
        padding: 0!important
        margin-left: 15px
        min-height: 0
    .icon-white__background p, .icon-white__background--transparent p
        max-width: 100%!important
        order: 2
        padding-right: 10px
    .multiselect__single
        font-weight: 400
    .multiselect__placeholder
        font-weight: 400!important
        font-size: 16px!important
</style>
