<template>
<div class='h-width-100p b-form-tabs-main'>
    <div v-if='getCurrentDate || dates'
         class='h-mb-40 h-text-center'>
        <div class='h-flex h-flex-center'>
            <div class='b-calendar-select__week fw-select-base'>
                <FwDatepicker
                    v-model='dateFrom'
                    name='datepicker'
                    :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                    :propsRange='false'
                    :propsLang='`${$i18n.locale}`'
                    :selectMode='false'
                    pickerStyle='static'
                    propsType='date'
                    propsFormat='MMM DD, YYYY'
                    :openOnTop='false'
                    :disabled='true'
                    :disabledDate='disabledDateByObject'
                    propsInputClass='b-add-reserved-slot-form__datepicker'>
                    <template #icon>
                        <FwIcon
                            class='h-flex'
                            icon='calendar'
                            size='16'
                            color='#203f6a'>
                        </FwIcon>
                    </template>
                </FwDatepicker>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='fw-select-base
                        fw-select-base__time
                        h-flex h-flex-center
                        h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.FROM') }}
                </h6>
                <div style='min-width: 120px' class='b-calendar-select__week'>
                    <FwSelect
                        v-model='timeFrom'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :disabled='isDayLessThenCurrent'
                        :options='timeFromArray'>
                    </FwSelect>
                </div>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='fw-select-base
                        fw-select-base__time
                        h-flex h-flex-center
                        h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.TO') }}
                </h6>
                <div style='min-width: 120px' class='b-calendar-select__week'>
                    <FwSelect
                        v-model='timeTo'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :disabled='true'
                        :options='timeToArray'>
                    </FwSelect>
                </div>
            </div>
        </div>
    </div>
    <div class='b-form-tabs h-mb-50'>
        <div
            v-for='tab in tabs'
            :key='tab.name'
            class='b-form-tabs__tab h-fw-500 h-flex-1 h-text-center'
            :class='{ "b-form-tabs__active-tab": tab.isActive }'
            @click='selectTab(tab)'>
            {{ tab.name }}
        </div>
    </div>

    <component :is='currentComponent' :appointmentData='appointmentData' >
    </component>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { clone } from 'ramda';
import { AppointmentForm } from '@/components/forms/readable/AppointmentForm';
import { AppointmentSearchType } from '@/types/Appointment';
import {
    dateAsEventTime,
    FULL_TIME_EVERY_15,
    getHoursAndMinutesFromAMPM,
    dateToString,
} from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { AppointmentClientForm } from '@/components/forms/readable/AppointmentClientForm';
import i18n from '@/locale';

interface AppointmentTabType {
    name: string,
    component: string,
    isActive: boolean,
}

@Component({
    components: {
        AppointmentForm,
        AppointmentClientForm,
    },
})
export default class FormTabs extends Mixins(DateMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: Boolean, default: false }) readonly isDayLessThenCurrent!: boolean;
    currentComponent: string = 'AppointmentRecallForm.vue';
    tabs: Array<AppointmentTabType> = [{
        name: i18n.tc('PAST.APPOINTMENT.DETAILS.TITLE'),
        component: 'AppointmentForm',
        isActive: true,
    }, {
        name: i18n.tc('CONSULT.APPOINTMENT.CLIENT.DETAILS'),
        component: 'AppointmentClientForm',
        isActive: false,
    }];
    dateFrom: string = '';
    timeFrom: string = '';
    timeTo: string = '';

    get timeFromArray(): Array<string> {
        return FULL_TIME_EVERY_15.map(time => i18n.t(time));
    }
    get timeToArray() {
        const updatedArray = clone(this.timeFromArray);
        const index = updatedArray.findIndex(i => i === this.timeFrom);
        const durationValue = (this.appointmentData.duration as number) / 15;
        const result = updatedArray.splice(index + durationValue, updatedArray.length - 1);
        this.timeTo = result[0];
        this.updateTime();

        return result;
    }

    get getCurrentDate() {
        return dateAsEventTime(new Date(this.appointmentData.dt_start as string), i18n.locale);
    }
    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentData.dt_start as string)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentData.dt_end as string)),
        ];
    }
    get startDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeFrom as string));
        return new Date(new Date(this.dateFrom).setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get endDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeTo as string));
        return new Date(new Date(this.dateFrom).setHours(timeData.hours, timeData.minutes, 0, 0));
    }
    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }

    selectTab(selectedTab: AppointmentTabType) {
        this.tabs.forEach(tab => {
            if (tab.name === selectedTab.name) {
                tab.isActive = true;
                this.currentComponent = tab.component;
            } else {
                tab.isActive = false;
            }
        });
    }
    created() {
        const initialTab: AppointmentTabType = {
            name: i18n.tc('PAST.APPOINTMENT.DETAILS.TITLE'),
            component: 'AppointmentForm',
            isActive: true,
        };
        this.selectTab(initialTab);
        if (this.appointmentData) {
            this.$store.dispatch('storeAppointmentData', this.appointmentData);
            this.dateFrom = this.appointmentData.dt_start as string;
            this.timeFrom = this.formatAMPM(new Date(this.appointmentData.dt_start as string));
            this.timeTo = this.formatAMPM(new Date(this.appointmentData.dt_end as string));
        }
    }

    @Emit('updateTime')
    updateTime() {
        return {
            dt_start: dateToString(this.startDate),
            dt_end: dateToString(this.endDate),
        };
    }
}
</script>

<style lang='sass'>
    .b-form-tabs-main
        .b-calendar-select__week
            background: rgba(216, 216, 216, 0.3)
            border-radius: 4px
        .b-form-tabs
            display: flex
            justify-content: center
            align-items: center
            color: #b5c0ce
            &__active-tab
                position: relative
                display: flex
                justify-content: center
                align-items: center
                flex-flow: column nowrap
                color: $dark-blue
                &:after
                    position: absolute
                    content: ''
                    top: 30px
                    height: 2px
                    width: 40px
                    background: $dark-blue
            &__tab
                cursor: pointer
                max-width: 220px
</style>
