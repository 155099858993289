<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-location-popup'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='500px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div v-if='companyData.longitude && companyData.latitude' class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mv-0 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.TITLE') }}
                </h2>
                <div ref='map' class='h-mt-40' style='width: 500px; height: 280px;'></div>
                <div class='h-flex h-flex-center'>
                    <FwIcon
                        class='h-flex-center h-pointer'
                        icon='location-icon'
                        size='28'
                        color='rgba(33,63,107)'>
                    </FwIcon>
                    <p class='h-font-18 h-fw-500 h-ml-20 h-mv-30'>
                        {{ companyData.address }}
                    </p>
                </div>
            </div>
            <div v-else>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.FAILED.TITLE') }}
                </h2>
                <p class='h-font-18 h-ml-20 h-mv-30 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.FAILED.TEXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div v-if='companyData.longitude && companyData.latitude' class='h-flex h-flex-center h-flex-dir-column'>
                <FwButton
                    class='h-width-300'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='$emit(`confirmAddress`, companyData)'>
                    {{ $t('COMPANY.POPUP.LOCATION.BUTTON.CONFIRM') }}
                </FwButton>
                <FwButton
                    class='h-mt-20 h-font-18 h-width-300'
                    color='cancel'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='$emit(`closeLocation`)'>
                    {{ $t('COMPANY.POPUP.LOCATION.BUTTON.CANCEL') }}
                </FwButton>
            </div>
            <FwButton
                v-else
                borderRadiusType='small-border'
                @click='$emit(`closeLocation`)'>
                {{ $t('COMPANY.POPUP.LOCATION.FAILED.BUTTON') }}
            </FwButton>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { CompanyUpdateAddressType } from '@/types/Workers';

declare let google: any;

@Component
export default class LocationPopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Object, default: null }) readonly companyAddressData!: CompanyUpdateAddressType;
    @Emit('goToPage')
    goToPage() {
        return true;
    }
    get storeCompanyData() {
        return this.$store.state.CalendarPageStore.company;
    }
    get companyData() {
        return this.companyAddressData ? this.companyAddressData : this.storeCompanyData;
    }
    initMap() {
        if (this.companyData.longitude && this.companyData.latitude) {
            const mapOptions = {
                zoom: 16,
                center: { lat: this.companyData.latitude, lng: this.companyData.longitude },
                disableDefaultUI: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };
            const mapView = new google.maps.Map(
                this.$refs.map,
                mapOptions
            );

            const marker = new google.maps.Marker({
                position: { lat: this.companyData.latitude, lng: this.companyData.longitude },
                map: mapView,
            });

            google.maps.event.addListener(window, 'load', this.initMap);
        }
    }
    // created() {
    //     const googleMap = document.createElement(`script`);
    //     googleMap.type = 'text/javascript';
    //     googleMap.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCPneC9b0KQmNmeEEu9ABKkSgELV8aKjCw&libraries=places&callback=initMap';
    //     googleMap.async = true;
    //     googleMap.defer = true;
    //     document.head.appendChild(googleMap);
    // }
    mounted() {
        this.initMap();
    }
}
</script>
<style lang='sass'>
    .b-location-popup
        .fw-button--cancel
            font-size: 16px
        .gmnoprint a, .gmnoprint span, .gm-style-cc
            display: none
</style>
