const CONSULT_PREFIX = '/consult';
const ADD_COMPANIES_PREFIX = '/add-companies';
const ADD_WORKERS_PREFIX = '/add-technicians';
const WORKERS_PREFIX = '/technicians';
const COMPANIES_PREFIX = '/companies';

export {
    CONSULT_PREFIX,
    ADD_COMPANIES_PREFIX,
    ADD_WORKERS_PREFIX,
    WORKERS_PREFIX,
    COMPANIES_PREFIX,
};
