export default {
    'ADD.RESERVED.SLOT.POPUP.AT_LEAST_10_DAYS.TEXT': 'Reserved slot must be at least 10 days ahead',

    'ADD.RESERVED.SLOT.POPUP.DELETE.BASE': 'Yes, delete',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL': 'Delete all unavailabilities',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS': 'Delete this unavailability',
    'ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL': 'CANCEL',

    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ONLY_THIS': 'Delete this reserved slot',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL': 'Delete all reserved slots',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE': 'Delete all future unavailabilities',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL.FUTURE': 'Delete all future reserved slots',

    'ADD.RESERVED.SLOT.POPUP.DELETE.TITLE': 'Delete unavailability',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE': 'Do you really want to delete this unavailability?',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECCURENCE': 'Are you sure you want to delete this unavailability? ' +
        'This is a recurrent unavailability',

    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.TITLE': 'Delete reserved slot',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE': 'Do you really want to delete this reserved slot?',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE_RECCURENCE': 'Are you sure you want to delete this reserved slot? ' +
        'This is a recurrent reserved slot',
};
