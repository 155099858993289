<template>
<div class='b-plan-table__add_an_appointment'
     :class='{ "b-plan-table__add_an_appointment--full-day": !secondHalfTime }'>
    <div v-if='fristHalfTime'
         class='b-plan-table__add_an_appointment__item'
         @click='currentHalf(fristHalfTime)'>
        <span class='h-mr-3'>
            {{ fristHalfTime }}
        </span>
        <span>
            {{ $t('CALENDAR.POPUP.ADD.APPOINTMENT.TITLE') }}
        </span>
    </div>
    <div v-if='secondHalfTime'
         class='b-plan-table__add_an_appointment__item'
         @click='currentHalf(secondHalfTime)'>
        <span class='h-mr-3'>
            {{ secondHalfTime }}
        </span>
        <span>
            {{ $t('CALENDAR.POPUP.ADD.APPOINTMENT.TITLE') }}
        </span>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AppointmentDayInfoPopup extends Vue {
    @Prop({ type: String, default: null }) readonly fristHalfTime!: string;
    @Prop({ type: String, default: null }) readonly secondHalfTime!: string;
    @Prop({ type: String, default: null }) readonly choosedDay!: string;
    currentHalf(half: string) {
        this.$emit('openAddAppointmentPopup', this.choosedDay, half);
    }
}
</script>
<style lang='sass'>
.b-plan-table__add_an_appointment
    width: 100%
    max-width: 100%
    color: #27DBBD
    background-color: #E9FBF8
    border-radius: 5px
    font-size: 9px
    line-height: 14px
    padding: 5px 3px
    cursor: pointer
    position: absolute
    left: 0
    top: 5px
    height: 100%
    z-index: 1

    &__item
        width: 100%
        display: flex
        opacity: 0
        height: 50%
        border: 1px dashed
        align-items: center
        justify-content: center
        &:hover
            opacity: 1

    &--full-day
        .b-plan-table__add_an_appointment__item
            height: 100%
            border: none
            flex-direction: column
</style>
