import GlobalStore from './GlobalStore';
import CommonStore from './CommonStore';
import CalendarPageStore from './CalendarPageStore';
import SettingsPageStore from './SettingsPageStore';
import WorkerPageStore from './WorkerPageStore';
import WorkersPageStore from './WorkersPageStore';
import AppointmentStore from './AppointmentStore';

export default {
    GlobalStore,
    CommonStore,
    CalendarPageStore,
    SettingsPageStore,
    WorkerPageStore,
    WorkersPageStore,
    AppointmentStore,
};
