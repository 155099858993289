import axios from 'axios';
import { EditCompanyData, CompanyData } from '@/types/Companies';
import {
    BookAppointmentDataType,
    BookAppointmentManuallyType,
    EventsAppointmentsResponseType,
    AppointmentDataType,
    CategorySkillNamesResponseType,
    AppointmentSearchPayloadType,
} from '@/types/Appointment';
import { CompanyUpdateAddressType } from '@/types/Workers';
import WebApi from '../WebApi';
import { updateUrlGetParams } from '@/helpers/url';

interface companyData {
    reference: string,
    app_type: string,
    account_id?: string | number,
    user_id?: string | number,
    dt_start?: string | Date,
    indirect?: boolean,
    contact_1?: string,
    contact_2?: string,
}
interface refreshData {
    appointment_id: string,
    dt_start?: string,
    user_id?: string,
}

interface companyCreateData {
    contact_email: string,
    title: string,
    address: string,
    siret: string,
    is_address_confirmed: boolean,
    phone?: string
    insee_code?: string
    siren?: string
}

interface pageData {
    from: string | number | Date | null,
    to: string | number | Date | null,
    company_id: string | number | null,
}

interface pageWorkerData {
    from: string | number | Date | null,
    to: string | number | Date | null,
    user_id: string | number,
}

type AppointmentMonthDatesPayloadType = {
    appointment_id: string
    company_id: string
    from: string
    to: string
}
interface pagePaginationData {
    per_page: number,
    current_page: number,
    sort?: boolean,
}

class AppointmentWebApi extends WebApi {
    async sendInterventionData(appointment: companyData) {
        const { data } = await axios.post(
            `${this.urlPrefix}/appointments/build`,
            { appointment },
        );
        return data;
    }

    async getCompanyAppointmentsDates(payload: pageData): Promise<{ data: any }> {
        const { company_id, from, to } = payload;
        return axios.get(
            `${this.urlPrefix}/appointments/fetch_dates`,
            {
                params: {
                    company_id,
                    from,
                    to,
                },
            },
        );
    }

    async getWorkerAppointmentsDates(payload: pageWorkerData): Promise<{ data: any }> {
        const { user_id, from, to } = payload;
        return axios.get(
            `${this.urlPrefix}/appointments/fetch_dates`,
            {
                params: {
                    user_id,
                    from,
                    to,
                },
            },
        );
    }

    async getAppointmentsMonthDates(params: AppointmentMonthDatesPayloadType): Promise<{ data: any }> {
        return axios.get(
            `${this.urlPrefix}/availabilities/timeslot_dates`,
            {
                params,
            },
        );
    }

    async getAppointmentsData(payload: pageData): Promise<{ data: EventsAppointmentsResponseType }> {
        const { company_id, from, to } = payload;
        const urlParams = `?pagination[per_page]=100`;
        return axios.get(
            `${this.urlPrefix}/appointments${urlParams}`,
            {
                params: {
                    company_id,
                    from,
                    to,
                },
            },
        );
    }

    async getInterventionCancelData(appointment: companyData) {
        const payload = `${appointment.app_type.toLowerCase()}_${appointment.reference}`;
        const { data } = await axios.get(`${this.urlPrefix}/appointments/${payload}/fetch`);
        return data;
    }

    async getSocietyData() {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/company_titles`);
        return data;
    }

    async getSocietyDataByIma(address: string) {
        const { data } = await axios.get(`${this.urlPrefix}/searches/companies?address=${address}`);
        return data;
    }

    async getSocietyPaginationData(payload: pagePaginationData) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/companies${urlParams}`);
        return data;
    }

    async getSocietyDataBySearch(payload: pagePaginationData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/companies?search=${searchValue}${urlParams}`);
        return data;
    }

    async getCompanyByAddress(address: string | null = null, skill_ids: Array<string>) {
        const params = {
            ...address ? { address } : {},
            skill_ids,
        };
        const { data } = await axios.get(`${this.urlPrefix}/searches/companies`, {
            params,
        });
        return data;
    }

    async createCompany(company: companyCreateData) {
        return axios.post(
            `${this.urlPrefix}/companies`,
            { company },
        );
    }

    async deleteCompany(id: string | number) {
        return axios.delete(`${this.urlPrefix}/companies/${id}`);
    }

    async getSocietyItemData(id: string): Promise<{ company: CompanyData }> {
        const { data } = await axios.get(`${this.urlPrefix}/companies/${id}`);
        return data;
    }

    async changeCompanyData(company: EditCompanyData, companyId: number | string) {
        return axios.put(
            `${this.urlPrefix}/companies/${companyId}`,
            { company },
        );
    }

    async getAppointmentData(id: string): Promise<AppointmentDataType> {
        const { data } = await axios.get(`${this.urlPrefix}/appointments/${id}`);
        return data.appointment;
    }

    async bookAppointment(payload: BookAppointmentDataType, id: string | number) {
        return axios.put(
            `${this.urlPrefix}/appointments/${id}/book`,
            {
                'appointment': payload,
            }
        );
    }

    async bookAppointmentManually(payload: BookAppointmentManuallyType): Promise<AppointmentDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/direct_appointments`,
            {
                'appointment': payload,
            }
        );
        return data.appointment;
    }

    async changeAppointmentTime(payload: BookAppointmentManuallyType, id: string): Promise<AppointmentDataType | null> {
        const { data } = await axios.put(
            `${this.urlPrefix}/appointments/${id}/update_dates`,
            {
                'appointment': payload,
            }
        );
        return data.appointment;
    }

    async cancelAppointment(appointment: EditCompanyData, appointmentId: number | string) {
        return axios.put(
            `${this.urlPrefix}/appointments/${appointmentId}/cancel`,
            { appointment },
        );
    }

    async confirmAppointment(appointmentId: number | string) {
        return axios.put(
            `${this.urlPrefix}/appointments/${appointmentId}/confirm`,
        );
    }

    async sendAddressForGeolocation(geolocation: CompanyUpdateAddressType) {
        return axios.post(
            `${this.urlPrefix}/geolocations`,
            { geolocation },
        );
    }

    async getAppointmentsBySearch(payload: AppointmentSearchPayloadType) {
        const url = updateUrlGetParams(`${this.urlPrefix}/searches/ima_appointments`, {
            'pagination[per_page]': payload.per_page || 10,
            'pagination[page]': payload.current_page || 1,
            ...(payload.client_last_name && { client_last_name: payload.client_last_name }),
            ...(payload.from && { from: payload.from }),
            ...(payload.to && { to: payload.to }),
            ...(payload.technician_last_name && { technician_last_name: payload.technician_last_name }),
            ...(payload.company_title && { company_title: payload.company_title }),
            ...(payload.contract_id && { contract_id: payload.contract_id }),
            ...(payload.intervention_order && { intervention_order: payload.intervention_order }),
        });
        const { data } = await axios.get(url);
        return data;
    }

    async getAppointment(id: string) {
        const { data } = await axios.get(`${this.urlPrefix}/appointments/${id}`);
        return data;
    }

    async getAdvisorTechLastAutocomplete(type: string, searchValue: string) {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/${type}_last_name_autocomplete?text=${searchValue}`);
        return data;
    }

    async getBranchTitlesAutocomplete(type: string, searchValue: string) {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/${type}_titles_autocomplete?text=${searchValue}`);
        return data;
    }

    async fetchSkillNames(): Promise<CategorySkillNamesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/skill_names`);
        return data;
    }
}

const appointmentApi = new AppointmentWebApi();

export {
    appointmentApi as AppointmentWebApi,
};
