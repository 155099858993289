export default {
    'PLAN.KIND.APPOINTMENT': 'What kind of appointment',
    'PLAN.CALENDAR.APPOINTMENT.HEADER': 'Plan an appointment',
    'PLAN.CHOOSE.APPOINTMENT': 'Choose an appointment',
    'PLAN.HEADER': 'Intervention code',
    'PLAN.IWS.CODE': 'IWS code',
    'PLAN.COHERIS.CODE': 'Coheris code',
    'PLAN.SOCIETY.NAME': 'Company selection',
    'PLAN.SOCIETY.LABEL': 'Company name',
    'PLAN.SOCIETY.PLACEHOLDER': 'Search a company',
    'PLAN.SOCIETY.NO.ELEMENT.FOUND': 'No elements found. Consider changing the search query',
    'PLAN.APPOINTMENT.DELAY.TXT': 'Beware, this appointment requires a minimum scheduling delay of {delay_value} days',
    'APPOINTMENT.SUCCESS.POPUP.TXT': 'Appointment booked successfully',
    'POPUP.SUCCESS.HEADER': 'Success!',
};
