import Vue from 'vue';
import { uniqBy, prop } from 'ramda';
import { InstanceAppointmentsWithSlotsType } from '@/types/Events';
import { AppointmentDataType } from '@/types/Appointment';
import { AvailabilityType } from '@/types/Events/Workers';

export default {
    state: {
        appointment: '',
        location: {},
        calendar_settings_data: null,
        date: {},
        company: '',
        calendar_settings_account: null,
        calendar_reserved_slots: [],
        events: [],
        appointment_exists_period: {
            start: new Date(),
            end: new Date(),
        },
        appointment_reserved_slots_exists: [],
        appointmentCacheData: [],
    },
    mutations: {
        PLAN_APPOINTMENT(state:any, data:any) {
            state.appointment = data;
        },
        PLAN_LOCATION(state:any, data:any) {
            state.location = data;
        },
        PLAN_DATE(state:any, data:any) {
            state.date = data;
        },
        PLAN_COMPANY(state:any, data:any) {
            state.company = data;
        },
        CALENDAR_SETTINGS_DATA(state:any, data:any) {
            state.calendar_settings_data = data;
        },
        CALENDAR_SETTINGS_ACCOUNT(state:any, data:any) {
            state.calendar_settings_account = data;
        },
        CALENDAR_RESERVED_SLOTS(state:any, data: Array< { date: Date, id: number }>) {
            state.calendar_reserved_slots = uniqBy(prop('id'), [...state.calendar_reserved_slots, ...data]);
        },
        CALENDAR_RESERVED_SLOTS_EXISTS(state:any, data: Array<{ date: Date, id: number }>) {
            state.appointment_reserved_slots_exists.push(data);
        },
        CALENDAR_COMMIT_EVENTS(state: any, newEvents: Array<AvailabilityType>) {
            state.events = newEvents;
        },
        UPDATE_CALENDAR_EVENT(state: any, event: InstanceAppointmentsWithSlotsType) {
            const index = state.events.findIndex((item: InstanceAppointmentsWithSlotsType) => event.id === item.id);
            if (index >= 0 && state.events[index]) {
                Vue.set(state.events, index, event);
            }
        },
        CALENDAR_DATE_CLEAR(state: any) {
            state.appointment_exists_period = {
                start: new Date(),
                end: new Date(),
            };
            state.events = [];
            state.calendar_reserved_slots = [];
            state.appointment_reserved_slots_exists = [];
            state.appointmentCacheData = [];
        },
        CACHE_APPOINTMENT(state: any, appointment: AppointmentDataType) {
            const index = state.appointmentCacheData.findIndex((item: AppointmentDataType) => item.id === appointment.id);
            if (~index) {
                Vue.set(state.appointmentCacheData, index, appointment);
            } else {
                state.appointmentCacheData.push(appointment);
            }
        },
        REMOVE_APPOINTMENT(state: any, id: string) {
            let dt_to_compare = '';
            // eslint-disable-next-line
            state.events = state.events.filter((item: AppointmentDataType) => {
                if (item.appointment_id === id) {
                    dt_to_compare = item.dt_start;
                } else {
                    return item;
                }
            });
            state.calendar_reserved_slots = state.calendar_reserved_slots.filter((item: { date: Date, id: number }) => item.date !== new Date(dt_to_compare as string));
        },
    },
};

