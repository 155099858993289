<template>
<div ref='infoBox'
     class='b-worker-time-card'>
    <div class='b-worker-time-card__inner'
         @click='handleClick'>
        <div v-if='duration > 30'
             class='b-worker-time-card__time'>
            {{ time(data.dt_start) }}
        </div>
        <div class='b-worker-time-card__title'>
            <p class='h-m-0'
               :class='{"b-worker-time-card__overflow-text": duration >= 30 }'
               :style='styles'>
                {{ data.title }}
            </p>
        </div>
    </div>
    <slot></slot>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { TimeSlotsCalendarInstancePreparedType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';

@Component({
    refs: [`infoBox`],
})
export default class WorkerTimeCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly data!: TimeSlotsCalendarInstancePreparedType;
    $refs!: {
        infoBox: HTMLElement
    };

    get duration(): number {
        return Math.abs(+new Date(this.data.dt_start) - +new Date(this.data.dt_end)) / 36e5 * 60;
    }

    get styles() {
        if (this.$refs.infoBox && this.duration <= 30) {
            return { width: `${this.$refs.infoBox.offsetWidth - 20}px` };
        }
        return null;
    }

    time(time: string) {
        return this.getCurrentDateAsMinutesAMPM(new Date(time));
    }

    handleClick(event: Event):void {
        this.$emit('click', event);
    }
}
</script>

<style lang='sass'>
.b-worker-time-card
    position: relative
    width: 100%
    display: flex
    padding: 5px 5px 5px 10px
    border-radius: 5px
    background-color: #fff
    overflow: hidden
    box-shadow: $base-box-shadow
    min-width: 100px

    &__inner
        display: flex
        justify-content: flex-start
        flex-flow: column nowrap
        width: 100%

    &__time
        padding: 3px 0
        width: 100%
        display: flex
        font-weight: 500
        font-size: 12px
        align-items: center
        justify-content: center

    &__overflow-text
        width: 140px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__title
        font-size: 12px
        padding: 3px 0
        width: 100%
        font-weight: 400
        display: flex
        justify-content: flex-start
        align-items: flex-start
        line-height: 1.5em
        overflow: hidden
        text-overflow: ellipsis
</style>
