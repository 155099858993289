<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t(headerText) }}
                </h2>
                <p class='h-mt-10 h-mb-20 h-text-center'>
                    {{ $t(bodyText) }}
                </p>
            </div>
        </template>
        <template #footer>
            <div v-if='!technicianLength'
                 class='h-flex h-flex-center h-flex-dir-column'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-10'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='deleteCompany'>
                    {{ $t('SETTINGS.ROLE.DELETE.BUTTON.CONFIRM') }}
                </FwButton>
                <div class='b-company-edit__delete h-mt-20'
                     @click='closePopup'>
                    {{ $t('SETTINGS.ROLE.DELETE.BUTTON.CANCEL') }}
                </div>
            </div>
            <div v-else>
                <FwButton
                    class='h-mt-10'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='$emit("closePopup")'>
                    {{ $t('FINISH.POPUP.SUCCESS.CLOSE') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DeleteCompanyPopup extends Vue {
    @Prop({ type: String, default: 'CONSULT.DELETE.COMPANY.TITLE' }) readonly headerText!: string;
    @Prop({ type: String, default: 'CONSULT.DELETE.COMPANY.SUBTITLE' }) readonly bodyText!: string;
    @Prop({ type: Number, default: null }) readonly technicianLength!: number;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    closePopup() {
        this.$emit('closePopup');
    }
    deleteCompany() {
        this.$emit('deleteCompany');
    }
}
</script>
