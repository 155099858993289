<template>
<div class='b-app-header__wrapper h-pos-rel'>
    <div class='b-app-header__inner'>
        <div
            v-if='!isTablet()'
            class='b-app-header__burger'
            :class='{"active": showMenuMob}'
            @click='showMenuMob = !showMenuMob'>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <router-link
            to='/plan/intervention-code'
            class='b-app-header__logo-wrapper
            b-app-header__aside-part'>
            <img :src='requireImage("logo.svg")'>
        </router-link>
        <div v-if='showMenuMob'
             class='b-app-header__mobile_menu_wrapper'>
            <FwMenu
                itemFontSize='14px'
                itemFontWeight='700'
                itemIconSize='15'
                :menuItems='menuItems'
                :activeId='activeId'
                :hideUnderline='true'>
            </FwMenu>
        </div>
        <div class='b-app-header__profile-wrapper
                    b-app-header__aside-part'>
            <router-link
                to='/profile'
                class='b-app-header__profile-inner'>
                <div class='b-app-header__profile-name'>
                    {{ userData.first_name }}
                </div>
                <div class='b-app-header__profile-image h-flex h-flex-center'
                     :style='profileStyles'>
                    <FwIcon
                        v-if='!userData.avatar_url'
                        class='b-worker__avatar__icon'
                        icon='no-avatar'
                        size='12'
                        color='#fff'>
                    </FwIcon>
                </div>
            </router-link>
        </div>
    </div>
    <div v-if='$route.meta.progressValue'
         class='b-app-header__progress-bar h-pos-abs'
         :style='{ width: `${$route.meta.progressValue}%` }'></div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import i18n from '@/locale';
import { separateColor, hexToRgbA } from '@/helpers/colors';

type MenuItemsType = {
    id: number,
    to: string,
    title: string,
    icon: string,
}

@Component
export default class AppHeader extends Vue {
    showMenuMob: boolean = this.isTablet() || this.isDesktop();
    windowWidth: number | null = null;

    handleResize() {
        this.windowWidth = window.innerWidth;
        this.showMenuMob = this.windowWidth > 768;
    }
    get userData() {
        return this.user();
    }
    get profileStyles() {
        return {
            backgroundImage: `${this.avatarColor}`,
        };
    }
    get menuItems(): Array<MenuItemsType> {
        return [
            {
                id: 1,
                to: '/plan/intervention-code',
                title: i18n.tc('HEADER.PLAN'),
                icon: 'ok-circle',
            },
            {
                id: 2,
                to: '/consult/choose-manage',
                title: i18n.tc('HEADER.CONSULT'),
                icon: 'eye-alt',
            },
            {
                id: 3,
                to: '/cancel/intervention-code',
                title: i18n.tc('HEADER.CANCEL'),
                icon: 'close-circle',
            },
            {
                id: 4,
                to: '/settings',
                title: i18n.tc('HEADER.SETTINGS'),
                icon: 'settings',
            },
        ];
    };
    get avatarColor() {
        let result;
        if (this.userData.color) {
            const colorArray = separateColor(this.userData.color);
            result = this.userData.avatar_url ?
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)},  ${hexToRgbA(colorArray[1], 0.5)}),
            url(${this.userData.avatar_url})` :
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 143, 1))`;
        }
        return result;
    }

    get activeId(): number {
        const reg = new RegExp('^/.+/');
        const item = this.menuItems.find(menu => {
            const matched = menu.to.match(reg);
            const matchedString: string | null = matched && matched.length ? matched[0] : null;
            return matchedString ? this.$route.path.startsWith(matchedString) : false;
        });
        return item ? item.id : 0;
    }

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    @Watch(`$route`)
    showMenuMobHandler() {
        if (this.isMobile() && this.showMenuMob) {
            this.showMenuMob = false;
        }
    }
}
</script>

<style lang='sass'>
.b-app-header
    &__progress-bar
        bottom: 0
        border-bottom: 3px solid $aqua-marine
        transition: width 0.4s ease-in-out

    &__burger
        display: none

    &__wrapper
        height: $header-height
        width: 100%
        background-color: #fff
        display: flex
        flex-direction: column
        justify-content: center

    &__inner
        height: 90px
        display: flex
        align-items: center
        justify-content: space-between

    &__aside-part
        flex: 0 0 25%
        height: 100%
        display: flex
        align-items: center

    &__logo-wrapper
        padding-left: 100px

    &__profile-wrapper
        padding-right: 100px
        justify-content: flex-end

    &__profile-inner
        display: flex
        align-items: center
        cursor: pointer

        &:hover
            .b-app-header__profile-name
                text-shadow: 0 3px 7px rgba(0, 0, 0, 0.12)

            .b-app-header__profile-image
                box-shadow: $middle-box-shadow
        &.router-link-active
            .b-app-header__profile-name
                font-weight: bold

    &__profile-name
        font-size: 16px
        font-weight: 500
        margin-right: 20px
        transition: text-shadow 0.3s
        color: $alt-blue

    &__profile-image
        width: 40px
        height: 40px
        border-radius: 50%
        background-color: $gray
        background-size: cover
        background-position: center
        transition: box-shadow 0.3s

@include media('<tablet')
    .b-app-header
        &__burger
            display: flex
            position: relative
            z-index: 31
            width: 100px
            height: 20px
            border: 0
            background: none
            cursor: pointer
            div
                margin-left: 20px
                position: absolute
                left: 0
                background: #27dbbd
                width: 30px
                height: 3px
                transition: transform .2s ease, top .2s ease .2s, bottom .2s ease .2s, opacity .2s ease .2s
                &:nth-child(1)
                    top: 0
                &:nth-child(2)
                    top: calc(50% - 2px)
                &:nth-child(3)
                    bottom: 0
            &.active
                div
                    transition: transform .2s ease .2s, top .2s ease, bottom .2s ease, opacity .2s ease
                    &:nth-child(1)
                        transform: rotate(45deg)
                        top: calc(50% - 2px)
                    &:nth-child(2)
                        opacity: 0
                    &:nth-child(3)
                        transform: rotate(-45deg)
                        bottom: calc(50% - 2px)
        &__logo-wrapper
            padding-left: 0

        &__profile-wrapper
            padding-right: 20px
    .fw-menu
        flex-flow: column
        padding: 60px
        background: #fff
        width: 300px
        height: 100%
        position: fixed
        z-index: 10
        top: 0
        left: 0
</style>
