import { lazyLoadView } from '@/helpers/url';
import i18n from '@/locale';

import { CONSULT_PREFIX, ADD_COMPANIES_PREFIX } from '@/routes/config';

export default [
    {
        path: `${CONSULT_PREFIX}${ADD_COMPANIES_PREFIX}/choose-one-or-more`,
        name: 'AddOneOrMorePageCompanies',
        component: lazyLoadView(`consult/common/AddOneOrMorePage/AddOneOrMorePage`),
        meta: {
            showAppHeader: true,
            title: i18n.tc('ADD_COMPANIES.COMMON.COMPANIES.TITLE'),
            urlPrefix: ADD_COMPANIES_PREFIX,
            backButton: true,
        },
    },
    // {
    //     path: `${CONSULT_PREFIX}${ADD_COMPANIES_PREFIX}/form`,
    //     name: `AddOrEditCompanyByForm`,
    //     component: lazyLoadView(`consult/add-companies/AddOrEditCompanyByForm/AddOrEditCompanyByForm`),
    //     meta: {
    //         showAppHeader: true,
    //         backButton: true,
    //     },
    // },
    {
        path: `${CONSULT_PREFIX}${ADD_COMPANIES_PREFIX}/file`,
        name: 'AddByFileCompany',
        component: lazyLoadView(`consult/common/AddByFile/AddByFile`),
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_COMPANIES_PREFIX,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_COMPANIES_PREFIX}/file-data`,
        name: `AddCompanyByFileData`,
        component: lazyLoadView(`consult/add-companies/AddCompanyByFileData/AddCompanyByFileData`),
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_COMPANIES_PREFIX,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_COMPANIES_PREFIX}/import-info`,
        name: 'AddCompanyByFileInfoWorkers',
        component: lazyLoadView(`consult/add-companies/AddCompanyByFileInfo/AddCompanyByFileInfo`),
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_COMPANIES_PREFIX,
        },
    },
];
