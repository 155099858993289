import { allCalendarTimes } from '@/mocks/tableData';

const calculateEventHeightCoef = (dates: Array<string>, i18n: any, availableTimes: Array<string> | null): number => {
    const timeData = availableTimes || allCalendarTimes;
    const length = timeData.findIndex(
        time => i18n.t(time) === dates[1]) - timeData.findIndex(time => i18n.t(time) === dates[0]
    );
    return length / timeData.length;
};

export {
    calculateEventHeightCoef,
};
