<template>
<div :class='[wrapperClass, { "b-consult-card__sub-wrapper": !information.isNoPadding }]'>
    <div class='b-consult-card__sub-title'>
        {{ $t(information.title) }}
    </div>
    <div v-if='information.aside'
         class='b-consult-card__sub-aside'>
        {{ information.aside }}
    </div>
    <div v-else-if='information.color'
         :style='{ backgroundImage: information.color }'
         class='b-consult-card__sub-color'>
    </div>
    <div v-else-if='information.tags'
         class='b-consult-card__sub-tags'>
        <div v-for='tag in information.tags'
             class='b-consult-card__sub-tag'>
            {{ tag }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { informationDataType } from '@/types/Consult';

const CONSULT_CARD_TYPES: Array<string> = ['base', 'two-rows'];

@Component
export default class ConsultCard extends Vue {
    @Prop({ type: Object, required: true }) readonly information!: informationDataType;
    @Prop({ type: String, default: 'base', validator: (type: string) => CONSULT_CARD_TYPES.includes(type) })
    readonly type!: Array<string>;

    get wrapperClass(): string {
        return `b-consult-card__sub-wrapper--${this.type}`;
    }
}
</script>

<style lang='sass'>
.b-consult-card
    &__sub-title
        font-weight: 500
        font-size: 16px

    &__sub-aside
        font-weight: 300
        font-size: 16px
        margin-top: 7px
        word-wrap: break-word

    &__sub-wrapper
        margin-top: 20px

        &--two-rows
            flex: 0 0 50%

    &__sub-color
        width: 40px
        height: 20px
        border-radius: 5px
        margin-top: 5px

    &__sub-tags
        display: flex
        margin-top: 5px

    &__sub-tag
        background-color: $light-gray
        color: $alt-blue
        font-size: 14px
        font-weight: bold
        padding: 4px 11px
        border-radius: 3px
        &:not(:last-of-type)
            margin-right: 10px
</style>
