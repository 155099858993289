import authRoutes from './auth';
import planRoutes from './plan';
import consultRoutes from './consult';
import cancelRoutes from './cancel';
import settingsRoutes from './settings';
import profileRoutes from './profile';

export default [
    ...authRoutes,
    ...planRoutes,
    ...consultRoutes,
    ...cancelRoutes,
    ...settingsRoutes,
    ...profileRoutes,
];
