export default {

    'CONSULT.APPOINTMENT.TITLE': 'Appointments',
    'CONSULT.APPOINTMENT.SEARCH.TITLE': 'Search for an appointment',
    'CONSULT.APPOINTMENT.SEARCH.CLIENTS.NAME': 'Client\'s name',
    'CONSULT.APPOINTMENT.SEARCH.CLIENT.NAME': 'Client name',
    'CONSULT.APPOINTMENT.SEARCH.ADVISORS.NAME': 'Advisor\'s last name',
    'CONSULT.APPOINTMENT.SEARCH.ADVISOR.NAME': 'Advisor last name',
    'CONSULT.APPOINTMENT.SEARCH.TECHS.NAME': 'Technician\'s last name',
    'CONSULT.APPOINTMENT.SEARCH.TECH.NAME': 'Technician last name',
    'CONSULT.APPOINTMENT.SEARCH.DATE': 'Date',
    'CONSULT.APPOINTMENT.SEARCH.REFERENCE': 'Reference',
    'CONSULT.APPOINTMENT.SEARCH.POPUP.EDIT': 'Edit appointment',

    'CONSULT.APPOINTMENT.CLIENT.DETAILS': 'Company details',
    'PAST.APPOINTMENT.DETAILS.TITLE': 'Appointment details',

    'CONSULT.BRANCHES.NAME': 'Branches name',
    'CONSULT.BRANCH.NAME': 'Branch name',
    'CONSULT.COMPANIES.NAME': 'Company name',
    'CONSULT.COMPANY.NAME': 'Company name',
    'CONSULT.BRANCHES.REFERENCE': 'Reference',

    'CONSULT.TYPE.NAME.PLACEHOLDER': 'Type the name',
    'CONSULT.TYPE.DATE.PLACEHOLDER': 'Choose a date',
    'CONSULT.TYPE.ADVISOR.PLACEHOLDER': 'Type the name',
    'CONSULT.TYPE.BRANCH.PLACEHOLDER': 'Branches name',
    'CONSULT.TYPE.COMPANIES.PLACEHOLDER': 'Company name',
    'CONSULT.TYPE.REFERENCE.PLACEHOLDER': 'Reference',

    'CONSULT.SITE.NOT.COVERED': 'Site not covered, please contact support.',

    'CUSTOMER.DETAILS.INPUT.PLACEHOLDER': 'Prospect or Customer ID',
    'CUSTOMER.DETAILS.BUTTON.ID': 'ID',
    'CUSTOMER.DETAILS.BUTTON.TYPE.IN': 'TYPE IN',

    'LABEL.SELECT.PLACEHOLDER': 'Choose a data',

    'SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL': 'Result | Results',

    'BUTTON.EDIT.APPT': 'Edit the appointment',
    'APPT.NOTES.PLACEHOLDER': 'Additional information about the appointment',
    'APPT.CHECKBOX.TITLE': 'This technician can have appointments',
    'CANCELLED.ON.EXPIRATION.DATE': 'Unless confirmed, this appointment will be automatically cancelled on',
    'CANCELLED.ON.PREBOOKED.MISMATCH.TEXT': 'Duration mismatch between this appointment and the new one',
    'CONFIRM.THE.APPT': 'Confirm the appointment',
    'CANCEL.THE.APPT': 'Cancel the appointment',

    'PREBOOKED.APPT.NOTIFICATION': 'There is a prebooked appointment linked to this appointment',
};
