<template>
<div :class='formClassWrapper' class='b-appointment-left-bar_form'>
    <div v-if='eventTime || dates'
         :class='iconsWrapperClass'>
        <div v-if='defaultFormValues && isWorkersCalendar' class='h-flex h-flex-center'>
            <div class='b-calendar-select__week fw-select-base h-flex h-flex-center'>
                <FwDatepicker
                    v-model='dateFrom'
                    name='datepicker'
                    :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                    :propsRange='false'
                    :propsLang='`${$i18n.locale}`'
                    :selectMode='false'
                    pickerStyle='static'
                    propsType='date'
                    propsFormat='MMM DD, YYYY'
                    :openOnTop='false'
                    :disabledDate='disabledDateByObject'
                    propsInputClass='b-add-reserved-slot-form__datepicker'>
                    <template #icon>
                        <FwIcon
                            class='h-flex'
                            icon='calendar'
                            size='16'
                            color='#203f6a'>
                        </FwIcon>
                    </template>
                </FwDatepicker>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='b-calendar-select__week
                                fw-select-base
                                fw-select-base__time
                                h-flex h-flex-center
                                h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.FROM') }}
                </h6>
                <div style='min-width: 120px'>
                    <FwSelect
                        v-model='timeFrom'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :options='timeFromArray'>
                    </FwSelect>
                </div>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='b-calendar-select__week
                        fw-select-base
                        fw-select-base__time
                        h-flex h-flex-center
                        h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.TO') }}
                </h6>
                <div style='min-width: 120px'>
                    <FwSelect
                        v-model='timeTo'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :disabled='defaultFormValues.duration'
                        :options='timeToArray'>
                    </FwSelect>
                </div>
            </div>
        </div>
        <template v-else>
            <div v-if='eventTime'
                 :class='iconsInnerClass'
                 class='h-inline-flex h-mv-3'>
                <FwIcon class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
                        icon='calendar-confirmed'
                        size='16'
                        color='#213F6B'>
                </FwIcon>
                <span>
                    {{ eventTime }}
                </span>
            </div>
            <div v-if='dates'
                 :class='iconsInnerClass'
                 class='h-inline-flex h-mv-3'>
                <div v-if='dates'
                     class='h-inline-flex h-mv-3'>
                    <FwIcon
                        class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
                        icon='calendar-time'
                        size='16'
                        color='#213F6B'>
                    </FwIcon>
                    <div class='h-flex h-flex-center'>
                        <div v-for='(time, index) in dates'
                             :key='index'
                             class='b-appointment-left-bar__time'>
                            <span>
                                {{ defaultFormValues.full_day ? "00:00" : time }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-if='dates && isPlanAnAppointmentCalendar'
             :class='iconsInnerClass'
             class='h-flex h-flex-space-between h-mv-3'>
            <div v-if='eventDate.data && eventDate.data.distance_to_in_km_text' class='h-flex h-flex-center'>
                <FwIcon
                    class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
                    icon='icon-go-arrow'
                    size='16'
                    color='#213F6B'>
                </FwIcon>
                <span>
                    {{ eventDate.data.distance_to_in_km_text }}
                </span>
            </div>
            <div v-if='eventDate.data && eventDate.data.distance_from_in_km_text' class='h-flex h-flex-center'>
                <FwIcon
                    class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
                    icon='icon-back-arrow'
                    size='16'
                    color='#213F6B'>
                </FwIcon>
                <span>
                    {{ eventDate.data.distance_from_in_km_text }}
                </span>
            </div>
        </div>
    </div>
    <div v-if='isPrebooked && isMismatchDuration' class='h-flex h-flex-center h-width-100p h-mv-15' style='line-height: 12px;'>
        <FwIcon
            class='h-mh-0'
            icon='alert-info'
            size='16'
            color='#BEC7D4'>
        </FwIcon>
        <span class='h-fw-500 h-font-16 h-opacity-0_5 h-ml-10'>
            {{ $t('CANCELLED.ON.PREBOOKED.MISMATCH.TEXT') }}
        </span>
    </div>
    <form v-if='isWorkersCalendar && !activeType'
          class='h-mt-15'
          :class='[formClass, { "b-appointment-tech-company": isWorkersCalendar }]'>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin'
                labelType='label-medium'
                :title='$t(`LABEL.CLIENT.NAME`)'>
                <FwInput
                    :value='client_details'
                    class='text-capitalize'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.CLIENT.NAME`)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_FULLNAME)'
                    @update:value='value => client_details = value'
                    @input='clearServerErrorsBase(CLIENT_FULLNAME)'
                    @blur='addCurrentInputToValidateArray(CLIENT_FULLNAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='$t(`LABEL.APPT.CLIENT.COMPANY`)'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.APPT.CLIENT.COMPANY`)'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    @update:value='value => company_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(CONTRACT_NUMBER)'
                labelType='label-medium'
                :title='$t(`LABEL.CONTRACT.NUMBER`)'>
                <FwInput
                    :value='contract_number'
                    type='tel'
                    name='text'
                    :placeholder='$t(`LABEL.CONTRACT.NUMBER`)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CONTRACT_NUMBER)'
                    @update:value='value => contract_number = value'
                    @input='clearServerErrorsBase(CONTRACT_NUMBER)'
                    @blur='addCurrentInputToValidateArray(CONTRACT_NUMBER)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='$t(`LABEL.APPT.CLIENT.OI`)'>
                <FwInput
                    :value='oi_number'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.APPT.CLIENT.OI`)'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    @update:value='value => oi_number = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    @update:value='value => company_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(LOCATION_VALUE)'
                labelType='label-medium'
                :title='$t(`LABEL.APPOINTMENT.LOCATION`)'>
                <FwInput
                    componentType='textarea'
                    :value='location_value'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.APPOINTMENT.LOCATION`)'
                    inputStyle='textarea'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(LOCATION_VALUE)'
                    @update:value='value => location_value = value'
                    @input='clearServerErrorsBase(LOCATION_VALUE)'
                    @blur='addCurrentInputToValidateArray(LOCATION_VALUE)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row' style='align-items: flex-end'>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='$t(`Contacts`)'>
                <FwInput
                    :value='contact_full_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`Contacts`)'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    @update:value='value => contact_full_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                title=''>
                <FwInput
                    :value='contact_phone'
                    type='text'
                    name='text'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    :placeholder='$t(`LABEL.PHONE`)'
                    inputStyle='white'
                    :disabled='disabledForm'
                    @update:value='value => contact_phone = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <form v-else
          class='h-mt-15'
          :class='[formClass, { "b-appointment-tech-company": isWorkersCalendar || isCompanyCalendar }]'
          @keyup.enter='onSubmit'>
        <FwFormInput
            v-if='defaultFormValues && defaultFormValues.is_professional'
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(REASON_COMPANY_NAME)'
            labelType='label-medium'
            :title='$t(`LABEL.COMPANY.NAME`)'>
            <FwInput
                :value='reason_company_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.COMPANY.NAME`)'
                :error='getFieldErrorText(REASON_COMPANY_NAME)'
                inputStyle='white'
                :disabled='disabledForm'
                @update:value='value => reason_company_name = value'
                @input='clearServerErrorsBase(REASON_COMPANY_NAME)'
                @blur='addCurrentInputToValidateArray(REASON_COMPANY_NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            labelType='label-medium'
            :title='$t(`LABEL.CLIENT.NAME`)'>
            <FwInput
                :value='client_details'
                class='text-capitalize'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.CLIENT.NAME`)'
                inputStyle='white'
                :disabled='disabledForm'
                @update:value='value => client_details = value'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(CONTRACT_NUMBER)'
            labelType='label-medium'
            :title='$t(`LABEL.CONTRACT.NUMBER`)'>
            <FwInput
                :value='contract_number'
                type='tel'
                name='text'
                :placeholder='$t(`LABEL.CONTRACT.NUMBER`)'
                inputStyle='white'
                :disabled='disabledForm'
                :error='getFieldErrorText(CONTRACT_NUMBER)'
                @update:value='value => contract_number = value'
                @input='clearServerErrorsBase(CONTRACT_NUMBER)'
                @blur='addCurrentInputToValidateArray(CONTRACT_NUMBER)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(LOCATION_VALUE)'
            labelType='label-medium'
            :title='$t(`LABEL.APPOINTMENT.LOCATION`)'>
            <FwInput
                componentType='textarea'
                :value='location_value'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.APPOINTMENT.LOCATION`)'
                inputStyle='textarea'
                :disabled='disabledForm'
                :error='getFieldErrorText(LOCATION_VALUE)'
                @update:value='value => location_value = value'
                @input='clearServerErrorsBase(LOCATION_VALUE)'
                @blur='addCurrentInputToValidateArray(LOCATION_VALUE)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(COMPANY_NAME)'
            labelType='label-medium'
            :title='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'>
            <FwInput
                :value='company_name'
                type='text'
                name='text'
                :placeholder='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'
                :error='getFieldErrorText(COMPANY_NAME)'
                inputStyle='white'
                :disabled='disabledForm'
                @update:value='value => company_name = value'
                @input='clearServerErrorsBase(COMPANY_NAME)'
                @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
            </FwInput>
        </FwFormInput>
    </form>
    <div v-if='submitText && !isPrebooked'
         class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-20'
            :disabled='isRequestSending'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t(submitText) }}
        </FwButton>
    </div>
    <div v-if='isPrebooked' class='h-flex h-flex-dir-column h-flex-center'>
        <span v-if='expiredDate' class='h-font-14 h-text-center h-mt-30 h-mb-10' style='max-width: 400px'>
            {{ $t('CANCELLED.ON.EXPIRATION.DATE') }} {{ expiredDate }}
        </span>
        <FwButton
            v-if='!isMismatchDuration'
            class='h-mt-20'
            :disabled='isRequestSending'
            borderRadiusType='tiny-border'
            @click='confirmPrebooked'>
            {{ $t('CONFIRM.THE.APPT') }}
        </FwButton>
        <div
            class='b-company-edit__delete h-mt-20'
            :disabled='isRequestSending'
            @click='$emit("cancelAppt")'>
            {{ $t('CANCEL.THE.APPT') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';
import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';
import DateMixin from '@/mixins/dateMixin';
import {
    REASON_COMPANY_NAME,
    COMPANY_NAME,
    CLIENT_SURNAME,
    CLIENT_FULLNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
} from '@/helpers/events';
import {
    FULL_TIME_EVERY_15,
    getHoursAndMinutesFromAMPM,
    formatDateForTitle,
    dateToString,
} from '@/helpers/dates';
import { CompanyData } from '@/types/Companies';
import { AppointmentDataType, BookAppointmentDataType } from '@/types/Appointment';
import i18n from '@/locale';

@Component({
    validators: {
        [REASON_COMPANY_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [COMPANY_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CLIENT_FULLNAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CONTRACT_NUMBER](value: string) {
            return this.baseInputValidator({ value });
        },
        [LOCATION_VALUE](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class EventForm extends Mixins(ValidationMixin, CalendarMixin, DateMixin) {
    @Prop({ type: Date, default: null }) readonly appointmentStartDate!: Date;
    @Prop({ type: String, default: 'BUTTON.SAVE' }) readonly submitText!: string;
    @Prop({ type: Object, default: null }) readonly defaultFormValues!: AppointmentDataType;
    @Prop({ type: Object, default: null }) readonly companyData!: CompanyData;
    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: String, default: null }) readonly iconsWrapperClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: String, default: null }) readonly eventTime!: Date;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isEditMode!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isPrebooked!: boolean;
    @Prop({ type: String, default: null }) readonly activeType!: string;
    @Prop({ type: Array, default: null }) readonly dates!: BookAppointmentDataType;

    [REASON_COMPANY_NAME]: string | null = '';
    [COMPANY_NAME]: string | null = '';
    [CLIENT_FULLNAME]: string | null = '';
    [CLIENT_NAME]: string | null = '';
    [CONTRACT_NUMBER]: number | string | null = '';
    [LOCATION_VALUE]: string | null = '';
    REASON_COMPANY_NAME: string = REASON_COMPANY_NAME;
    COMPANY_NAME: string = COMPANY_NAME;
    CLIENT_FULLNAME: string = CLIENT_FULLNAME;
    CLIENT_NAME: string = CLIENT_NAME;
    CONTRACT_NUMBER: string = CONTRACT_NUMBER;
    LOCATION_VALUE: string = LOCATION_VALUE;
    dateFrom: Date = this.appointmentStartDate;
    timeFrom: string = '';
    contact_full_name: string = '';
    contact_phone: string = '';
    oi_number: string = '';
    timeTo: string = '';

    serverErrors: serverErrors = {
        [COMPANY_NAME]: null,
        [REASON_COMPANY_NAME]: null,
        [CLIENT_FULLNAME]: null,
        [CLIENT_NAME]: null,
        [CONTRACT_NUMBER]: null,
        [LOCATION_VALUE]: null,
    };
    get expiredDate() {
        if (!this.defaultFormValues.expired_at) {
            return null;
        }
        return formatDateForTitle(new Date(this.defaultFormValues.expired_at));
    }
    get isMismatchDuration() {
        if (!this.defaultFormValues) {
            return false;
        }
        return this.defaultFormValues.mismatch_duration;
    }
    get eventDate(): any {
        return this.$store.state.CalendarPageStore.date;
    }
    get companyName() {
        let result = null;
        if (this.isPlanAnAppointmentCalendar) {
            result = this.companyData.title;
        } else {
            result = this.defaultFormValues.company_title;
        }
        return result;
    }
    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate : new Date(),
        };
    }
    get timeFromArray(): Array<string> {
        return FULL_TIME_EVERY_15.map(time => i18n.t(time));
    }
    get timeToArray() {
        const updatedArray = clone(this.timeFromArray);
        const index = updatedArray.findIndex(i => i === this.timeFrom);
        let durationValue = null;
        if (this.defaultFormValues.duration) {
            durationValue = this.defaultFormValues.duration / 15;
        } else {
            durationValue = 30 / 15;
        }
        const result = updatedArray.splice(index + durationValue, updatedArray.length - 1);
        this.updateTime();

        return result;
    }
    get startDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeFrom as string));
        return new Date(new Date(this.dateFrom).setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get endDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeTo as string));
        return new Date(new Date(this.dateFrom).setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    created() {
        if (this.isWorkersCalendar) {
            if (this.isEditMode && this.eventDate.data) {
                this.timeFrom = this.formatAMPM(new Date(this.eventDate.data.dt_start));
                this.timeTo = this.formatAMPM(new Date(this.eventDate.data.dt_end));
            } else {
                this.timeFrom = this.formatAMPM(this.dateFrom, false);
            }
        }
    }
    mounted() {
        if (this.defaultFormValues) {
            const keys = Object.keys(this.defaultFormValues);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                this[key] = this.defaultFormValues[key];
                this.addCurrentInputToValidateArray(key);
            }
            this[REASON_COMPANY_NAME] = this.defaultFormValues.company_title;
            this[COMPANY_NAME] = this.companyName;
            this[CLIENT_FULLNAME] = this.defaultFormValues.client_details;
            this[CONTRACT_NUMBER] = this.defaultFormValues.contract_id;
            this[LOCATION_VALUE] = this.defaultFormValues.location;
            this.oi_number = this.defaultFormValues.intervention_order;
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [REASON_COMPANY_NAME]: this[REASON_COMPANY_NAME],
            [COMPANY_NAME]: this[COMPANY_NAME],
            [CLIENT_FULLNAME]: this[CLIENT_FULLNAME],
            [CONTRACT_NUMBER]: this[CONTRACT_NUMBER],
            [LOCATION_VALUE]: this[LOCATION_VALUE],
            intervention_order: this.oi_number,
        };
    }

    @Emit('confirmPrebooked')
    confirmPrebooked() {
        return {
            [REASON_COMPANY_NAME]: this[REASON_COMPANY_NAME],
            [COMPANY_NAME]: this[COMPANY_NAME],
            [CLIENT_FULLNAME]: this[CLIENT_FULLNAME],
            [CONTRACT_NUMBER]: this[CONTRACT_NUMBER],
            [LOCATION_VALUE]: this[LOCATION_VALUE],
        };
    }

    @Emit('updateTime')
    updateTime() {
        return {
            dt_start: dateToString(this.startDate),
            dt_end: dateToString(this.endDate),
        };
    }

    @Watch('timeFrom')
    updateTimeTo() {
        this.timeTo = this.timeToArray[0];
    }
}
</script>

<style lang='sass'>
.b-appointment-tech-company
    display: flex
    flex-flow: row wrap
    .b-base-form-margin
        width: 290px!important
        margin: 10px
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 10px 0 15px
        font-size: 12px
        color: $cancel-red
    .mx-datepicker
        padding: 0
    .mx-input-wrapper, .fw-select-base .multiselect__tags, .fw-select-address .multiselect__tags, .fw-select-tooltip .multiselect__tags
        background: #eff0f3
        border-radius: 10px
        font-size: 14px
    .mx-datepicker--static .mx-calendar, .mx-datepicker--static .mx-datepicker-popup
        background: #eff0f3!important
        right: auto!important
        padding: 10px
    .fw-select-base .multiselect__element, .fw-select-address .multiselect__element, .fw-select-tooltip .multiselect__element
        font-size: 14px
    .fw-select-base .multiselect__content-wrapper, .fw-select-address .multiselect__content-wrapper, .fw-select-tooltip .multiselect__content-wrapper
        max-height: 240px!important
.b-appointment-left-bar__time
    &:not(:first-of-type)
        &:before
            content: '-'
            margin: 0 3px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px

.b-company-edit__delete
    text-transform: uppercase
    margin-top: 20px
    font-size: 12px
    color: $cancel-red
    font-weight: bold
    cursor: pointer

    &:hover
        opacity: 0.7
</style>
