<template>
<div class='h-flex h-flex-justify-end h-width-200'>
    <div v-if='!hideBackButton'
         class='b-block-arrow__border h-flex h-flex-center'
         @click='changeDayHandler(-1)'>
        <FwIcon
            class='h-mh-5'
            icon='arrow-left'
            size='10'
            color='#27DBBD'>
        </FwIcon>
    </div>
    <div class='b-block-arrow__border h-flex h-flex-center h-mh-10'
         @click='changeDayHandler(1)'>
        <FwIcon
            class='h-mh-5'
            icon='arrow-right'
            size='10'
            color='#27DBBD'>
        </FwIcon>
    </div>
    <div class='b-appointment-main__today-button'>
        <FwButton
            size='flex'
            inversed
            :styles='{
                fontSize: "10px",
                borderWidth: "2px",
                lineHeight: "normal",
            }'
            borderRadiusType='small-border'
            @click.native='changeDayHandlerByToday(0)'>
            {{ $t('CALENDAR.BUTTON.TODAY') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';

@Component
export default class CalendarDateNavigation extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Boolean, required: true }) readonly hideBackButton!: boolean;

    @Emit('changeDay')
    changeDayHandler(delta: number) {
        return { delta, date: this.date };
    }

    @Emit('changeDay')
    changeDayHandlerByToday(delta: number) {
        return { delta, date: new Date() };
    }
}
</script>

<style lang='sass'>
.b-block-arrow
    &__border
        border: 2px solid #27DBBD
        border-radius: 5px
        height: 28px
        width: 28px
        line-height: 20px
        transition: opacity 0.24s ease-in-out
        cursor: pointer
        &:hover
            background: rgba(39, 219, 189, 0.05)
</style>
