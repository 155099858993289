import { CompanyInformationDataType, CompanyInformationFormType, CompanyData } from '@/types/Companies';

const COMPANY_INFORMATION_NAME_TITLE = 'Company name';
const COMPANY_INFORMATION_ADDRESS_TITLE = 'Address';
const COMPANY_INFORMATION_ADDRESS_2_TITLE = 'Address 2';
const COMPANY_INFORMATION_POINT_TITLE = 'Starting point';
const COMPANY_INFORMATION_SIREN_TITLE = 'SIREN';
const COMPANY_INFORMATION_SIRET_TITLE = 'SIRET';
const COMPANY_INFORMATION_PHONE_TITLE = 'Phone';
const COMPANY_INFORMATION_EMAIL_TITLE = 'Email';
const COMPANY_INFORMATION_ZIP_CODE_TITLE = 'Zip code';
const COMPANY_INFORMATION_INSEE_CODE_TITLE = 'Insee code';
const COMPANY_INFORMATION_CITY_TITLE = 'City';

const COMPANY_INFORMATION_NAME = 'name';
const COMPANY_INFORMATION_ADDRESS = 'address';
const COMPANY_INFORMATION_ADDRESS_2 = 'address_2';
const COMPANY_INFORMATION_POINT = 'point';
const COMPANY_INFORMATION_SIREN = 'siren';
const COMPANY_INFORMATION_SIRET = 'siret';
const COMPANY_INFORMATION_PHONE = 'phone';
const COMPANY_INFORMATION_EMAIL = 'contact_email';
const COMPANY_INFORMATION_ZIP_CODE = 'zip_code';
const COMPANY_INFORMATION_INSEE_CODE = 'insee_code';
const COMPANY_INFORMATION_CITY = 'city';
const COMPANY_CONFIRM_ADDRESS = 'is_address_confirmed';

const COMPANY_INFORMATION_FORM_MAPPING = {
    [COMPANY_INFORMATION_NAME]: COMPANY_INFORMATION_NAME_TITLE,
    [COMPANY_INFORMATION_ADDRESS]: COMPANY_INFORMATION_ADDRESS_TITLE,
    [COMPANY_INFORMATION_POINT]: COMPANY_INFORMATION_POINT_TITLE,
    [COMPANY_INFORMATION_SIREN]: COMPANY_INFORMATION_SIREN_TITLE,
    [COMPANY_INFORMATION_SIRET]: COMPANY_INFORMATION_SIRET_TITLE,
    [COMPANY_INFORMATION_PHONE]: COMPANY_INFORMATION_PHONE_TITLE,
    [COMPANY_INFORMATION_EMAIL]: COMPANY_INFORMATION_EMAIL_TITLE,
};

function getMockedInformationData(i18n: any): Array<CompanyInformationDataType> {
    return [
        {
            title: i18n.t('COMPANIES.FORM.INPUT.ADDRESS.LABEL'),
            aside: '130 rus Saint-Maur 75011 Paris, FRANCH',
        },
        {
            title: i18n.t('COMPANIES.FORM.INPUT.START_POINT.LABEL'),
            aside: '75011',
        },
        {
            title: COMPANY_INFORMATION_SIREN_TITLE,
            aside: '1234531231412412',
        },
        {
            title: COMPANY_INFORMATION_SIRET_TITLE,
            aside: '312121',
        },
        {
            title: i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
            aside: '0927383718',
        },
        {
            title: COMPANY_INFORMATION_EMAIL_TITLE,
            aside: 'addresse@mail.com',
        },
    ];
}
// @ts-ignore-next-line
const companyDataProps: CompanyData = {
    latitude: '',
    longitude: '',
    id: '',
    created_at: '',
    updated_at: '',
    account_id: '',
    remote_id: null,
    title: '',
    address: '',
    contact_email: null,
    internal_title: null,
    technician_count: null,
    siret: null,
    technicians: null,
    is_address_confirmed: false,
};

const companyInformationFormData: CompanyInformationFormType = {
    [COMPANY_INFORMATION_ADDRESS]: '130 rus Saint-Maur 75011 Paris, FRANCH',
    [COMPANY_INFORMATION_POINT]: '75011',
    [COMPANY_INFORMATION_SIREN]: '1234531231412412',
    [COMPANY_INFORMATION_SIRET]: '312121',
    [COMPANY_INFORMATION_PHONE]: '0927383718',
    [COMPANY_INFORMATION_EMAIL]: 'addresse@mail.com',
};

const ADD_COMPANIES_STATE = 'state';
const ADD_COMPANIES_LABEL = 'label';
const ADD_COMPANIES_OVERVIEW = 'overview';
const ADD_COMPANIES_CORRESPONDENCE = 'correspondence';

export {
    companyInformationFormData,
    companyDataProps,
    COMPANY_INFORMATION_FORM_MAPPING,
    COMPANY_INFORMATION_NAME,
    COMPANY_INFORMATION_ADDRESS,
    COMPANY_INFORMATION_POINT,
    COMPANY_INFORMATION_SIREN,
    COMPANY_INFORMATION_SIRET,
    COMPANY_INFORMATION_PHONE,
    COMPANY_INFORMATION_EMAIL,
    ADD_COMPANIES_STATE,
    ADD_COMPANIES_LABEL,
    ADD_COMPANIES_OVERVIEW,
    ADD_COMPANIES_CORRESPONDENCE,
    COMPANY_INFORMATION_NAME_TITLE,
    COMPANY_INFORMATION_ADDRESS_2_TITLE,
    COMPANY_INFORMATION_ADDRESS_2,
    COMPANY_INFORMATION_POINT_TITLE,
    COMPANY_INFORMATION_PHONE_TITLE,
    COMPANY_INFORMATION_ADDRESS_TITLE,
    COMPANY_INFORMATION_SIRET_TITLE,
    COMPANY_INFORMATION_SIREN_TITLE,
    COMPANY_INFORMATION_EMAIL_TITLE,
    COMPANY_INFORMATION_ZIP_CODE_TITLE,
    COMPANY_INFORMATION_INSEE_CODE_TITLE,
    COMPANY_INFORMATION_CITY_TITLE,
    COMPANY_INFORMATION_ZIP_CODE,
    COMPANY_INFORMATION_INSEE_CODE,
    COMPANY_INFORMATION_CITY,
    COMPANY_CONFIRM_ADDRESS,
    getMockedInformationData,
};
