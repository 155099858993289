<template>
<div class='h-mt'>
    <form class='b-add-branch__form'
          @keydown.stop.prevent.enter='onSubmit'>
        <div class='h-flex h-width-100p'>
            <FwFormInput
                class='h-mh-20'
                style='flex: 0 0 270px'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='`${$t("COMPANIES.FORM.INPUT.NAME.LABEL")}`'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`COMPANIES.FORM.INPUT.NAME.LABEL`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    @update:value='value => company_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(COMPANY_ADDRESS)'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY.ADDRESS`)}`'>
                <FwInput
                    :value='company_address'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.COMPANY.ADDRESS`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(COMPANY_ADDRESS)'
                    @update:value='value => company_address = value'
                    @input='clearServerErrorsBase(COMPANY_ADDRESS)'
                    @blur='addCurrentInputToValidateArray(COMPANY_ADDRESS)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-width-100p h-mt-20' style='align-items: flex-end'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t(`Contacts`)}`'>
                <FwInput
                    :value='company_contact_1_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => company_contact_1_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'>
                <FwInput
                    :value='company_contact_1_phone'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => company_contact_1_phone = value'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-mh-20'></div>
        </div>
        <div class='h-flex h-width-100p h-mt-20' style='align-items: flex-end'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'>
                <FwInput
                    :value='company_contact_2_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => company_contact_2_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'>
                <FwInput
                    :value='company_contact_2_phone'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => company_contact_2_phone = value'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-mh-20'></div>
        </div>
        <div class='h-flex h-width-100p h-mt-20' style='align-items: flex-end'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='$t("GLOBAL.TECHNICIAN")'>
                <FwInput
                    :value='technician_full_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => technician_full_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'>
                <FwInput
                    :value='technician_phone'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE`)'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    @update:value='value => technician_phone = value'>
                </FwInput>
            </FwFormInput>
            <div class='h-flex-1-0 h-mh-20'></div>
        </div>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import {
    COMPANY_NAME,
    COMPANY_ADDRESS,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    ADVISOR_NAME,
    ADVISOR_PHONE,
    BRANCH_VALUE,
} from '@/helpers/events';
import { AppointmentConsultSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';

@Component({
    validators: {
        [COMPANY_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [COMPANY_ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
        [CONTRACT_NUMBER](value: string) {
            return this.requiredValidator({ value });
        },
        [LOCATION_VALUE](value: string) {
            return this.requiredValidator({ value });
        },
        [ADVISOR_NAME](value: string) {
            return this.requiredValidator({ value });
        },
    },
})

export default class AppointmentClientForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentConsultSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;
    isEdit: boolean = false;
    confirmCheckbox: boolean = false;
    disabledForm: boolean = true;

    valueSkill: any = null;
    valueCompany: any = null;
    zoneValue: any = null;
    COMPANY_NAME: string = '';
    COMPANY_ADDRESS: string = '';
    CONTRACT_NUMBER: string = '';
    LOCATION_VALUE: string = '';
    ADVISOR_NAME: string = '';
    ADVISOR_PHONE: string = '';
    BRANCH_VALUE: string = '';
    company_name: string = '';
    company_address: string = '';
    company_contact_1_name: string = '';
    company_contact_1_phone: string = '';
    company_contact_2_name: string = '';
    company_contact_2_phone: string = '';
    technician_full_name: string = '';
    technician_phone: string = '';
    fist_name: string = '';
    client_name: string = '';
    client_surname: string = '';
    contract_number: string = '';
    location: string = '';
    advisor_name: string = '';
    advisor_phone: string = '';
    branches_name: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveAdmin: boolean = false;
    serverErrors: serverErrors = {
        company_name: null,
        client_name: null,
        client_surname: null,
        contract_number: null,
        location: null,
        advisor_name: null,
        advisor_phone: null,
        branches_name: null,
    };
    updateFormByServerValues() {
        if (this.appointmentData.company_title) {
            this.company_name = this.appointmentData.company_title;
        }
        if (this.appointmentData.company_address) {
            this.company_address = this.appointmentData.company_address;
        }
        if (this.appointmentData.company_contact_1) {
            const contactDetailsArray = this.appointmentData.company_contact_1.split(',');
            this.company_contact_1_name = contactDetailsArray[0];
            this.company_contact_1_phone = contactDetailsArray[1];
        }
        if (this.appointmentData.company_contact_2) {
            const contactDetailsArray = this.appointmentData.company_contact_2.split(',');
            this.company_contact_2_name = contactDetailsArray[0];
            this.company_contact_2_phone = contactDetailsArray[1];
        }
        if (this.appointmentData.technician_full_name) {
            this.technician_full_name = this.appointmentData.technician_full_name;
        }
        if (this.appointmentData.technician_phone) {
            this.technician_phone = this.appointmentData.technician_phone;
        }
    }

    created() {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: $cancel-red
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
