import { CONSULT_PREFIX, COMPANIES_PREFIX } from '@/routes/config';
import { ConsultCompaniesPage } from '@/views/consult/companies/ConsultCompaniesPage';
import { ConsultCompanyPage } from '@/views/consult/companies/ConsultCompanyPage';
import { ConsultCompanyEditPage } from '@/views/consult/companies/ConsultCompanyEditPage';
import { CalendarPage } from '@/views/consult/common/CalendarPage';
import { AddOrEditAddressByForm } from '@/views/consult/add-companies/AddOrEditAddressByForm';

import {
    COMPANY_CALENDAR,
} from '@/helpers/calendar';

export default [
    {
        path: `${CONSULT_PREFIX}${COMPANIES_PREFIX}/:page([0-9]+)?`,
        name: ConsultCompaniesPage.name,
        component: ConsultCompaniesPage,
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${COMPANIES_PREFIX}/:id`,
        name: ConsultCompanyPage.name,
        component: ConsultCompanyPage,
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${COMPANIES_PREFIX}/address/:id`,
        name: AddOrEditAddressByForm.name,
        component: AddOrEditAddressByForm,
        meta: {
            showAppHeader: true,
            backButton: true,
            goBackRouterName: ConsultCompanyPage.name,
        },
    },
    {
        path: `${CONSULT_PREFIX}${COMPANIES_PREFIX}/calendar/:id`,
        name: COMPANY_CALENDAR,
        component: CalendarPage,
    },
    {
        path: `${CONSULT_PREFIX}${COMPANIES_PREFIX}/edit/:id`,
        name: ConsultCompanyEditPage.name,
        component: ConsultCompanyEditPage,
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
];
