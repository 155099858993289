import axios from 'axios';

import {
    EventsWorkersResponseType,
    CalendarInstancesAppointmentsWithSlotsResponseType,
    UnavailabilityWorkersResponseType,
} from '@/types/Events';
import {
    CreateEventPayload,
    CreateEventUnavailabilityType,
} from '@/types/Events/Workers';
import {
    CalendarInstancesParamsType,
    CalendarInstancesAppointmentsWithSlotsParamsType,
    UnavailabilityParamsType,
} from '@/types/Calendar';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

class EventsWebApi extends WebApi {
    async getCalendarInstances(
        { from, to, user_id, origin = 'unavailability', page = 1 }: CalendarInstancesParamsType
    ): Promise<EventsWorkersResponseType> {
        const params: CalendarInstancesParamsType = {
            to,
            from,
            origin,
        };

        if (user_id) {
            params.user_id = user_id;
        }

        const url = updateUrlGetParams(`${this.urlPrefix}/calendar_instances`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
        });

        const { data } = await axios.get(url, { params });
        return data;
    };

    async getUnavailabilities(
        { from, to, user_id, page = 1 }: UnavailabilityParamsType
    ): Promise<UnavailabilityWorkersResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/unavailabilities`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            to,
            from,
            user_id,
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getCalendarInstancesAppointmentsWithSlots(
        { from, to, user_id, page = 1 }: CalendarInstancesAppointmentsWithSlotsParamsType
    ): Promise<CalendarInstancesAppointmentsWithSlotsResponseType> {
        const params: CalendarInstancesParamsType = {
            to,
            from,
            user_id,
        };

        const url = updateUrlGetParams(`${this.urlPrefix}/calendar_instances/appointments_with_slots`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
        });

        const { data } = await axios.get(url, { params });
        return data;
    };

    async getEvent(id: string): Promise<{ event: CreateEventUnavailabilityType }> {
        const { data } = await axios.get(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async createEvent(payload: CreateEventPayload): Promise<CreateEventUnavailabilityType> {
        const { data } = await axios.post(`${this.urlPrefix}/events`, { 'event': payload });
        return data;
    };

    async updateEvent({ payload, id }: { payload: CreateEventPayload, id: string }): Promise<CreateEventUnavailabilityType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/events/${id}`,
            {
                'event': payload,
            },
        );
        return data;
    };

    async deleteEvent(id: string): Promise<EventsWorkersResponseType> {
        const { data } = await axios.delete(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async deleteCalendarInstance(id: string, future?: boolean) {
        const params: { [key: string]: string } = {};
        if (future) {
            params.scope = `future`;
        }
        const { data } = await axios.delete(updateUrlGetParams(`${this.urlPrefix}/calendar_instances/${id}`, params));
        return data;
    };
}

const eventsWebApi = new EventsWebApi();

export {
    eventsWebApi as EventsWebApi,
};
