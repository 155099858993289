import { AddOneOrMorePage } from '@/views/consult/common/AddOneOrMorePage';
import { AddWorkersByForm } from '@/views/consult/add-technicians/AddWorkersByForm';
import { AddWorkersByFileData } from '@/views/consult/add-technicians/AddWorkersByFileData';
import { AddCompanyByFileInfo } from '@/views/consult/add-companies/AddCompanyByFileInfo';
import { AddByFile } from '@/views/consult/common/AddByFile';
import i18n from '@/locale';

import { CONSULT_PREFIX, ADD_WORKERS_PREFIX, ADD_COMPANIES_PREFIX } from '@/routes/config';

export default [
    {
        path: `${CONSULT_PREFIX}${ADD_WORKERS_PREFIX}/choose-one-or-more`,
        name: 'AddOneOrMorePageWorkers',
        component: AddOneOrMorePage,
        meta: {
            showAppHeader: true,
            title: i18n.tc('ADD_COMPANIES.COMMON.WORKERS.TITLE'),
            urlPrefix: ADD_WORKERS_PREFIX,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_WORKERS_PREFIX}/form`,
        name: 'AddWorkersByForm',
        component: AddWorkersByForm,
        meta: {
            showAppHeader: true,
            backButton: true,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_WORKERS_PREFIX}/file`,
        name: 'AddByFileWorkers',
        component: AddByFile,
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_WORKERS_PREFIX,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_WORKERS_PREFIX}/file-data`,
        name: AddWorkersByFileData.name,
        component: AddWorkersByFileData,
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_WORKERS_PREFIX,
        },
    },
    {
        path: `${CONSULT_PREFIX}${ADD_WORKERS_PREFIX}/import-info`,
        name: 'AddCompanyByFileInfoCompanies',
        component: AddCompanyByFileInfo,
        meta: {
            showAppHeader: true,
            backButton: true,
            urlPrefix: ADD_WORKERS_PREFIX,
        },
    },
];
