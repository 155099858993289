export default {
    'WORKER.POPUP.IMA.TITLE': 'IMA PROTECT reserved slots',
    'WORKER.TIME.SLOT.TITLE': 'Working hours',
    'WORKER.TIME.SLOT.MORNING': 'MORNING',
    'WORKER.TIME.SLOT.DAY': 'DAY',
    'WORKER.TIME.SLOT.AFTERNOON': 'AFTERNOON',
    'WORKER.TIME.SLOT.LUNCH': 'LUNCH BREAK',
    'WORKER.TIME.SLOT.FROM': 'from',
    'WORKER.TIME.SLOT.TO': 'to',
    'WORKER.TIME.CURRENT.TITLE': 'Current',
    'WORKER.TIME.SCHEDULED.TITLE': 'Scheduled',
    'WORKER.TIME.SCHEDULED.UPDATED.AT': 'Will updated at',
    'WORKER.TIME.POPUP.TITLE': 'Save changes',
    'WORKER.TIME.POPUP.TXT': 'Do you want to leave this page without saving?',
    'WORKER.TIME.POPUP.BUTTON.CANCEL': 'Don’t save',
    'WORKER.TIME.POPUP.BUTTON.SAVE': 'Save changes',
    'WORKER.POPUP.ADMIN.TEXT': 'This technician is the admin of his company',
    'WORKER.POPUP.COMPANY.ADMIN': 'This company already has an admin',
    'WORKER.POPUP.CONFIRM.BUTTON': 'I confirm these working hours',
    'WORKER.POPUP.NO.PERMISSION.TEXT': 'You cannot edit working hours after confirmation',
    'WORKER.POPUP.INVITATION.OOPS': 'Oops',
    'WORKER.POPUP.INVITATION.CHECK': 'You need to complete the following tasks before sending an invitation:',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE': 'Confirm the working hours',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT': '(by closing this popup and clicking on \'Working hours\' );',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE': 'Confirm the technicians company address',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT': '(by clicking on this link).',
    'WORKER.POPUP.INVITATION.CHECK.BUTTON': 'Check and confirm working hours, address',
    'WORKER.POPUP.INVITATION.SEND': 'SEND INVITATION',
    'WORKER.POPUP.INVITATION.SENT': 'Invitation sent',
    'WORKER.POPUP.INVITATION.ACCEPTED': 'Invitation accepted',
    'WORKER.POPUP.INVITATION.RESEND': 'RESEND',
    'WORKER.POPUP.INVITATION.CONFIRMED': 'Confirmed account',
    'WORKER.POPUP.INVITATION.SYNCHRONIZED': 'Synchronized calendar',
    'WORKER.POPUP.INVITATION.OUTLOOK.AUTHORIZED': 'Outlook account linked',
    'WORKER.POPUP.INVITATION.GOOGLE.AUTHORIZED': 'Google account linked',
    'WORKER.POPUP.INVITATION.OUTLOOK.GOOGLE.AUTHORIZED': 'Google and Outlook accounts linked',
    'WORKER.POPUP.INVITATION.SENT.MESSAGE': 'Invitation was successfully sent!',
    'WORKER.NO.WORKER': 'No workers yet',
    'WORKER.UNCONFIRMED': 'Unconfirmed users',
    'WORKER.CONFIRMED': 'Confirmed users',
    'WORKER.EMAIL.TAKEN': 'Email has already been taken',
    'WORKER.ADD.APPOINTMENT': 'Fast track appointment',

    'WORKER.TAKES.APPOINTMENTS': 'Receives appt.',
    'WORKER.TAKES.NOT.APPOINTMENTS': 'Does not receive appt.',
};
